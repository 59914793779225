<template>
  <!-- <el-form ref="tariffeForm" :model="partner" :rules="formRulePartner">  -->

  <div class="point-container">
    <InputComponent
      :value="partner.company_name"
      prop="company_name"
      label="Ragione sociale"
       @input="(newValue) => (partner.company_name = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
    />
    <InputComponent
    :value="partner.piva"
    label="P.iva"
    prop="piva"
     @input="(newValue) => (partner.piva = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
      />
      <InputComponent
      :value="partner.CF"
      label="CF"
      prop="CF"
       @input="(newValue) => (partner.CF = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
    />
    <InputComponent
    :value="partner.SDI"
    label="SDI"
    prop="SDI"
     @input="(newValue) => (partner.SDI = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.PEC"
    label="PEC"
    prop="PEC"
     @input="(newValue) => (partner.PEC = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.mail"
    label="mail"
    prop="mail"
     @input="(newValue) => (partner.mail = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.phone_number"
    label="telefono"
    prop="phone_number"
     @input="(newValue) => (partner.telefono = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.IBAN"
    label="IBAN"
    prop="IBAN"
     @input="(newValue) => (partner.IBAN = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.CAB"
    label="CAB"
    prop="CAB"
    @input="(newValue) => (partner.CAB = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.ABI"
    label="ABI"
    prop="ABI"
    @input="(newValue) => (partner.ABI = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.domain"
    label="Dominio Nexi"
    prop="domain"
     @input="(newValue) => (partner.domain = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
    <InputComponent
    :value="partner.provvigione"
    label="provvigione"
    prop="provvigione"
    @input="(newValue) => (partner.provvigione = newValue.target.value)"
    :showEeuro="false"
    :disabled="disabled"
    />
  </div>

    <!-- </el-form> -->
  </template>
  
  
  <script>
  import InputComponent from './InputComponent.vue';
  import {   reactive } from 'vue';
  //import FormPointComponent from './FormPointComponent.vue';
  export default {
    components: {
      InputComponent,
      //FormPointComponent,
      
      //DescriptionComponent,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      form: {
        type: String,
        required: true,
      },
  
      type: {
        type: Number,
        required: false,
        default: 0,
      },
      style: {
        type: String,
        required: false,
        default: 'margin-top: 10%',
      },
      label: {
        type: String,
        required: false,
      },
      loading: {
        type: String,
        required: false,
      },
  
      handleInputBlur: {
        type: Function,
      },
  
      showGeocoding:{
        type: Boolean,
        required: false,
        default:true
      },
      disabled:{
        default:false
      }
    },
    setup() {
      let formRulePartner = reactive({
      company_name: [
        { required: true, message: 'Inserire ragione sociale', trigger: 'blur' },
      ],
        piva:  [
        { required: true, message: 'Inserire piva', trigger: 'blur' },
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        phone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        
    
  });
       return {
        formRulePartner,
      }; 
    },
    data() {
      return {
        partner: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.partner = newValue;
      },
    },
    getTitle(key) {
      return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
    },
    handleInput(newValue, key) {
      this.$set(this.partner, key, newValue);
    },
  };
  </script>
  
  <style scoped>
  .point-container {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: space-between;
    
  }
  
  .point-container > div {
    flex: 10;
    min-width: 20%;
    justify-content: center;
  }

  </style>
  