<template>
    <h1>Agenzie</h1>
    <div class="add-button">
    <ButtonComponent
    label="Aggiungi nuova agenzia"
    :isRouter="true"
    linkPath="/add-agency"
    @buttonClick="back"
  ></ButtonComponent>
  </div>
  
    <div class="mt-4">
      <FilterTableAgencyComponent
        @companyNameChanged="(newValue) => (companyNameFilter = newValue)"
        @mailChanged="(newValue) => (mailFilter = newValue)"
        @areaChanged="(newValue) => (areaFilter = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilter"
      ></FilterTableAgencyComponent>
  
      <el-table
        :data="filteredTable"
        stripe
        height="500"
        style="width: 100%"
        :header-cell-style="{ background: 'rgb(35,44,81)' }"
      >
        <!-- <el-table-column prop="id" label="id" width="100">
          <template #default="scope">
            <el-input :value="scope.row.id"></el-input>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="company_name"
          label="ragione sociale"
          width="150"
        />
        <el-table-column prop="name" label="name" width="150" />
        <el-table-column prop="surname" label="cognome" width="150" />
        <el-table-column prop="mail" label="mail" width="200" />
        <el-table-column prop="telephone_number" label="telephone_number" width="150" />
        <el-table-column prop="document" label="document" width="150" />
        <el-table-column prop="priority" label="N." width="50" />
       
        <el-table-column
        prop="areas"
        class-name="sub_row"
        label="area di ogni agenzia"
      >
        <template #default="scope">
 
          <el-table :data="scope.row.areas" :show-header="false">
            <el-table-column prop="area" label="area" width="200" />
          </el-table>
        </template>
      </el-table-column>
  
       
  
        <el-table-column width="50">
          <template #default="scope">
            <router-link
              :to="{ path: `/agency/${scope.row.id}` }"
              class="routerLink"
            >
              <el-button size="small" @click="handleClick(scope.row.id)">
                <el-icon><DArrowRight /></el-icon>
              </el-button>
            </router-link>
          </template>
        </el-table-column>

      </el-table>
      <router-link class="routerLink" to="/addAgency">
        <el-button type="primary" @click="addpartnerClick()" plain>
          Aggiungi partner</el-button
        >
      </router-link>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import agencyAPI from '../../services/agencyAPI.js';
  import FilterTableAgencyComponent from '../../components/FilterTableAgencyComponent.vue';
  import ButtonComponent from '../../components/ButtonComponent.vue'
  export default {
    name: 'PartnerPage',
    components: {
      FilterTableAgencyComponent,
      ButtonComponent
    },
    setup() {
      
      const value = ref(0);
      const input = ref('');
      const tableData = ref([]);
  
      const loadPartners = async () => {
        try {
          const response = await agencyAPI.getAgencies();
          response.data.forEach((area) => {
            let tableRow = {
              id: area.id,
              company_name: area.company_name,
              name: area.name,
              surname: area.surname,
              telephone_number: area.telephone_number,
              mail: area.mail,
              priority: area.priority,
              document: area.document,
              areas: area.areas,
            };
            tableData.value.push(tableRow);
          });
        } catch (error) {
          console.log(error);
        }
      };
  
      loadPartners();
  
      let companyNameFilter = ref('');
      let mailFilter = ref('');
      let areaFilter = ref('');
  
      const filteredTable = computed(function () {
        return tableData.value.filter(
          (row) =>
            row.company_name
              .toLowerCase()
              .includes(companyNameFilter.value.toLowerCase()) &&
            row.mail.toLowerCase().includes(mailFilter.value.toLowerCase()) &&
            row.areas.some((hq) =>
              hq.area
                .toLowerCase()
                .includes(areaFilter.value.toLowerCase())
            )
        );
      });
  
      const resetFilter = async () => {
        window.location.reload();
      };
  
      return {
        input,
        
        value,
        tableData,
        filteredTable,
        resetFilter,
        companyNameFilter,
        mailFilter,
        areaFilter,
      };
    },
  };
  </script>
  
  <style>
  .el-table {
    color: black;
  }
  
  .el-table thead {
    color: rgb(255, 255, 255);
  }
  
  .el-table td {
    color: black;
  }
  
  .el-table line {
    color: black;
  }

  .add-button {
    text-align: right;
  }
  </style>
  