<template>
  <h1>COD1</h1>

  <TitleComponent title="Fatture" />
  <el-table
    :data="fattureTable"
    style="width: 100%"
    height="500"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >

    <el-table-column prop="dal" label="Dal" />
    <el-table-column prop="al" label="Al" />
    <el-table-column prop="description" label="Descrizione" />
    <el-table-column prop="sede" label="Sede" />
    <el-table-column prop="rif" label="Rif fattura">
      <template #default="scope">
        <el-input v-model="scope.row.rif"></el-input>
      </template>
    </el-table-column>
    
    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="importo" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.importo" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <!-- <el-table-column prop="liquidato" label="esito">
      <template #default="scope">
        <el-checkbox v-model="scope.row.liquidato" />
      </template>
    </el-table-column> -->

    <el-table-column prop="data" label="Data" sortable>
      <template #default="scope">
        <el-date-picker
          v-model="scope.row.data"
          type="date"
          format="YYYY-MM-DD"
          placeholder="Seleziona data"
        />
      </template>
    </el-table-column>

    <el-table-column label="Modifica">
      <template #default="scope">
        <el-button size="small" @click="update(scope.row)">
          Modifica
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <TitleComponent title="Fatture Inserite" />
  <el-table
    :data="fattureLiquidateTable"
    style="width: 100%"
    height="500"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >

    <el-table-column prop="dal" label="Dal" />
    <el-table-column prop="al" label="Al" />
    <el-table-column prop="description" label="Descrizione" />
    <el-table-column prop="sede" label="Sede" />
    <el-table-column prop="rif" label="Rif fattura">
      <template #default="scope">
        <el-input v-model="scope.row.rif"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="importo" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.importo" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <!-- <el-table-column prop="liquidato" label="esito">
      <template #default="scope">
        <el-checkbox v-model="scope.row.liquidato" />
      </template>
    </el-table-column> -->

    <el-table-column prop="data" label="Data" sortable>
      <template #default="scope">
        <el-date-picker
          v-model="scope.row.data"
          type="date"
          format="YYYY-MM-DD"
          placeholder="Seleziona data"
        />
      </template>
    </el-table-column>

    <el-table-column label="Modifica">
      <template #default="scope">
        <el-button size="small" @click="update(scope.row)">
          Modifica
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import contabilitaAPI from '../../../services/contabilitaAPI';
//import partnerAPI from "../../../services/partnerAPI";
import { useRoute } from 'vue-router';

import TitleComponent from '../../../components/TitleComponent.vue';

export default {
  components: {
    TitleComponent,
  },
  setup() {
    const route = useRoute();
    const headquarterID = route.params.id;
    const input = ref('');
    const tableData = ref([]);
    const tableDataInserite = ref([]);

    const loadFatture = async () => {
      try {
        const response = await contabilitaAPI.getFatture(route.params.id);

        response.data.forEach((fattura) => {

          let tableRow = {
            id: fattura.id,
            dal: fattura.date_start.split('T')[0],
            al: fattura.date_end.split('T')[0],
            description: fattura.description,
            imponibile: fattura.imponibile,
            importo: fattura.importo,
            rif: fattura.rif_fattura,
            liquidato: fattura.liquidato,
            data: fattura.data.split('T')[0],
            sede:
              fattura.headquarter.address +
              ' ' +
              fattura.headquarter.street_number +
              ' ' +
              fattura.headquarter.city,
          };
          tableRow.liquidato?tableDataInserite.value.push(tableRow):tableData.value.push(tableRow);
        });
        console.log(tableData.value);
      } catch (error) {
        console.log('error', error);
      }
    };

    loadFatture();

    const fattureTable = computed(function () {
      return tableData.value.filter((row) =>
        row.rif.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const fattureLiquidateTable = computed(function () {
      return tableDataInserite.value.filter((row) =>
        row.rif.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const update = (fattura) => {
      let updatedFattura = {
        id: fattura.id,
        date_start: fattura.dal,
        date_end: fattura.al,
        description: fattura.description,
        imponibile: fattura.imponibile,
        importo: fattura.importo,
        rif_fattura: fattura.rif,
        liquidato: true,
        data: fattura.data,
      };
      
      updFattura(updatedFattura).then((response) => {
        
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Fattura aggiornato con successo!',
            type: 'success',
          });
          route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updFattura = async (updatedFattura) => {
      let response = {};
      try {
        //let fattura = await contabilitaAPI.getFattura(id);
        response = await contabilitaAPI.updateFattura(
          updatedFattura.id,
          updatedFattura
        );
        
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      loadFatture,
      fattureTable,
      input,
      headquarterID,
      update,
      fattureLiquidateTable
    };
  },
};
</script>

<style>
.button {
  margin-top: 30px;
}
</style>
