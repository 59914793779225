<template>
  <TitleComponent title="Aggiungi Partner" />
  <el-card class="custom-card">
    <el-form ref="formRef" :model="tableData" :rules="formRulePartner">
      <FormPartnerComponent
        :value="tableData"
        :type="type"
        :rules="formRulePartner"
      />

      <h4>Aggiungi nuove sedi</h4>
      <div
        v-for="(newHeadquarter, counter_headquarter) in headquarterNew"
        v-bind:key="counter_headquarter"
      >
        <el-form ref="formHeadquarter" :model="newHeadquarter">
          <br />
          <FormPointComponent
            :inputPoint="newHeadquarter"
            :handleInputBlur="handleInputBlur(newHeadquarter)"
            :showDescription="true"
            :loadingPoint="loadingPoint"
            :label="newHeadquarter.label"
            :deletePoint="true"
            :ifCondition="counter_headquarter != 0 ? true : false"
            @buttonClick="rimuoviHeadquarter"
            :counter="counter_headquarter"
            :showAbbonamento="true"
            :optionsArea="areaExisisting"
            :selectArea="true"
            :readonly="false"
            @selected="handleSelectArea"
          ></FormPointComponent>
        </el-form>
      </div>
      <br />
      <ButtonComponent
        label="Aggiungi sedi"
        :isRouter="false"
        @buttonClick="aggiungiHeadquarter"
        type="success"
        :plain="true"
      ></ButtonComponent>

      <br />
      <br />
      <br />

      <FormTariffeComponent :value="tableData" :type="type" :rules="formRule" />
    </el-form>

    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/partner"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Aggiungi il nuovo partner"
        :isRouter="false"
        @buttonClick="onSubmit('formRef')"
      ></ButtonComponent>
    </el-row>
  </el-card>
</template>

<script>
import partnerAPI from '@/services/partnerAPI';
import pointAPI from '@/services/pointAPI';
import agencyAPI from '@/services/agencyAPI';
import { ref, toRaw, reactive } from 'vue';

import { ElMessage } from 'element-plus';

import FormPartnerComponent from '../../components/FormPartnerComponent.vue';
import FormTariffeComponent from '../../components/FormTariffeComponent.vue';

import TitleComponent from '../../components/TitleComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormPointComponent from '../../components/FormPointComponent.vue';

import { useRouter } from 'vue-router';
export default {
  components: {
    FormPartnerComponent,
    ButtonComponent,
    TitleComponent,
    FormTariffeComponent,
    FormPointComponent,
  },
  setup() {
    const router = useRouter();

    let tableData = ref({
      company_name: '',
      piva: '',
      CF: '',
      SDI: '',
      PEC: '',
      CAB: '',
      ABI: '',
      domain: '',

      mail: '',
      phone_number: '',
      IBAN: '',
      provvigione: 0,

      delivery_5km: 0.0,
      delivery_10km: 0.0,
      partner_delivery_5km: 0.0,
      partner_delivery_10km: 0.0,
      delivery_15km: 0.0,
      partner_delivery_15km: 0.0,
      delivery_20km: 0.0,
      partner_delivery_20km: 0.0,
      delivery_25km: 0.0,
      partner_delivery_25km: 0.0,
      delivery_30km: 0.0,
      partner_delivery_30km: 0.0,      
      delivery_over_30km: 0.0,
      partner_delivery_over_30km: 0.0,

      pickup_5km: 0.0,
      pickup_10km: 0.0,
      pickup_15km: 0.0,
      pickup_20km: 0.0,
      pickup_25km: 0.0,
      pickup_30km: 0.0,
      pickup_over_30km: 0.0,

      partner_pickup_5km: 0.0,
      partner_pickup_10km: 0.0,
      partner_pickup_15km: 0.0,
      partner_pickup_20km: 0.0,
      partner_pickup_25km: 0.0,
      partner_pickup_30km: 0.0,
      partner_pickup_over_30km: 0.0,

      pickup_loaner_5km: 0.0,
      partner_pickup_loaner_5km: 0.0,      
      pickup_loaner_10km: 0.0,
      partner_pickup_loaner_10km: 0.0,
      pickup_loaner_15km: 0.0,
      partner_pickup_loaner_15km: 0.0,
      pickup_loaner_20km: 0.0,
      partner_pickup_loaner_20km: 0.0,
      pickup_loaner_30km: 0.0,
      partner_pickup_loaner_30km: 0.0,      
      pickup_loaner_over_30km: 0.0,
      partner_pickup_loaner_over_30km: 0.0,

      escort_10km: 0.0,
      escort_5km: 0.0,
      escort_15km: 0.0,
      escort_20km: 0.0,
      escort_30km: 0.0,
      escort_over_30km: 0.0,
      partner_escort_5km: 0.0,
      partner_escort_10km: 0.0,
      partner_escort_15km: 0.0,
      partner_escort_20km: 0.0,
      partner_escort_30km: 0.0,
      partner_escort_over_30km: 0.0,

      escort_loaner_5km: 0.0,
      partner_escort_loaner_5km: 0.0,      
      escort_loaner_10km: 0.0,
      partner_escort_loaner_10km: 0.0,
      escort_loaner_15km: 0.0,
      partner_escort_loaner_15km: 0.0,
      escort_loaner_20km: 0.0,
      partner_escort_loaner_20km: 0.0,
      escort_loaner_30km: 0.0,
      partner_escort_loaner_30km: 0.0,      
      escort_loaner_over_30km: 0.0,
      partner_escort_loaner_over_30km: 0.0,
      one_valet:0.0,
      two_valet:0.0,
      three_valet:0.0,
      headquarters: [],
    });

    const headquarterNew = reactive([
      {
        address: '',
        street_number: '',
        cap: '',
        city: '',
        province: '',
        label: '',
        latitude: 0,
        longitude: 0,
        partner: tableData.value,

        address_geocoded: '',
        street_number_geocoded: '',
        city_geocoded: '',
        cap_geocoded: '',
        province_geocoded: '',
        area:{}
      },
    ]);

    const rimuoviHeadquarter = (e, counter) => {
      headquarterNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiHeadquarter = (e) => {
      headquarterNew.push({
        address: '',
        street_number: '',
        cap: '',
        city: '',
        province: '',
        label: '',
        latitude: 0,
        longitude: 0,
        partner: tableData.value,

        address_geocoded: '',
        street_number_geocoded: '',
        city_geocoded: '',
        cap_geocoded: '',
        province_geocoded: '',
      });
      e.preventDefault();
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await pointAPI.getPoints({
          text: pointToString(point),
        });
        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    let areaExisisting = ref([]);
    let areaExisistingResponse = ref([]);
    const loadAreas = async () => {
      try {
        const resp = await agencyAPI.getAreas();
        areaExisistingResponse.value = resp.data;

        resp.data.forEach((area) => {
          let tableRow = {
            value: area.id,
            label: `${area.area}`,
          };
          areaExisisting.value.push(tableRow);
        });
      } catch (e) {
        console.log('errore load area: ', e);
      }
    };
    loadAreas();

    const handleSelectArea = (newValue, counter) => {
      const selectedArea = areaExisistingResponse.value.find(
        (hq) => hq.id === newValue[0]
      );
      
      if (selectedArea) {
        headquarterNew[counter].area=selectedArea;
      }
    };

    const handleInputBlur = async (headquarter) => {
      if (
        headquarter.address != '' &&
        headquarter.street_number != '' &&
        headquarter.city != '' &&
        headquarter.cap != '' &&
        headquarter.province != ''
      ) {
        let point = {
          address: headquarter.address,
          street_number: headquarter.street_number,
          city: headquarter.city,
          cap: headquarter.cap,
          province: headquarter.province,
        };

        let response = await loadPoints(point);
        headquarter.label = response.label;
        headquarter.latitude = response.point.latitude;
        headquarter.longitude = response.point.longitude;

        headquarter.address_geocoded = response.point.address;
        headquarter.street_number_geocoded = response.point.street_number;
        headquarter.city_geocoded = response.point.city;
        headquarter.cap_geocoded = response.point.cap;
        headquarter.province_geocoded = response.point.province;
      }
    };

    let formRulePartner = reactive({
      company_name: [
        {
          required: true,
          message: 'Inserire ragione sociale',
          trigger: 'blur',
        },
      ],
      piva: [{ required: true, message: 'Inserire piva', trigger: 'blur' }],
      mail: [{ required: true, message: 'Inserire mail', trigger: 'blur' }],
      telephone_number: [
        {
          required: true,
          message: 'Inserire numero di telefono',
          trigger: 'blur',
        },
      ],
    });
    const formRef = ref(null);

    const onSubmit = async () => {
      
      const headquartersPayload = { bulk: toRaw(headquarterNew) };
      formRef.value.validate(async (valid) => {
        if (valid) {
          await partnerAPI
            .addHeadquarters(headquartersPayload)
            .then(async (response) => {
              if (response.status === 201) {
                 router.push('/partner');

                ElMessage({
                  message: 'Partner aggiunte con successo!',
                  type: 'success',
                });
              }
            });
        } else {
          console.log('erroree');
        }
      });
    };

    return {
      tableData,
      rimuoviHeadquarter,
      aggiungiHeadquarter,
      headquarterNew,
      loadPoints,
      loadingPoint,
      handleInputBlur,
      loadAreas,
      areaExisistingResponse,
      areaExisisting,
      handleSelectArea,
      formRef,
      onSubmit,
      formRulePartner,
    };
  },

  methods: {},
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
