<template>
  <h1>COD2</h1>
  <el-row :gutter="20">
    <el-col :span="20">
      <el-button
        style="margin-left: 110%; margin-top: -10%"
        type="primary"
        :plain="true"
        @click="downloadPDF"
      >
        scarica PDF
      </el-button>
    </el-col>
  </el-row>

   <FilterTableCod2Component

    @customerChanged="(newValue) =>
    (customerFilter = newValue)"

    @targaChanged="(newValue) =>
    (targaFilter = newValue)"
   
    @resetClick="resetFilter"
    :nome="nomeFilter">
</FilterTableCod2Component>

  <el-input v-model="input" placeholder="Inserisci il riferimento fattura">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table
    :data="minuteTable"
    height="500"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer" label="Cliente" />
    <el-table-column prop="targa" label="Auto" />
    <el-table-column prop="data_reg" label="Data Reg" />
    <el-table-column prop="valet" label="Valet" width="68"/>
    <el-table-column prop="residual_valet" label="Valet Residui" width="71"/>
    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="tot_fattura" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="rif_fattura" label="Rif fattura">
      <template #default="scope">
        <el-input v-model="scope.row.rif_fattura"></el-input>
      </template>
    </el-table-column>

    <el-table-column prop="data" label="Data" sortable>
      <template #default="scope">
        <el-date-picker
          v-model="scope.row.data"
          type="date"
          placeholder="Seleziona data"
        />
      </template>
    </el-table-column>

    <el-table-column label="Modifica">
      <template #default="scope">
        <el-button size="small" @click="update(scope.row)">
          Modifica
        </el-button>
      </template>
    </el-table-column>

    <el-table-column label="PDF" width="55">
      <template #default="scope">
        <el-checkbox
          v-model="scope.row.selected"
          @change="handleRowSelect(scope.row)"
        ></el-checkbox>
      </template>
    </el-table-column>
  </el-table>




    <el-table
    :data="minuteTableData"
    height="500"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer" label="Cliente" />

    <el-table-column prop="targa" label="Auto" />
    <el-table-column prop="data_reg" label="Data Reg" />
    <el-table-column prop="valet" label="Valet" width="68"/>
    <el-table-column prop="residual_valet" label="Valet Residui" width="71"/>

    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="tot_fattura" label="Tot Fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="rif_fattura" label="Rif fattura">
    </el-table-column>

    <el-table-column prop="data" label="Data" sortable>
    </el-table-column>

  </el-table>
  <div style="text-align: center">
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChangePage"
      :page-size="pageSize"
      :total="minuteTableData.length"
    >
    </el-pagination>

  </div>
</template>

<script>
import { ref, computed } from 'vue';

import partnerAPI from '../../../services/partnerAPI';
import contabilitaAPI from '../../../services/contabilitaAPI';
import { useRoute } from 'vue-router';
import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import FilterTableCod2Component from  '../../../components/FilterTableCod2Component.vue';
import 'jspdf-autotable';
const dayjs = require('dayjs');

export default {
  data() {
    return {
      pageMinute: 1,
      pageSize: 7,
    };
  },
  components:{
    FilterTableCod2Component
  },
  setup() {
    const route = useRoute();
    const input = ref('');
    const tableData = ref([]);

    const tableDataInserite = ref([]);

    const selectedRow = ref([]);
    const loadMinute = async () => {
      try {
        const response = await partnerAPI.getMinutesByPartner(route.params.id);

        response.data.forEach((minute) => {
          
          let tableRow = {
            id: minute.id,
            data_reg: dayjs(minute.data_reg).format('DD-MM-YY'),
            valet: minute.valet,
            residual_valet: minute.residual_valet,
            imponibile: minute.imponibile,
            tot_fattura: minute.tot_fattura,
            rif_fattura: minute.rif_fattura,
            esito: minute.esito,
            targa: minute.car.targa,
            data:  dayjs(minute.data).format("DD/MM/YYYY"),
            customer:
              minute.car.customer.name + ' ' + minute.car.customer.surname,
            //headquarter: minute.headquarter
          };
          tableRow.esito?tableDataInserite.value.push(tableRow):tableData.value.push(tableRow);

        });
        console.log(tableData.value);
      } catch (e) {
        console.log(e);
      }
    };

    const handleRowSelect = (row) => {
      if (row.selected) {
        selectedRow.value.push(row);
      }
    };

    const downloadPDF = () => {
      //const selectedRows = fattureTableFalse.filter((row) => row.selected);
      if (selectedRow.value.length === 0) {
        ElMessage.error('Seleziona almeno una riga!');
        return;
      }
      // Call a function to generate a PDF with the selected rows
      generatePDF(selectedRow.value);
    };

    const generatePDF = (selectedRows) => {
      const pdf = new jsPDF();
      const header = ['Cliente', 'Targa','Valet', 'Imponibile', 'Totale'];
      const data = selectedRows.map((row) => [
        row.customer,
        row.targa,
        row.valet,
        row.imponibile,
        row.tot_fattura,
      ]);

      pdf.autoTable({
        head: [header],
        body: data,
      });

      const today = new Date();

      // Save the PDF or open it in a new tab
      pdf.save('dettaglio-minuti ' + dayjs(today).format('DD-MM-YY') + '.pdf');
    };

    loadMinute();

    const update = (minute) => {
      
      let updatedMinute = {
        id: minute.id,
        imponibile: minute.imponibile,
        tot_fattura:1.22 *  minute.imponibile,
        rif_fattura: minute.rif_fattura,
        esito: true,
        data:minute.data,
      };
      

      updMinute(updatedMinute).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Fattura aggiornato con successo!',
            type: 'success',
          });
          route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updMinute = async (updatedMinute) => {
      let response = {};
      try {
        //let fattura = await contabilitaAPI.getFattura(id);
        response = await contabilitaAPI.updateMinute(
          updatedMinute.id,
          updatedMinute
        );
        
        return response;
      } catch {
        console.log('error');
      }
    };

    let customerFilter = ref('');
    let targaFilter = ref('');
    //let headquarterFilter = ref("");

    const minuteTable = computed(function () {
      return tableData.value.filter(
        (row) =>
          row.customer
            .toLowerCase()
            .includes(customerFilter.value.toLowerCase()) &&
          row.targa.toLowerCase().includes(targaFilter.value.toLowerCase()) //&&
        //row.headquarter.toLowerCase().includes(headquarterFilter.value.toLowerCase()) &&
      );
    });

    const minuteTableFatturati = computed(function () {
      return tableDataInserite.value.filter(
        (row) =>
          row.customer
            .toLowerCase()
            .includes(customerFilter.value.toLowerCase()) &&
          row.targa.toLowerCase().includes(targaFilter.value.toLowerCase()) //&&
        //row.headquarter.toLowerCase().includes(headquarterFilter.value.toLowerCase()) &&
      );
    });

    const resetFilter = async () => {
      window.location.reload();
    };

    return {
      downloadPDF,
      handleRowSelect,
      loadMinute,
      minuteTable,
      input,
      customerFilter,
      targaFilter,
      update,
      minuteTableFatturati,
      resetFilter
    };
  },
  computed: {
    minuteTableData() {
      if (!this.minuteTableFatturati || this.minuteTableFatturati.length === 0) return [];
      return this.minuteTableFatturati.slice(
        this.pageSize * this.pageMinute - this.pageSize,
        this.pageSize * this.pageMinute
      );
    },
  },
  methods: {
    handleCurrentChangePage(val) {
      this.pageMinute = val;
    },
  },
};
</script>

<style></style>
