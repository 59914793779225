import API from './API';


export default {

  getCarsByCustomer(customerId, /* partnerId */) {
    return API.get(`/car?filter=customer.id||$eq||${customerId}&` , {
      params: {
       join: 
       [
         'minutes',
         'minutes.partner',
         'customer',
         'headquarters',
         'headquarters.employees'
       ]
       
     },  
   });
  },

  modifyCar(car){
    return API.patch('/car/'+car.id, car)
  },

  addCar(car){
    return API.post('/car/create', car)
  },

  addMinute(minute){
    return API.post(`/minute` , minute);
  },

  getCarByTarga(targa){
    return API.get(`/car?filter=targa||$eq||${targa}` );
  },
}