<template>
  <div class="employee-container">
    <InputComponent
      :value="employee.name"
      :span="6"
      title="Nome"
      label="Nome"
      placeholder="Nome"
      @input="(newValue) => (employee.name = newValue.target.value)"
      prop="name"
      :showEeuro="false"
      :readonly="readonly"
    />
    <InputComponent
      :value="employee.surname"
      :span="6"
      title="Cognome"
      label="Cognome"
      prop="surname"
      placeholder="Cognome"
      :showEeuro="false"
      @input="(newValue) => (employee.surname = newValue.target.value)"
:readonly="readonly"
    />
    <InputComponent
      :value="employee.mail"
      :span="6"
      title="Mail"
      label="Mail"
      placeholder="Mail"
      prop="mail"
      :showEeuro="false"
      @input="(newValue) => (employee.mail = newValue.target.value)"

    />
    <InputComponent
      :value="employee.telephone_number"
      :span="6"
      title="Cell"
      label="Cell"
      prop="telephone_number"
      placeholder="Numero di telefono"
      :showEeuro="false"
      @input="(newValue) => (employee.telephone_number = newValue.target.value)"
:readonly="readonly"
    />

    <InputComponent
      v-if="showInputRole"
      :value="mapRole(employee.role)"
      :span="6"
      title="Ruolo"
      label="Ruolo"
      prop="role"
      placeholder="Role"
      :showEeuro="false"
      @input="(newValue) => (employee.role = newValue.target.value)"
      :readonly="readonly"
    />

    <SelectComponent
      v-if="!showInputRole"
      @selected="(newValue) => (employee.role = newValue)"
      :options="options"
      title="Ruolo"
      :addButtom="false"
      :isDisabled="false"
      :value="employee.role"
    />

    <ButtonComponent
      v-if="deleteEmployee"
      :ifCondition="ifCondition"
      :isRouter="false"
      @buttonClick="handleButtonClickDelete($event, counterEmployee)"
      type="danger"
      icon="Delete"
      style="margin-right: 45%"
    ></ButtonComponent>
  </div>
</template>

<script>
import InputComponent from './InputComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import SelectComponent from './SelectComponent.vue';

import { reactive } from 'vue';
export default {
  components: {
    InputComponent,
    ButtonComponent,
    SelectComponent,
  },
  props: {
    value: {
      required: true,
    },
    form: {
      type: String,
      required: true,
    },

    type: {
      type: Number,
      required: false,
      default: 0,
    },
    style: {
      type: String,
      required: false,
      default: 'margin-top: 10%',
    },
    label: {
      type: String,
      required: false,
    },
    loading: {
      type: String,
      required: false,
    },

    handleInputBlur: {
      type: Function,
    },

    showGeocoding: {
      type: Boolean,
      required: false,
      default: true,
    },
    deleteEmployee: {
      required: false,
      default: false,
      type: Boolean,
    },
    counterEmployee: {
      required: false,
    },
    ifCondition: {
      type: Boolean,
    },
    options: {},
    showInputRole: {
      type: Boolean,
      default: false,
    },
        readonly:{
          required:false,
          default:false
        }
  },
  setup() {
    let formRule = reactive({
      name: [
        {
          required: true,
          message: 'Inserire il nome',
          trigger: 'blur',
        },
      ],
      surname: [
        {
          required: true,
          message: 'Inserire il cognome',
          trigger: 'blur',
        },
      ],
      date_birth: [
        {
          type: 'date',
          required: true,
          message: 'Inserire data di nascita',
          trigger: 'blur',
        },
      ],
      mail: [
        {
          required: true,
          message: 'Inserire mail',
          trigger: 'blur',
        },
      ],
      telephone_number: [
        {
          required: true,
          message: 'Inserire numero di telefono',
          trigger: 'blur',
        },
      ],
    });

    return {
      formRule,
    };
  },
  data() {
    return {
      employee: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.employee = newValue;
    },
  },

  methods: {
    getTitle(key) {
      return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
    },
    handleInput(newValue, key) {
      this.$set(this.employee, key, newValue);
    },
    handleButtonClickDelete() {
      this.$emit('buttonClick', this.employee, this.counterEmployee);
    },
    mapRole(role) {
      switch (role) {
        case 'employee':
          return 'Dipendente';
        case 'adminEmployee':
          return 'Amministratore';
        case 'salesvendor':
          return 'Venditore';
      }
    },
  },
};
</script>

<style scoped>
.employee-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.employee-container > div {
  flex: 2;
  min-width: 7%;
}
</style>
