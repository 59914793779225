<template>
  <h1>Partner</h1>
  <div class="add-button">
  <ButtonComponent
    label="Aggiungi nuovo partner"
    :isRouter="true"
    linkPath="/add-partner"
    @buttonClick="back"
  ></ButtonComponent>
</div>
  <div class="mt-4">
    <FilterTablePartnerComponent
      @companyNameChanged="(newValue) => (companyNameFilter = newValue)"
      @mailChanged="(newValue) => (mailFilter = newValue)"
      @headquarterChanged="(newValue) => (headquarterFilter = newValue)"
      @resetClick="resetFilter"
      :nome="nomeFilter"
    ></FilterTablePartnerComponent>

    <el-table
      :data="filteredTable"
      stripe
      height="500"
      style="width: 100%"
      :header-cell-style="{ background: 'rgb(35,44,81)' }"
    >
      <el-table-column prop="id" label="id" width="100">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        prop="company_name"
        label="ragione sociale"
        width="150"
      />
      <el-table-column prop="mail" label="mail" width="150" />
      <el-table-column prop="provvigione" label="provvigione" width="110" />
      <el-table-column
        prop="headquarters"
        class-name="sub_row"
        label="sedi di ogni partner"
      >
        <template #default="scope">
          <el-table :data="scope.row.headquarters" :show-header="false">
          
            <el-table-column prop="address" label="indirizzo" width="200" />
            <el-table-column
              prop="street_number"
              label="n. civico"
              width="50"
            />
            <el-table-column prop="cap" label="CAP" width="100" />
            <el-table-column prop="city" label="città" width="110" />
            <el-table-column prop="province" label="provincia" width="50" />
            <el-table-column width="50">
              <template #default="scope">
                <router-link
                  :to="{ path: `/headquarter-detail/${scope.row.id}` }"
                  class="routerLink"
                >
                  <el-button size="small" @click="handleClick(scope.row.id)">
                    <el-icon><DArrowRight /></el-icon>
                  </el-button>
                </router-link>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>

      <el-table-column width="50">
        <template #default="scope">
          <router-link
            :to="{ path: `/partner/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.id)">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import { ref, computed } from 'vue';
import partnersAPI from '../../services/partnerAPI.js';
import FilterTablePartnerComponent from '../../components/FilterTablePartnerComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
export default {
  name: 'PartnerPage',
  components: {
    FilterTablePartnerComponent,
    ButtonComponent,
  },
  setup() {
    const value = ref(0);
    const input = ref('');
    const tableData = ref([]);

    const loadPartners = async () => {
      try {
        const response = await partnersAPI.getHeadquartersToPartner();

        response.data.forEach((partner) => {
          let tableRow = {
            id: partner.id,
            company_name: partner.company_name,
            mail: partner.mail,
            provvigione: partner.provvigione + '%',
            headquarters: partner.headquarters,
          };
          tableData.value.push(tableRow);
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadPartners();

    let companyNameFilter = ref('');
    let mailFilter = ref('');
    let headquarterFilter = ref('');

    const filteredTable = computed(function () {
      return tableData.value.filter(
        (row) =>
          row.company_name
            .toLowerCase()
            .includes(companyNameFilter.value.toLowerCase()) &&
          row.mail.toLowerCase().includes(mailFilter.value.toLowerCase()) &&
          row.headquarters.some((hq) =>
            hq.address
              .toLowerCase()
              .includes(headquarterFilter.value.toLowerCase())
          )
      );
    });

    const resetFilter = async () => {
      window.location.reload();
    };

    return {
      input,

      value,
      tableData,
      filteredTable,
      resetFilter,
      companyNameFilter,
      mailFilter,
      headquarterFilter,
    };
  },
};
</script>

<style>
.el-table {
  color: black;
}

.el-table thead {
  color: rgb(255, 255, 255);
}

.el-table td {
  color: black;
}

.el-table line {
  color: black;
}

.add-button {
  text-align: right;
}
</style>
