<template>
    <TitleComponent title="AGGIUNGI UN NUOVO CLIENTE" />
    <el-card class="custom-card">
        <el-form ref="formRef" :model="customer" :rules="formRule">
            <FormAddCustomerComponent
              :value="customer"
              :type="type"
              :rules="formRule"
              :label="customer.label"
              :loading="loadingPoint"
              :handleInputBlur="handleInputBlur"
            />
          </el-form>
    </el-card>
</template>

<script>
import { reactive, toRaw, ref, nextTick, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import customerAPI from '../../services/customerAPI';
import { isLoggedStore } from '../../store/logged';
import partnerAPI from '../../services/partnerAPI';
import employeeAPI from '../../services/employeeAPI';
import TitleComponent from '../../components/TitleComponent.vue';
import FormAddCustomerComponent from '../../components/FormAddCustomerComponent.vue';
import prenotazioneAPI from '../../services/prenotazioneAPI.js';
import { useRouter } from 'vue-router';
import carAPI from '../../services/carAPI.js';
const dayjs = require('dayjs');
export default {
    components: {
      TitleComponent,
      FormAddCustomerComponent
    },
  
    setup() {
      const props1 = {
        expandTrigger: 'hover',
        //checkStrictly: true,
      };
  
      let isPrivate = ref(true);
  
      //let valid_customer=ref(false);
      const storeLogin = isLoggedStore();
      const router = useRouter();
      let transmission = ref('');
      const options = [
        {
          value: 'manual',
          label: 'manuale',
        },
        {
          value: 'automatic',
          label: 'automatico',
        },
      ];
      let customer = reactive({
        company_name:"FFF",
        piva:"FFF",
        CF:"FF",
        SDI:"FF",
        PEC:"FFF",
        name: 'x',
        surname: 'x',
        date_birth: new Date(),
        mail: 'asya.pesaresi@intellidio.com',
        address: 'via palombare',
        street_number: '25',
        city: 'ancona',
        cap: '60100',
        province: 'ancona',
        latitude: '',
        longitude: '',
        label: '',
        telephone_number: '333333',
        role: 'customer',
        isAlive: true,
        cars: [
          {
            intestatario: 'xxx',
            marca: 'x',
            modello: 'x',
            targa: 'x',
            transmission: 'automatic',
            headquarters:{}
          },
        ],
        partners: [],
        employees: [],
      });
  
      let minutes = ref([
        {
          valet: 0,
          tariffa: 0,
          car: {},
        },
      ]);

      let formRule = reactive({
        name: [
          {
            required: true,
            message: 'Inserire il nome',
            trigger: 'blur',
          },
        ],
        surname: [
          {
            required: true,
            message: 'Inserire il cognome',
            trigger: 'blur',
          },
        ],
        date_birth: [
          {
            type: 'date',
            required: true,
            message: 'Inserire data di nascita',
            trigger: 'blur',
          },
        ],
        mail: [
          {
            required: true,
            message: 'Inserire mail',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: 'Inserire indirizzo',
            trigger: 'blur',
          },
        ],
        street_number: [
          {
            required: true,
            message: 'Inserire N.civico',
            trigger: 'blur',
          },
        ],
        city: [
          {
            required: true,
            message: 'Inserire città',
            trigger: 'blur',
          },
        ],
        cap: [
          {
            required: true,
            message: 'Inserire cap',
            trigger: 'blur',
          },
        ],
        province: [
          {
            required: true,
            message: 'Inserire provincia',
            trigger: 'blur',
          },
        ],
        telephone_number: [
          {
            required: true,
            message: 'Inserire numero di telefono',
            trigger: 'blur',
          },
        ],
        CF: [
          {
            required: true,
            message: 'Inserire codice fiscale',
            trigger: 'blur',
          },
          {
            min: 16,
            max: 16,
            message: 'Numero di cifre errato',
            trigger: 'blur',
          },
        ],
        intestatario: [
          {
            required: true,
            message: 'Inserire intestatario',
            trigger: 'blur',
          },
        ],
        marca: [
          {
            required: true,
            message: 'Inserire marca',
            trigger: 'blur',
          },
        ],
        modello: [
          {
            required: true,
            message: 'Inserire modello',
            trigger: 'blur',
          },
        ],
        targa: [
          {
            required: true,
            message: 'Inserire targa',
            trigger: 'blur',
          },
        ],
        transmission: [
          {
            required: true,
            message: 'Scegliere cambio',
            trigger: 'blur',
          },
        ],
      });
  
      let partner = ref({});
  
      const loadPartner = async () => {
        const responsePartner = await partnerAPI.getPartnerByEmployee(
          storeLogin.id
        );
  
        partner.value = responsePartner.data[0].headquarters[0].partner;
      };
  
      loadPartner();
  
      const handleChangeValet = (minute) => {
        if (minute.valet == 1) {
          minute.tariffa = partner.value.one_valet;
        }
        if (minute.valet == 2) {
          minute.tariffa = partner.value.two_valet;
        }
        if (minute.valet == 3) {
          minute.tariffa = partner.value.three_valet;
        }
      };
  
      let carsId = ref([]);
      watch(
        () => customer.cars,
        (cars) => {
          carsId.value = [];
          console.log(cars);
          cars.forEach((car) => {
            if (
              car.intestatario != '' &&
              car.marca != '' &&
              car.modello != '' &&
              car.targa != ''
            ) {
              let tableRow = {
                value: car.targa,
                label: `${car.marca} ${car.modello} ${car.targa}`,
              };
  
              carsId.value.push(tableRow);
            }
          });
        },
        { deep: true, immediate: true }
      );
  
      const rimuoviMinute = (e, counter) => {
        minutes.value.splice(counter, 1);
        e.preventDefault();
      };
  
      const aggiungiMinute = (e) => {
        minutes.value.push({
          valet: 0,
          tariffa: 0,
          car: {},
        });
        e.preventDefault();
      };
  
      const pointToString = (point) => {
        const address = point.address ? point.address : '';
        const street_number = point.street_number ? point.street_number : '';
        const city = point.city ? point.city : '';
        const province = point.province ? point.province : '';
        const cap = point.cap ? point.cap : '';
  
        return (
          address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
        );
      };
      let loadingPoint = ref(true);
  
      const loadPoints = async (point) => {
        try {
          const response = await prenotazioneAPI.getPoints({
            text: pointToString(point),
          });
          
          loadingPoint.value = false;
          return response.data;
        } catch (error) {
          console.log(error);
          return [];
        }
      };
  
      let responsePoint = ref({
        address: '',
        street_number: '',
        city: '',
        cap: '',
        province: '',
        latitude: '',
        longitude: '',
      });
  
      const handleInputBlur = async () => {
  
        if (
          customer.address != '' &&
          customer.street_number != '' &&
          customer.city != '' &&
          customer.cap != '' &&
          customer.province != ''
        ) {
          let point = {
            address: customer.address,
            street_number: customer.street_number,
            city: customer.city,
            cap: customer.cap,
            province: customer.province,
          };
  
          let response = await loadPoints(point);
          customer.label = response.label;
          customer.latitude = response.point.latitude;
          customer.longitude = response.point.longitude;
  
          responsePoint.value.address = response.point.address;
          responsePoint.value.street_number = response.point.street_number;
          responsePoint.value.city = response.point.city;
          responsePoint.value.cap = response.point.cap;
          responsePoint.value.province = response.point.province;
          responsePoint.value.latitude = response.point.latitude;
          responsePoint.value.longitude = response.point.longitude;
        }
      };
  
      const postMinute = async (minute) => {
  
        try {
          let resp = await carAPI.addMinute(minute);
          return resp;
        } catch (e) {
          console.log(e);
        }
      };
  
      const addMinute = async () => {
        minutes.value.forEach(async (minute) => {
          let car = await carAPI.getCarByTarga(minute.car[0]);
  
          let newMinute = {
            valet: minute.valet,
            residual_valet: minute.valet,
            expiration_valet: new Date(),
            imponibile: minute.tariffa,
            tot_fattura: minute.tariffa + ((minute.tariffa)*22)/100,
            rif_fattura: 0,
            esito: false,
            accepted: false,
            data: new Date(),
            car: car.data[0],
            partner: partner.value,
          };
  
          newMinute.expiration_valet.setFullYear(newMinute.expiration_valet.getFullYear() + minute.valet);
           await postMinute(newMinute);
         
        });
      };
  
      const addcustomer_data = async (customer) => {
        let response = {};
        try {
          customer.address = responsePoint.value.address;
          customer.street_number = responsePoint.value.street_number;
          customer.city = responsePoint.value.city;
          customer.cap = responsePoint.value.cap;
          customer.province = responsePoint.value.province;
          customer.latitude = responsePoint.value.latitude;
          customer.longitude = responsePoint.value.longitude;
  
          const employeeId = storeLogin.employee.id;
          const partner = await partnerAPI.getPartnerByEmployee(employeeId);
          const employee = await employeeAPI.getEmployee(employeeId);
          customer.date_birth = dayjs(customer.date_birth).format(
            'YYYY-MM-DD HH:mm:ss'
          );
  
          customer.cars.forEach((car)=>{
            car.headquarters= partner.data[0].headquarters
          })
  
          customer.partners.push(partner.data[0].headquarters[0].partner);
          customer.employees.push(employee.data[0]);
  
          response = await customerAPI.addCustomer(toRaw(customer));
  
          // if(storeLogin.role=="salesvendor"){
  
          //}
  
          return response;
        } catch (error) {
          if (error.response.status === 400) {
            ElMessage({
              message: 'Errore!',
              type: 'error',
            });
            console.log('errore inatteso');
          } else if (error.response.status === 409) {
            ElMessage({
              message: 'Mail già usata!',
              type: 'error',
            });
            console.log(error.response,'mail già utilizzata');
          } else {
            ElMessage({
              message: 'Errore catch!',
              type: 'error',
            });
          }
          console.log(error);
        }
      };

      const rimuoviCar = (e, counter) => {
        customer.cars.splice(counter, 1);
        e.preventDefault();
      };

      const aggiungiCar = (e) => {
        customer.cars.push({
          intestatario: '',
          marca: '',
          modello: '',
          telaio: '',
        });
        e.preventDefault();
      };
  
      const formRef = ref(null);
      const formCarRef = ref(null);
  
      const onSubmit = async () => {
        await nextTick();
        formRef.value.validate(async (valid) => {
          if (valid) {
            formCarRef.value[0].validate(async (validcar) => {
              if (validcar) {
                const response = await addcustomer_data(toRaw(customer));
                if (response.status === 201) {
                  await addMinute();
                  router.push('/');
                  ElMessage({
                    message: 'Cliente aggiunto con successo!',
                    type: 'success',
                  });
                } else if (response.status === 400) {
                  ElMessage({
                    message: 'Errore1!',
                    type: 'error',
                  });
                  console.log('errore inatteso');
                } else if (response.status === 409) {
                  ElMessage({
                    message: 'Mail già usata!',
                    type: 'error',
                  });
                  console.log('mail già utilizzata');
                }
              } else {
                //this.$router.push("/addcustomer_employee");
                ElMessage({
                  message: 'Errore 2!',
                  type: 'error',
                });
              }
            });
          }
        });
      };
  
      const annulla = () => {
        router.back();
      };
  
      return {
        formRule,
        transmission,
        options,
        addcustomer_data,
        customer,
        props1,
        rimuoviCar,
        aggiungiCar,
        Delete,
        TitleComponent,
        onSubmit,
        formRef,
        formCarRef,
        isPrivate,
        annulla,
        loadPoints,
        loadingPoint,
        handleInputBlur,
        storeLogin,
        minutes,
        carsId,
        handleChangeValet,
        rimuoviMinute,
        aggiungiMinute,
      };
    },
    watch: {
      formatCar() {
        // if(this.customer.cars[0].targa!=''){
        this.customer.cars.forEach((car) => {
          let tableRow = {
            value: car.id,
            label: `${car.marca} ${car.modello} ${car.targa}`,
          };
  
          this.carsId.value.push(tableRow);
        });
        //  }
      },
    },
  };
</script>