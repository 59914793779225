<template>
  <div class="common-layout">
    <el-container>
      <el-aside v-if="store.isLogged == true" width="200px">
        <div
          class="bottom"
          style="margin-top: 10px; text-align: left; margin-left: 10px"
        >
          <ButtonComponent
            :ifCondition="true"
            :isRouter="false"
            @buttonClick="handleButtonClick"
            icon="MoreFilled"
            style=""
          ></ButtonComponent>
        </div>

        <el-menu
          class="el-menu-vertical-demo"
          :collapse="isCollapse"
          router="true"
        >
          <el-sub-menu index="1">
            <template #title>
              <el-icon><Calendar /></el-icon>
              <span>Booking</span>
            </template>
            <el-menu-item-group>
              <div @click="navigateToArea()">
                <el-menu-item index="/booking">Tutte le zone</el-menu-item>
              </div>
              <div
                v-for="(area, counter_area) in areas"
                :key="counter_area"
                @click="navigateToArea(area.id)"
              >
                <el-menu-item>{{ area.area }}</el-menu-item>
              </div>
            </el-menu-item-group>
          </el-sub-menu>

          <router-link class="routerLink" to="/driver">
            <el-menu-item index="2">
              <el-icon><Van /></el-icon>
              <template #title>Driver</template>
            </el-menu-item>
          </router-link>
          <router-link class="routerLink" to="/customer">
            <el-menu-item index="3">
              <el-icon><User /></el-icon>
              <template #title>Clienti</template>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/partner">
            <el-menu-item index="4">
              <el-icon><OfficeBuilding /></el-icon>
              <template #title>Partner</template>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/agency">
            <el-menu-item index="5">
              <el-icon><School /></el-icon>
              <template #title>Agenzie</template>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/area">
            <el-menu-item index="6">
              <el-icon><LocationInformation /></el-icon>
              <template #title>Zone</template>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/contabilita">
            <el-menu-item index="7">
              <el-icon><SuitcaseLine /></el-icon>
              <template #title>Contabilità</template>
            </el-menu-item>
          </router-link>

          <el-menu-item index="8" @click="logout">
            <el-icon><DocumentDelete /></el-icon>
            <span>Logout</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { isLoggedStore } from './store/logged';
import ButtonComponent from './components/ButtonComponent.vue';
import areasAPI from './services/areasAPI.js';
export default {
  name: 'App',
  components: { ButtonComponent },
  setup() {
    const store = isLoggedStore();
    const router = useRouter();
    const logout = () => {
      /*store.isLogged = false;
      store.nome = '';
      store.tipo = '';
      router.push('/login');*/
      store.viewMenu = false;
      store.isLogged = false;
      store.role = null;
      store.jwt = null;
      router.push('/login');
    };

    let isCollapse = ref(false);

    const handleButtonClick = () => {
      isCollapse.value = !isCollapse.value;
    };

    let areas = ref([]);
    const loadArea = async () => {
      const resp = await areasAPI.getAreas();

      areas.value = resp.data;
    };
    loadArea();

    return {
      logout,
      store,
      isCollapse,
      handleButtonClick,
      areas,
    };
  },

  methods: {
    navigateToArea(areaId) {
      if (areaId) {
        window.location.href = `/booking?area=${areaId}`;
      } else {
        window.location.href = `/booking`;
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.routerLink {
  text-decoration: none;
}

.max-height {
  min-height: 100vh;
  height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
