<template>
  <TitleComponent title="Aggiungi Zone" />
  <el-card class="custom-card">
    <h4>Aggiungi aree</h4>
    <div v-for="(areaNew, counter_area) in areasNew" v-bind:key="counter_area">
      <el-form ref="form2" :model="areaNew">
        <br />
        <InputComponent
          :value="areaNew.area"
          prop="area"
          label="Area"
          @input="(newValue) => (areaNew.area = newValue.target.value)"
          :showEeuro="false"
          :disabled="disabled"
        />
        <div class="input-group">
          <label for="valetInput">Driver </label>
          <el-input-number
            v-model="areaNew.num_driver"
            :min="1"
            :max="10"
            @change="handleChangeDriver(minute)"
          />
          <label for="valetInput">Jolly</label>
          <el-input-number
            v-model="areaNew.num_jolly"
            :min="1"
            :max="10"
            @change="handleChangeDriver(minute)"
          />
        </div>

        <FormPointComponent
          :inputPoint="areaNew.default_point"
          :handleInputBlur="handleInputBlur(areaNew)"
          :showDescription="true"
          :loadingPoint="loadingPoint"
          :label="areaNew.default_point.label"
          :deletePoint="false"
          :ifCondition="counter_area != 0 ? true : false"
          :counter="counter_area"
          :showAbbonamento="false"
          :showArea="false"
          :selectArea="false"
        ></FormPointComponent>
      </el-form>
    </div>
    <br />
    <ButtonComponent
      label="Aggiungi zona"
      :isRouter="false"
      @buttonClick="aggiungiArea"
      type="success"
      :plain="true"
    ></ButtonComponent>

    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/area"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Aggiungi le nuove aree"
        :isRouter="false"
        @buttonClick="addArea()"
      ></ButtonComponent>
    </el-row>
  </el-card>
</template>

<script>
import areasAPI from '../../services/areasAPI.js';
import driverAPI from '../../services/driverAPI.js';
import prenotazioneAPI from '../../services/prenotazioneAPI.js';
import pointAPI from '../../services/pointAPI.js';

import { reactive, toRaw, ref } from 'vue';

import { ElMessage } from 'element-plus';
import TitleComponent from '../../components/TitleComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormPointComponent from '../../components/FormPointComponent.vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    ButtonComponent,
    TitleComponent,
    InputComponent,
    FormPointComponent,
  },
  setup() {
    const router = useRouter();

    const areasNew = reactive([
      {
        area: '',
        num_driver: 1,
        num_jolly: 1,
        default_point: {
          address: '',
          street_number: '',
          cap: '',
          city: '',
          province: '',
          label: '',
        },
      },
    ]);

    const rimuoviArea = (e, counter) => {
      areasNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiArea = (e) => {
      areasNew.push({
        area: '',
        num_driver: 1,
        num_jolly: 1,
        default_point: {
          address: '',
          street_number: '',
          cap: '',
          city: '',
          province: '',
          label: '',
        },
      });
      e.preventDefault();
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await prenotazioneAPI.getPoints({
          text: pointToString(point),
        });
        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    const handleInputBlur = async (area) => {
      if (
        area.default_point.address != '' &&
        area.default_point.street_number != '' &&
        area.default_point.city != '' &&
        area.default_point.cap != '' &&
        area.default_point.province != ''
      ) {
        let response = await loadPoints(area.default_point);

        area.default_point.label = response.label;
        area.default_point.latitude = response.point.latitude;
        area.default_point.longitude = response.point.longitude;

        area.default_point.address = response.point.address;
        area.default_point.street_number = response.point.street_number;
        area.default_point.city = response.point.city;
        area.default_point.cap = response.point.cap;
        area.default_point.province = response.point.province;
      }
    };

    const addArea = async () => {
      let response = {};
      try {
        const areasPayload = { bulk: toRaw(areasNew) };
        response = await areasAPI.addAreas(areasPayload);
        for (let area of response.data) {
          let point = await pointAPI.postPoint(area.default_point);
          for (let d = 0; d < area.num_driver; d++) {
            const driver = {
              name: 'test',
              surname: 'test',
              mail: 'driver' + d + area.area + '@mail.com',
              telephone_number: '',
              password: 'pw',
              data_reg: '2023-12-27T12:52:15.000Z',
              role: 'driver',
              refreshtoken: null,
              refreshtokenexpires: null,
              date_birth: null,
              refresh_token: null,
              refresh_token_expires: null,
              device_token: '',
              CF: null,
              iban: '',
              transmission: 'both',
              is_jolly: false,
              payed: false,
              is_alive: true,
              avatar: null,
              emergency_telephone_number: null,
              areas: [{ id: area.id, area: area.area }],
              default_point: point.data,
            };
            await driverAPI.postDriverTest(driver);
          }

          for (let d = 0; d < area.num_driver; d++) {
            const driver = {
              name: 'test',
              surname: 'test',
              mail: 'jolly' + d + area.area + '@mail.com',
              telephone_number: '',
              password: 'pw',
              data_reg: '2023-12-27T12:52:15.000Z',
              role: 'driver',
              refreshtoken: null,
              refreshtokenexpires: null,
              date_birth: null,
              refresh_token: null,
              refresh_token_expires: null,
              device_token: '',
              CF: null,
              iban: '',
              transmission: 'both',
              is_jolly: true,
              payed: false,
              is_alive: true,
              avatar: null,
              emergency_telephone_number: null,
              areas: [{ id: area.id, area: area.area }],
              default_point: point.data,
            };
            await driverAPI.postDriverTest(driver);
          }
        }
        router.push('/area');
        return response;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };

    return {
      rimuoviArea,
      aggiungiArea,
      areasNew,
      addArea,
      loadingPoint,
      handleInputBlur,
    };
  },

  methods: {},
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
