import API from './API';

export default { 
    getPoints(text){
        return API.post('/planner/maps-geocode', text)
    },

    calcSlot(deatl) {
      return API.post('/planner/slot-availables/', deatl);
    },
      
}