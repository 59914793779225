import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '../Views/LoginPage.vue';
import PartnerPage from '@/Views/partner/PartnerPage.vue';
import HeadquarterPage from '@/Views/partner/HeadquarterPage.vue';
import DetailPartnerPage from '@/Views/partner/DetailPartnerPage.vue';
import DetailHeadquarterPage from '@/Views/partner/DetailHeadquarterPage.vue';
import DetailEmployeePage from '@/Views/partner/DetailEmployeePage.vue';
import BookingPage from '../Views/booking/BookingPage.vue';
import BookingDetailsPage from '../Views/booking/BookingDetailsPage.vue';

import AddPartnerPage from '@/Views/partner/AddPartnerPage.vue';
import AddAgencyPage from '@/Views/agency/AddAgencyPage.vue';
import AgencyPage from '@/Views/agency/AgencyPage.vue';
import DetailAgencyPage from '@/Views/agency/DetailAgencyPage.vue';
import ContabilitaPage from '@/Views/contabilita/ContabilitaPage.vue';
import Cod1Page from '@/Views/contabilita/COD1/Cod1Page.vue';
import Cod2Page from '@/Views/contabilita/COD2/Cod2Page.vue';
import Cod3Page from '@/Views/contabilita/COD3/Cod3Page.vue';
import Cod4PageBooking from '@/Views/contabilita/COD4/Cod4Page.vue';
import Cod4PageValet from '@/Views/contabilita/COD4/Cod4ValetPage.vue';
import Cod5Page from '@/Views/contabilita/COD5/Cod5Page.vue';
import Cod6Page from '@/Views/contabilita/COD6/Cod6Page.vue';
import DetailCod6Page from '@/Views/contabilita/COD6/DetailCod6Page.vue';
import BookingDriverModifyPage from '@/Views/booking/BookingDriverModifyPage';
import BookingPointModifyPage from '@/Views/booking/BookingPointModifyPage';
import DriverPage from '@/Views/driver/DriverPage';
import DriverDetailPage from '@/Views/driver/DriverDetailsPage';
import AddDriverPage from '@/Views/driver/AddDriverPage';
import CustomerPage from '@/Views/customer/CustomerPage';
import AddCustomerPage from '@/Views/customer/AddCustomerPage';
import CustomerDetailPage from '@/Views/customer/CustomerDetailPage';
import AddAgenziaPage from '@/Views/customer/AddAgenziaPage';
import ZonaPage from '@/Views/zona/ZonaPage';
import AddZonaPage from '@/Views/zona/AddZonaPage';
import ResetPassword from '@/Views/ResetPasswordPage';
//import SearchPage from '../Views/SearchPage.vue';
import { isLoggedStore } from '@/store/logged';
const routes = [
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/partner',
    component: PartnerPage,
  },
  {
    path: '/add-partner',
    component: AddPartnerPage,
  },
  {
    path: '/agency',
    component: AgencyPage,
  },
  {
    path: '/add-agency',
    component: AddAgencyPage,
  },
  {
    path: '/area',
    component: ZonaPage,
  },
  {
    path: '/add-area',
    component: AddZonaPage,
  },
  {
    path: '/agency/:id',
    component: DetailAgencyPage,
  },
  {
    path: '/partner/:id',
    component: DetailPartnerPage,
  },
  {
    path: '/headquarter/:id',
    component: HeadquarterPage,
  },
  {
    path: '/employee/:id',
    component: DetailEmployeePage,
  },
  {
    path: '/headquarter-detail/:id',
    component: DetailHeadquarterPage,
  },
  {
    path: '/booking',
    name: 'Booking',
    component: BookingPage,
  },
  {
    path: '/contabilita',
    name: 'Contabilita',
    component: ContabilitaPage,
  },
  {
    path: '/contabilita/cod1/:id',
    name: 'Cod1Page',
    component: Cod1Page,
  },
  {
    path: '/contabilita/cod2/:id',
    name: 'Cod2Page',
    component: Cod2Page,
  },
  {
    path: '/contabilita/cod3/:id',
    name: 'Cod3Page',
    component: Cod3Page,
  },
  {
    path: '/contabilita/cod4/:id',
    name: 'Cod4PageBooking',
    component: Cod4PageBooking,
  },

  {
    path: '/contabilita/cod5/:id',
    name: 'Cod5Page',
    component: Cod5Page,
  },
  {
    path: '/contabilita/cod6/:id',
    name: 'Cod6Page',
    component: Cod6Page,
  },

  {
    path: '/contabilita/cod6/detail/:id',
    name: 'DetailCod6Page',
    component: DetailCod6Page,
  },

  {
    path: '/contabilita/cod7/:id',
    name: 'Cod4PageValet',
    component: Cod4PageValet,
  },

  {
    path: '/booking/detail/:id',
    name: 'BookingDetail',
    component: BookingDetailsPage,
  },
  {
    path: '/booking/driver/:id',
    name: 'BookingDriverDetail',
    component: BookingDriverModifyPage,
  },
  {
    path: '/booking/point/:id',
    name: 'BookingPointModifyPage',
    component: BookingPointModifyPage,
  },
  {
    path: '/driver',
    name: 'DriverPage',
    component: DriverPage,
  },
  {
    path: '/driver/detail/:id',
    name: 'DriverDetailPage',
    component: DriverDetailPage,
  },
  {
    path: '/driver/add-driver',
    name: 'AddDriverPage',
    component: AddDriverPage,
  },
  {
    path: '/customer',
    name: 'Customer',
    component: CustomerPage,
  },
  {
    path: '/customer/add-customer',
    name: 'AddCustomer',
    component: AddCustomerPage,
  },
  {
    path: '/customer/detail/:id',
    name: 'CustomerDetail',
    component: CustomerDetailPage,
  },
  {
    path: '/customer/add-agenzia',
    name: 'AddAgenzia',
    component: AddAgenziaPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = isLoggedStore();
  if (
    store.isLogged == false &&
    to.name !== 'Login'
  ) {
    if (to.name !== 'ResetPassword') {
      console.log('not auth');
      return { name: 'Login' };
    }
  } 
});

export default router;
