<template>
    <div class="point-container">
        <InputComponent
        :value="singleCar.intestatario"
        label="Intestatario"
        prop="intestatario"
        :showEeuro="false"
        :disabled="false"
        @input="(newValue) => (singleCar.intestatario = newValue.target.value)"
        />

        <InputComponent
        :value="singleCar.marca"
        title="Marca"
        label="Marca"
        prop="marca"
        :showEeuro="false"
        @input="(newValue) => (singleCar.marca = newValue.target.value)"
        />

        <InputComponent
        :value="singleCar.modello"
        title="Modello"
        label="Modello"
        prop="modello"
        :showEeuro="false"
        @input="(newValue) => (singleCar.modello = newValue.target.value)"
        />

        <InputComponent
        :value="singleCar.targa"
        title="Targa"
        label="Targa"
        prop="targa"
        :showEeuro="false"
        @input="(newValue) => (singleCar.targa = newValue.target.value)"
        />
    
        <SelectComponent
        @selected="(newValue)=>(singleCar.transmission=newValue)"
        
        :options="options"
        :value="singleCar.transmission"
        title="Selezionare tipo di cambio"
        :addButtom="false"
        :isDisabled="false"
        :showEeuro="false"
        ></SelectComponent>

        <el-form-item v-if="modifyButom">
            <ButtonComponent label="Modifica" :isRouter=true  @buttonClick="handleButtonClick(single_car)"></ButtonComponent> 
        </el-form-item>


        <el-form-item v-if="deleteCar">
            <ButtonComponent :isRouter="false" @buttonClick="handleButtonClickDelete($event, counterCar)" type="danger" icon="Delete"></ButtonComponent>
        </el-form-item>
    </div>
 
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue";
import InputComponent from './InputComponent.vue';
import SelectComponent from './SelectComponent.vue';

export default {
  components: { ButtonComponent, InputComponent, SelectComponent },
  props: {
    title: {
      type: String,
      required: true,
    },
    car: {
      required: true,
    },
    options: {
      required: true,
    },
    buttonClickDelete:{
      required: false,
    },
    counterCar:{
      required:false
    },
    isDisabled:{
      deafult:false
    },
    deleteCar:{
      required:false,
      default:true,
      type:Boolean
    },
    modifyButom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      singleCar: this.car,
    };
  },
  
  methods: {
    handleButtonClick() {
        this.$emit('buttonClickModifica');
    },
    handleButtonClickDelete() {
      this.$emit('buttonClickDelete', this.singleCar, this.counterCar);
    },
  }
};
</script>

<style>

.point-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .point-container > div {
    flex: 1;
    min-width: 14%; 
  }
</style>
