import API from './API';

export default { 
    getEmployee(employeeId) {
        return API.get(`/employee/?filter=id||$eq||${employeeId}`)
      },

      getEmployeesByPartner(partnerId) {
        return API.get(
          `/headquarter?filter=partner.id||$eq||${partnerId}`,
          {
            params: {
              join: ['partner', 'employees'],
            },
          }
        );
      },

      
  
  addHeadquarterToEmployee(data) {
    return API.post('/employee/add-headquarter' , data)
  },
}