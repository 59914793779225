<template>
    <TitleComponent :title="tableData.name + ' ' + tableData.surname" />
    <el-card class="custom-card">
      <el-form ref="formRef" :model="tableData" :rules="formRule">
        <div class="add-button">
          <ButtonComponent
            label="Elimina cliente"
            type="danger"
            :isRouter="false"
            @buttonClick="deleteC(tableData.id)"
          ></ButtonComponent>
        </div>
        <FormCustomerComponent :value="tableData" :rules="formRule" />
        <el-divider />
        <TitleComponent title="AUTO DEL CLIENTE" />
        <el-card class="custom-card">
            <div
                v-for="(single_car, counter_car) in carsEditable"
                v-bind:key="counter_car"
            >
                <el-form
                ref="form2"
                :model="single_car"
                :rules="formRuleCar(counter_car)"
                >
                <FormCarComponent
                    :car="single_car"
                    :type="type"
                    :rules="formRule"
                    :options="options"
                    :deleteCar="false"
                    :modifyButom="true"
                    :counterCar="counter_car"
                    @change="handleChange"
                    @buttonClickModifica="modifyCar(single_car)"
                    @buttonClickDelete="deleteCar(single_car)"
                ></FormCarComponent>
                </el-form>
            </div>

            <div
                v-for="(single_car_new, counter_car) in carsNew"
                v-bind:key="counter_car"
            >
                <el-divider />
                <el-form
                ref="form2"
                :model="single_car_new"
                :rules="formRuleCar(counter_car)"
                >
                    <FormCarComponent
                        :car="single_car_new"
                        :type="type"
                        :rules="formRule"
                        :options="options"
                        :deleteCar="true"
                        :modifyButom="false"
                        @change="handleChange"
                        @buttonClickDelete="rimuoviCar"
                        :counterCar="counter_car"
                    ></FormCarComponent>
                </el-form>
            </div> 
            <ButtonComponent
                label="Aggiungi auto"
                :isRouter="false"
                @buttonClick="aggiungiCar"
                type="success"
                :plain="true"
            ></ButtonComponent>

            <div v-if="carsNew.length > 0">
                <el-row justify="center" style="margin-top: 3.5%">
                <ButtonComponent
                    label="Annulla"
                    :isRouter="true"
                    linkPath="/customer"
                    @buttonClick="annulla"
                ></ButtonComponent>

                <ButtonComponent
                    label="Conferma"
                    :isRouter="false"
                    type="primary"
                    @buttonClick="onSubmitCar('form2')"
                ></ButtonComponent>
                </el-row>
            </div>
        </el-card>
      </el-form>
  
      <el-row justify="center" style="margin-top: 3.5%">
        <ButtonComponent
          label="Annulla"
          :isRouter="true"
          linkPath="/customer"
          @buttonClick="back"
        ></ButtonComponent>
        <ButtonComponent
          label="Modifica i dati del cliente"
          :isRouter="true"
          linkPath="/customer"
          @buttonClick="update"
        ></ButtonComponent>
      </el-row>
    </el-card>
</template>

<script>
import TitleComponent from '../../components/TitleComponent.vue';
import FormCustomerComponent from '../../components/FormCustomerComponent.vue'
import ButtonComponent from '../../components/ButtonComponent.vue'
import FormCarComponent from '../../components/FormCarComponent.vue'

import { ElMessage } from 'element-plus';

import { useRouter, useRoute } from "vue-router";
import customerAPI from '../../services/customerAPI.js';
import carAPI from '../../services/carAPI.js';
import { ref, toRaw, reactive } from "vue";
//const dayjs = require("dayjs");

export default {
  components: {
    TitleComponent,
    FormCustomerComponent,
    ButtonComponent,
    FormCarComponent
  },
  setup() {
    const route = useRoute();
    
    const router = useRouter();
    //const driverId = route.params.id.split("-")[0];
    let tableData = ref({
        id: "",
        name: "",
        surname: "",
        mail: "",
        telephone_number: "",
        date_birth: "",
        address: "",
    });

    const carsEditable = reactive([]);
    const carsNew = reactive([]);

    const processCar = (car) => {
      const tableRow = {
        id: car.id,
        intestatario: car.intestatario,
        marca: car.marca,
        modello: car.modello,
        targa: car.targa,
        transmission: car.transmission,
        customer: car.customer,
      };
      return tableRow;
    };

    const formRuleCar = (counter) => {
      return {
        intestatario: [
          {
            required: true,
            message: 'Inserire intestatario macchina ' + counter,
            trigger: 'blur',
          },
        ],
        marca: [
          {
            required: true,
            message: 'Inserire marca',
            trigger: 'blur',
          },
        ],
        modello: [
          {
            required: true,
            message: 'Inserire modello',
            trigger: 'blur',
          },
        ],
        targa: [
          {
            required: true,
            message: 'Inserire targa',
            trigger: 'blur',
          },
        ],
        transmission: [
          {
            required: true,
            message: 'Scegliere cambio',
            trigger: 'blur',
          },
        ],
      };
    };

    const loadCliente = async () => {
      try {
        const resp = await customerAPI.getCustomerById(route.params.id);
        const customer = resp.data;
        tableData.value.id = customer.id;
        tableData.value.name = customer.name;
        tableData.value.surname = customer.surname;
        tableData.value.mail = customer.mail;
        tableData.value.date_birth = customer.date_birth;
        tableData.value.telephone_number = customer.telephone_number;
        tableData.value.address = customer.address;

        await loadCars(customer.id);

      } catch (e) {
        console.log('errore load tariffe: ', e);
      }
    };

    loadCliente();


    const loadCars = async (customerId) => {
      try {
        //const partner = await partnerAPI.getPartnerByEmployee(employeeId);
        const response = await carAPI.getCarsByCustomer(customerId);

        response.data.forEach((car) => {
          if (car.minutes.length !== 0) {
            car.minutes.forEach((minute) => {
              if (minute.accepted == true) {
                const tableRow = processCar(car);
                
                carsEditable.push(tableRow);
              }
            });
          } else {
            const tableRow = processCar(car);
            
            carsEditable.push(tableRow);
            
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const options = [
      {
        value: 'manual',
        label: 'manuale',
      },
      {
        value: 'automatic',
        label: 'automatico',
      },
      {
        value: 'both',
        label: 'entrambi',
      },
    ];

    const modifyCar = async (car) => {
      let response;
      try {

        response = await carAPI.modifyCar(car);
        if (response.status === 200) {
          ElMessage({
            message: 'Macchina modificata con successo!',
            type: 'success',
          });
        } else {
          ElMessage({
            message: 'Errore modifica auto!',
            type: 'error',
          });
          console.log('errore modifica auto');
        }
      } catch (e) {
        console.log(e);
      }
    };

    const update = () => {
      updCustomer(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Customer aggiornato con successo!',
            type: 'success',
          });
          router.push('/customer');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updCustomer = async (customer) => {
      let response = {};
      try {
        response = await customerAPI.updCustomer(customer);
        return response;
      } catch {
        console.log('error');
      }
    };

    const rimuoviCar = (e, counter) => {
      carsNew.splice(counter, 1);
      //e.preventDefault();
    };
    const aggiungiCar = () => {
      carsNew.push({
        intestatario: '',
        marca: '',
        modello: '',
        telaio: '',
        transmission: '',
        customer: {},
        headquarters: [{}],
      });
    };

    const addCar = async (car) => {
      let response = {};
      try {
        car.customerId = tableData.value.id
        response = await carAPI.addCar(car);
        return response;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };

    const deleteC = (id) => {
      deleteCustomer(id).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Cliente eliminato con successo!',
            type: 'success',
          });
          router.push('/customer');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const deleteCustomer = async (id) => {
      let response = {};
      try {
        response = await customerAPI.deleteCustomer(id);
        return response;
      } catch {
        console.log('error');
      }
    };

    return { 
        loadCliente,
        tableData,
        update,
        loadCars,
        carsEditable,
        carsNew,
        formRuleCar,
        options,
        modifyCar,
        rimuoviCar,
        aggiungiCar,
        addCar,
        deleteC

    }
  },
  methods: {
    onSubmitCar(formName) {
        console.log(this.$refs[formName][0])
        this.$refs[formName][0].validate((valid) => {
        
          if (valid) {
            this.carsNew.forEach((car) => {
              car.customer = this.customer;
              //car.headquarters = this.headquarters.headquarters;
              this.addCar(toRaw(car)).then((response) => {
                if (response.status === 201) {
                  this.$router.push('/');
                  //this.$router.push({name:'home', params: {id: '[paramdata]'}});
                  ElMessage({
                    message: 'Macchina aggiunta con successo!',
                    type: 'success',
                  });
                } else {
                  ElMessage({
                    message: 'Errore inserimento auto!',
                    type: 'error',
                  });
                  console.log('errore aggiunta auto');
                }
              });
            });
          } else {
            console.log('erroree');
          }
      });
    },
  }
}
</script>

<style>
.highlight {
    border: 2px solid rgb(205, 54, 54); 
  }
.point-container {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;
}

.point-container > div {
  flex: 7;
  min-width: 15%;
  justify-content: center;
}
</style>