<template>
  <TitleComponent title="Aggiungi Agenzia" />
  <el-card class="custom-card">

    <el-form ref="formRef" :model="tableData" :rules="formRule">
        <FormAgencyComponent :value="tableData" :type="type" :rules="formRule" />
    </el-form>

    <h4>Aggiungi aree</h4>
    <div
      v-for="(areaNew, counter_area) in areasNew"
      v-bind:key="counter_area"
    >
      <el-form ref="form2" :model="areaNew">
        <br />
        <SelectComponent
        @selected="handleSelectArea"
        :options="areaExisisting"
        title="Scegli un'area"
        :addButtom="counter_area != 0 ? true : false"
        :isDisabled="false"
        :value="areaNew.id"
        :counterElement="counter_area"
        @buttonClick="rimuoviArea"
      />
      </el-form>
    </div>
    <br />
    <ButtonComponent
      label="Aggiungi area"
      :isRouter="false"
      @buttonClick="aggiungiArea"
      type="success"
      :plain="true"
    ></ButtonComponent>

    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/agency"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Aggiungi la nuova agenzia"
        :isRouter="false"
        @buttonClick="onSubmit('formRef')"
      ></ButtonComponent>
    </el-row>
  </el-card>
</template>

<script>
import agencyAPI from '@/services/agencyAPI';

import { ref, toRaw, reactive } from 'vue';

import { ElMessage } from 'element-plus';
import TitleComponent from '../../components/TitleComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormAgencyComponent from '../../components/FormAgencyComponent.vue';
import SelectComponent from '../../components/SelectComponent.vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    FormAgencyComponent,
    ButtonComponent,
    TitleComponent,
    SelectComponent,
  },
  setup() {
    
    const router = useRouter();

    let tableData = ref({
      company_name: '',
      name: '',
      surname: '',
      telephone_number: '',
      mail: '',
      priority: 1,
      document: '',
      areas: [],
    });

    let areaExisisting = ref([]);
      let areaExisistingResponse = ref([]);
      let areaAddNew = ref([]);
      const loadAreas = async () => {
      try {
        const resp = await agencyAPI.getAreas();
        areaExisistingResponse.value= resp.data;

        resp.data.forEach((area) => {

            let tableRow = {
              value: area.id,
              label: `${area.area}`,
            };
            areaExisisting.value.push(tableRow);

        });
      } catch (e) {
        console.log('errore load area: ', e);
      }
    };
    loadAreas();

    
    const handleSelectArea = (newValue) => {
      
      const selectedArea = areaExisistingResponse.value.find(
        (hq) => hq.id === newValue[0]
      );
       if (selectedArea) {
        areaAddNew.value.push(selectedArea);
      } 
    };


    const areasNew = reactive([
      {
        area: '',
      },
    ]);

    const rimuoviArea = (e, counter) => {
      areasNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiArea = (e) => {
      areasNew.push({
        area: '',
      });
      e.preventDefault();
    };
    const addAgency = async (agency) => {
      let response = {};
      try {
        response = await agencyAPI.addAgency(agency);
        return response;
      } catch (error) {
        ElMessage({
          message: 'Error!',
          type: 'error',
        });
        console.log(error);
      }
    };
    
    const formRef = ref(null);
    const onSubmit = async () => {
      tableData.value.areas = areaAddNew.value;

      formRef.value.validate(async (valid) => {

        if (valid) {

            addAgency(  toRaw(tableData.value)).then(async (response) => {
                if(response.status==201){
                    router.push('/agency');

                    ElMessage({
                      message: 'Agenzia aggiunta con successo!',
                      type: 'success',
                    });
                  
                }
            })

        } else {
          console.log('erroree');
        }
      });
    };

    let formRuleAgency = reactive({
        company_name: [
          { required: true, message: 'Inserire ragione sociale', trigger: 'blur' },
        ],
          name:  [
          { required: true, message: 'Inserire nome', trigger: 'blur' },
        ],
        surname:  [
          { required: true, message: 'Inserire cognome', trigger: 'blur' },
        ],
          mail:  [
          { required: true, message: 'Inserire mail', trigger: 'blur' },
        ],
          telephone_number:  [
          { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
        ],
          document:  [
          { required: true, message: 'Inserire documento', trigger: 'blur' },
        ]
      
    });

    return {
      tableData,
      rimuoviArea,
      aggiungiArea,
      areasNew,
      loadAreas,
areaExisistingResponse,
 areaExisisting,
 handleSelectArea,
 areaAddNew,
      formRef,
      onSubmit,
      formRuleAgency,
    };
  },

  methods: {},
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
