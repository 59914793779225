<template>
    <div style="margin-top: 40px">
        <h1>Modifica driver del booking {{ booking_info.date }}</h1>
    </div>
    <el-card class="custom-card">
        <el-form ref="form2" :model="headquarternew">
            <br />
            <SelectComponent
              @selected="handleSelectDriverAndata"
              :options="driverAndata"
              title="Scegli uno dei driver disponibili"
              :addButtom="true"
              :isDisabled="false"
              :value="driverAndataNew.id"
              :counterElement="counter_headquarter"
              @buttonClick="rimuoviHeadquarter"
            />
        </el-form>
    </el-card>
</template>

<script>
import { ref } from "vue";
import bookingAPI from "../../services/bookingAPI.js";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import SelectComponent  from "../../components/SelectComponent.vue"
export default {
    name: "HomePage",
    components: {SelectComponent},
    setup() {      
  
      const route = useRoute();
      const bookingId = route.params.id.split("-")[0];
      const booking_info = reactive({
        id: "",
        driver_andata: "",
        driver_ritorno: "",
  
      });
  
      let is_gone = ref(false);
      let is_return = ref(false);

      let fullDriversAndata = ref([]);
      let driverAndataNew = ref([]);
      let driverAndata = ref([]);
      
      const loadBooking = async () => {
        try {
          const response = await bookingAPI.getBookingById(route.params.id);
          const booking = response.data;
          
          booking_info.id = booking.id;
          
          if (booking.pickup_bookings && booking.pickup_bookings.length > 0) {            
  
            for (let i = 0; i < booking.pickup_bookings.length; i++) {
              const currentBooking = booking.pickup_bookings[i];
  
              if (currentBooking.type_booking === "gone") {
                is_gone.value = true;
                currentBooking.driver_pickup != null && currentBooking.driver_pickup.is_alive==false
                  ? (booking_info.driver_andata =
                      currentBooking.driver_pickup.name +
                      " " +
                      currentBooking.driver_pickup.surname)
                  : "Non assegnato";
              } else {
                is_return.value = true;

                currentBooking.driver_pickup != null && currentBooking.driver_pickup.is_alive==false
                  ? (booking_info.driver_ritorno =
                      currentBooking.driver_pickup.name +
                      " " +
                      currentBooking.driver_pickup.surname)
                  : "Non assegnato";
   
              }
            }
          }
  
          if (booking.escort_bookings && booking.escort_bookings.length > 0) {
            for (let i = 0; i < booking.escort_bookings.length; i++) {
              const currentBooking = booking.escort_bookings[i];
  
  
              if (currentBooking.type_booking === "gone") {
                is_gone.value = true;
                currentBooking.driver_escort != null && currentBooking.driver_escort.is_alive==false
                  ? (booking_info.driver_andata =
                      currentBooking.driver_escort.name +
                      " " +
                      currentBooking.driver_escort.surname)
                  : "Non assegnato";
              } else {
                is_return.value = true;
  
                currentBooking.driver_escort != null && currentBooking.driver_escort.is_alive==false
                  ? (booking_info.driver_ritorno =
                      currentBooking.driver_escort.name +
                      " " +
                      currentBooking.driver_escort.surname)
                  : "Non assegnato";
              }
            }
          }
          if (booking.delivery_bookings && booking.delivery_bookings.length > 0) {
            for (let i = 0; i < booking.delivery_bookings.length; i++) {
  
              const currentBooking = booking.delivery_bookings[i];
  
              if (currentBooking.type_booking === "gone") {
                is_gone.value = true;
  
                currentBooking.driver_delivery != null && currentBooking.driver_delivery.is_alive==false
                  ? (booking_info.driver_andata =
                      currentBooking.driver_delivery.name +
                      " " +
                      currentBooking.driver_delivery.surname)
                  : "Non assegnato";
  
              } else {
                is_return.value = true;
  
                currentBooking.driver_delivery != null && currentBooking.driver_delivery.is_alive==false
                  ? (booking_info.driver_ritorno =
                      currentBooking.driver_delivery.name +
                      " " +
                      currentBooking.driver_delivery.surname)
                  : "Non assegnato";
              }
            }
          }
  
          return booking;
        } catch (e) {
          console.log(e);
        }
      };
  
      loadBooking();
  
      const handleSelectDriverAndata = (newValue) => {
      
        const selectedDriverAndata = fullDriversAndata.value.find(
            (hq) => hq.id === newValue[0]
        );

        if (selectedDriverAndata) {
            driverAndataNew.value.push(selectedDriverAndata);
        }
      };

      const loadDriverAndata = async (bookingId) => {
        try {
            const resp = await bookingAPI.getPartnerById(bookingId);

            fullDriversAndata.value = resp.data[0].headquarters;

            resp.data[0].headquarters.forEach((headquarter) => {
            let tableRow = {
                value: headquarter.id,
                label: `${headquarter.address} ${headquarter.street_number}, ${headquarter.city} ${headquarter.province}`,
            };
            driverAndata.value.push(tableRow);
            });
        } catch (e) {
            console.log('errore load driver: ', e);
        }
      };

      loadDriverAndata(bookingId);
      return {
        bookingId,
        booking_info,
        loadBooking,
        handleSelectDriverAndata,
        is_gone,
        is_return,
        driverAndataNew
      };
    },
  };
</script>