<template>
    <TitleComponent title="Aggiungi Driver" />
    <el-card class="custom-card">
      <el-form ref="formRef" :model="tableData" :rules="formRuleDriver">
        <FormAddDriverComponent :value="tableData" :rules="formRuleDriver" :options="options" @selectedAgency="handleSelectAgency"/>
        <!--<SelectComponent
          @selected="handleSelectAgency"
          :options="options"
          title="Selezionare un'agenzia"
          :addButtom="false"
          :isDisabled="false"
          :showEeuro="false"
        ></SelectComponent>-->

        <div v-if="isAgenciesSelected == true"> 
          <el-divider />
          <h4>Zone</h4>
          <div v-for="(item, index) in tableData.aree" :key="index">
            {{ tableData.aree }}
            <InputComponent
            :value="item.area"
            title="Punto di prelievo"
            :label="'Area ' + (index +1)"
            :showEeuro="false"
            :readonly="true"
          ></InputComponent>
          </div>
          <div
            v-for="(zonanew, index) in zonaNew"
            v-bind:key="index"
          >
            <el-form ref="form2" :model="zonanew">
              <br />
              <SelectComponent
                @selected="handleSelectZona"
                :options="zoneDriver"
                title="Scegli una delle sedi del partner"
                :addButtom="index != 0 ? true : false"
                :isDisabled="false"
                :value="zonanew.id"
                :counterElement="index"
                @buttonClick="rimuoviSede"
              />
            </el-form>
          </div>
          <br />
          <ButtonComponent
            label="Aggiungi sedi"
            :isRouter="false"
            @buttonClick="aggiungiArea"
            type="success"
            :plain="true"
          ></ButtonComponent>
        </div>

      </el-form>
  
      <el-row justify="center" style="margin-top: 3.5%">
        <el-form-item>
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/driver"
            @buttonClick="back"
          ></ButtonComponent>
          <el-button
            type="primary"
            style="margin: 1px 3px"
            @click="onSubmit('formRef')"
          >
            Aggiungi driver
          </el-button>
        </el-form-item>
      </el-row>
    </el-card>
</template>

<script>
import TitleComponent from '../../components/TitleComponent.vue';
import FormAddDriverComponent from '../../components/FormAddDriverComponent.vue'
import ButtonComponent from '../../components/ButtonComponent.vue'
import InputComponent from '../../components/InputComponent.vue'
import SelectComponent from '../../components/SelectComponent.vue'
import { ElMessage } from 'element-plus';

import { useRouter } from "vue-router";
import driverAPI from '../../services/driverAPI.js';
import agencyAPI from '../../services/agencyAPI';
import pointAPI from '../../services/pointAPI';
import { ref, toRaw, reactive } from "vue";

export default {
  components: {
    TitleComponent,
    FormAddDriverComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent
  },
  setup() {
    
    const router = useRouter();
    //const driverId = route.params.id.split("-")[0];
    let tableData = ref({
        name: "",
        surname: "",
        mail: "",
        telephone_number: "",
        date_birth: "",
        emergency_telephone_number: "",
        iban: "",
        CF: "",
        is_jolly: false,
        transmission: "",
        role: "driver",
        areas: [],
        is_alive: 0,
        agency: {
          id: ""
        }
    });

    let zonaNew = reactive([
      {
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      },
    ]);
    let zoneDriver = ref([]);

    let addNewArea = ref([]);
    let fullZone = ref([]);

    let options = ref([]);
    let isAgenciesSelected = ref(false);
    let selectedAgency = ref([]);

    const default_point = {
          address: '',
          street_number: '',
          cap: '',
          city: '',
          province: '',
          label: '',
        }

    let formRuleDriver = reactive({
      mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
      telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
      CF:  [
        { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
        { min: 11, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
      agency:  [
        { required: true, message: "Selezionare un'agenzia", trigger: 'blur' },
      ],
    });

    const checkHighlight = () => {
      if (tableData.value.agency.id == '') {
        ElMessage.error("Perfavore seleziona un'agenzia");
        return false;
      } else {
        return true;
      }
    }

    const onSubmit = async () => {
      tableData.value.areas = addNewArea.value;
      let point = await pointAPI.postPoint(default_point);
      tableData.value.default_point = point.data;

      formRef.value.validate(async (valid) => {
          if (valid) {
            if (checkHighlight()) {
      
            saveDriver(toRaw(tableData.value)).then((response) => {
              if (response.status === 201) {
                ElMessage({
                  message: 'Driver aggiunto con successo!',
                  type: 'success',
                });
                router.push('/driver');
              } else {
                console.log('errore inatteso');
              }
            });
          }
          }
        })
    };

    const formRef = ref(null);

    const saveDriver = async (driver) => {
      
      let response = {};
      try {
        response = await driverAPI.createDriver(driver);
        return response;
      } catch {
        console.log('error');
      }
    };

    const loadAgenzia = async () => {
      try {
        const agencies = await agencyAPI.getAgencies();

        agencies.data.forEach((a) => {
          let tableRow = {
            value: a.id,
            label: a.company_name
          }
          options.value.push(tableRow);
        })
      } catch (e) {
        console.log(e)
      }
    }

    loadAgenzia();

    const handleSelectAgency = (newValue) => {
              
        if( newValue != null) {
          selectedAgency.value = options.value.find(
            (hq) => hq.value === newValue[0]
          );
        
          isAgenciesSelected.value = true;
          loadAllSedi();
        } else {
          zoneDriver.value = [];
        }
    };

    const loadAllSedi = async () => {
      try {
        zoneDriver.value = [];
        const resp = await agencyAPI.getAgenciesById(selectedAgency.value.value);
        fullZone.value = resp.data[0].areas;

        resp.data[0].areas.forEach(async (a) => {

            let tableRow = {
                value: a.id,
                label: a.area,
            };

            zoneDriver.value.push(tableRow)
        })
      } catch (e) {
        console.log('errore load sedi: ', e);
      }
    };

    const handleSelectZona = (newValue) => {
      
      const selectedZona = fullZone.value.find(
        (hq) => hq.id === newValue[0]
      );

      if (selectedZona) {
        addNewArea.value.push(selectedZona);
      }
    };

    const aggiungiArea = (e) => {
      zonaNew.push({
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      });
      e.preventDefault();
    };

    const rimuoviSede = (e, counter) => {
      zonaNew.splice(counter, 1);
      e.preventDefault();
    };

    return { 
        tableData,
        zonaNew,
        addNewArea,
        handleSelectZona,
        zoneDriver,
        aggiungiArea,
        options,
        handleSelectAgency,
        isAgenciesSelected,
        rimuoviSede,
        formRuleDriver,
        onSubmit,
        formRef
    }
  }
}
</script>

<style scoped>
</style>