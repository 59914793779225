import API from './API';

export default {


  getCustomer() {
    return API.get('/customer?sort=surname,ASC');
  },

  getCustomerById(id) {
    return API.get(`/customer/`+ id)
  },

  updCustomer(customer) {
    return API.patch('/customer/' + customer.id, customer)
  },

  addCustomer(customer) {
    return API.post('/auth/register/' , customer)
  },

  deleteCustomer(id) {
    return API.get('/customer/delete/' + id)
  },

  getCustomerByCar(id){
    return API.get(
      `/car?filter=id||$eq||${id}`,
      {
        //return API.get(`/booking`, {
        params: {
          join: [
            'customer'
          ],
        },
     
      }
    );
  }
}