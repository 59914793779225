import API from './API';

export default {
  getHeadquartersToPartner() {
    return API.get(`/partner?sort=company_name,ASC`, {
      params: {
        join: ['headquarters'],
      },
    });
  },
  getEmployeesToHeadquarter() {
    return API.get(`/headquarter`, {
      params: {
        join: ['employees'],
      },
    });
  },
  getPartnerById(id) {
    return API.get(`/partner?filter=id||$eq||${id}&`, {
      params: {
        join: ['headquarters', 'headquarters.area'],
      },
    });
  },

  getHeadquarterById(id) {
    return API.get(`/headquarter?filter=id||$eq||${id}&`, {
      params: {
        join: ['employees', 'partner', 'area'],
      },
    });
  },

  getEmployeeById(id) {
    return API.get(`/employee?filter=id||$eq||${id}&`, {
      params: {
        join: ['headquarters', 'headquarters.partner'],
      },
    });
  },

  updatePartner(partner) {
    return API.patch('/partner/' + partner.id, partner);
  },

  getPartners() {
    return API.get('/partner?sort=company_name,ASC');
  },

  updateHeadquarter(headquarter) {
    return API.patch('/headquarter/' + headquarter.id, headquarter);
  },

  updateEmployee(employee) {
    return API.patch('/employee/' + employee.id, employee);
  },

  addEmployee(employee) {
    return API.post('/auth/register/', employee);
  },

  addPartner(partner) {
    return API.post('/partner', partner);
  },

  addHeadquarters(headquarters) {
    return API.post('/headquarter/bulk/', headquarters);
  },

  addHeadquarter(headquarter) {
    return API.post('/headquarter/', headquarter);
  },

  getHeadquarters() {
    return API.get(`/headquarter`, {
      params: {
        join: ['partner'],
      },
    });
  },
  getMinutesByPartner(partnerId) {
    return API.get(
      `/minute?filter=partner.id||$eq||${partnerId}&filter=accepted||$eq||${true}&sort=esito,ASC`,
      {
        params: {
          join: ['partner', 'car', 'car.customer', 'minutes'],
        },
      }
    );
  },

  getPartnerByEmployee(employeeId) {
    return API.get(`/employee?filter=id||$eq||${employeeId}&`, {
      params: {
        join: ['headquarters', 'headquarters.partner'],
      },
    });
  },  
  
  getPartnerByHeadquarter(headquarterId) {
    return API.get(`/partner?filter=headquarters.id||$eq||${headquarterId}&`, {
      params: {
        join: ['headquarters', ],
      },
    });
  },


  
};
