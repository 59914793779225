import API from './API';

export default {
    
  getPoints(text){
    return API.post('/planner/maps-geocode', text)
  },

  postPoint(point){
    return API.post('/point/', point)
  }
}