import API from './API';

export default {
  getAgencies() {
    return API.get(`/agency`, {
      params: {
        join: ['areas'],
      },
    });
  },
  getAgenciesById(id) {
    return API.get(`/agency?filter=id||$eq||${id}&`, {
      params: {
        join: ['areas'],
      },
    });
  },
  updateAgency(agency) {
    return API.patch('/agency/' + agency.id, agency);
  },

  getAreas() {
    return API.get('/area');
  },
  addAgency(agency) {
    return API.post('/agency', agency);
  },
};
