<template>
    <h1>COD5</h1>
    <FilterTableCod3Component
    @nomeChanged="(newValue) => (nomeFilter = newValue)"
    @cognomeChanged="(newValue) => (cognomeFilter = newValue)"
    @targaChanged="(newValue) => (targaFilter = newValue)"
    @dataChanged="(newValue) => (dataFilter = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilter"
  >
  </FilterTableCod3Component>

    
    <el-table :data="homeTable" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)' }">
      <el-table-column prop="customer_id" label="ID cliente" width="150">
        <template #default="scope">
          <el-input :value="scope.row.customer_id"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="customer_name" label="Nome" width="150" />
      <el-table-column prop="customer_surname" label="Cognome" width="150" />
      <el-table-column prop="id" label="ID Booking" width="250">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
        </el-table-column>
  
      <el-table-column prop="task"  label="Task di ogni booking">
        <template #default="scope">
          <el-table :data="scope.row.task" :show-header="true" border style="width: 100%"  :header-cell-style="{ background: 'rgb(35,44,81)' }">
            <el-table-column prop="description" width="350" label="Descrizione" />
            <el-table-column prop="cost" label="Costo" />
          </el-table>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangePage"
        :page-size="pageSize"
        :total="taskTableData.length"
      >
      </el-pagination>
  
    </div>
  </template>
  
  <script>
  import { ref, computed } from "vue";
  import contabilitaAPI from "../../../services/contabilitaAPI";
  import { useRoute } from "vue-router";
  
  import FilterTableCod3Component from "@/components/FilterTableCod3Component.vue";
  const dayjs = require('dayjs');
  export default {
   
    data() {
    return {
      pageMinute: 1,
      pageSize: 7,
    };
  },

    setup() {
      const route = useRoute();
      const input = ref("");
      const tableData = ref([]);
      const loadTask = async () => {
        try {
          const response = await contabilitaAPI.getBookingCod5(route.params.id);
          response.data.forEach((booking) => {
            let tableRow = {
              customer_id: booking.customer.id,
              customer_name: booking.customer.name,
              customer_surname: booking.customer.surname,
              id: booking.id,
              task: booking.tasks,
              targa:booking.car.targa,
              dataBooking: booking.data
            };
            
            tableRow.task.forEach((task)=>{
              if(task.pay===true){
                  tableData.value.push(tableRow);
              }
            })
          });

  
        } catch (e) {
          console.log(e);
        }
      };
      
      loadTask();
  
/*       const TaskTable = computed(function () {
        return tableData.value.filter((row) =>
        row.customer_surname.toLowerCase().includes(input.value.toLowerCase())
      );
      }); */

        let idFilter = ref("");
        let nomeFilter = ref("");
        let cognomeFilter = ref("");
        let targaFilter = ref("");
        let dataFilter = ref("");

      const homeTable= computed(function () {
            return tableData.value.filter((row) =>
                row.id.toLowerCase().includes(idFilter.value.toLowerCase()) &&
                row.customer_name.toLowerCase().includes(nomeFilter.value.toLowerCase()) &&
                row.customer_surname.toLowerCase().includes(cognomeFilter.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilter.value.toLowerCase()) &&
                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilter.value == '' ? '' : dayjs(new Date(dataFilter.value)).format("YYYY-MM-DD"))
            );
        });

        const resetFilter = async () => {
      window.location.reload();
    };

  
      return {
        loadTask,
        input,
        tableData,
        //TaskTable,
        idFilter,
        nomeFilter,
        cognomeFilter,
        targaFilter,
        dataFilter,
        homeTable,
        resetFilter
      };
    },
  computed: {
    taskTableData() {
      if (!this.homeTable || this.homeTable.length === 0) return [];
      return this.homeTable.slice(
        this.pageSize * this.pageMinute - this.pageSize,
        this.pageSize * this.pageMinute
      );
    },
  },

  methods: {
    handleCurrentChangePage(val) {
      this.pageMinute = val;
    },
  },
  components: { FilterTableCod3Component },
  };
  </script>
  
  <style>
  </style>
  