<template>
    <h1>Sedi</h1>
    <div class="mt-4">
      <FilterTablePartnerComponent
        @addressChanged="(newValue) => (addressFilter = newValue)"
        @streetNumberChanged="(newValue) => (streetNumberFilter = newValue)"
        @cityChanged="(newValue) => (cityFilter = newValue)"
        @capChanged="(newValue) => (capFilter = newValue)"
        @provinceChanged="(newValue) => (provinceFilter = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilter"
      ></FilterTablePartnerComponent>
  
      <el-table
        :data="filteredTable"
        stripe
        height="500"
        style="width: 100%"
        :header-cell-style="{ background: 'rgb(35,44,81)' }"
      >
        <el-table-column prop="id" label="id" width="100">
          <template #default="scope">
            <el-input :value="scope.row.id"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="indirizzo" width="250" />
              <el-table-column
                prop="street_number"
                label="num"
                width="60"
              />
              <el-table-column prop="cap" label="CAP" width="100" />
              <el-table-column prop="city" label="città" width="110" />
              <el-table-column prop="province" label="prov" width="70" />
        <el-table-column
          prop="employees"
          class-name="sub_row"
          label="dipendenti di ogni sedi"
          
        >
          <template #default="scope">
            <el-table :data="scope.row.employees" :show-header="false">
            
              <el-table-column prop="name" label="nome" width="170" />
              <el-table-column prop="surname" label="cognome" width="170" />
              <!-- <el-table-column prop="mail" label="mail" width="210" /> -->
              <el-table-column width="50">
                <template #default="scope">
                  <router-link
                    :to="{ path: `/employee/${scope.row.id}` }"
                    class="routerLink"
                  >
                    <el-button size="small" @click="handleClick(scope.row.id)">
                      <el-icon><DArrowRight /></el-icon>
                    </el-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column width="70">
          <template #default="scope">
            <router-link
              :to="{ path: `/headquarter-detail/${scope.row.id}` }"
              class="routerLink"
            >
              <el-button size="small" @click="handleClick(scope.row.id)">
                <el-icon><DArrowRight /></el-icon>
              </el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <router-link class="routerLink" to="/addHeadquarter">
        <el-button type="primary" @click="addpartnerClick()" plain>
          Aggiungi sede</el-button
        >
      </router-link>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import partnersAPI from '../../services/partnerAPI.js';
  import FilterTablePartnerComponent from '../../components/FilterTablePartnerComponent.vue';
  export default {
    name: 'PartnerPage',
    components: {
      FilterTablePartnerComponent,
    },
    setup() {
      const value = ref(0);
      const input = ref('');
      const tableData = ref([]);
  
      const loadHeadquarters = async () => {
        try {
          const response = await partnersAPI.getEmployeesToHeadquarter();
  
          response.data.forEach((headquarter) => {
            let tableRow = {
              id: headquarter.id,
              address: headquarter.address,
              street_number: headquarter.street_number,
              cap: headquarter.cap ,
              city: headquarter.city,
              province: headquarter.province,
              employees: headquarter.employees
            };
            tableData.value.push(tableRow);
          });
        } catch (error) {
          console.log(error);
        }
      };
  
      loadHeadquarters();
  
      let addressFilter = ref('');
      let streetNumberFilter = ref('');
      let cityFilter = ref('');
      let capFilter = ref('');
      let provinceFilter = ref('');
  
      const filteredTable = computed(function () {
        return tableData.value.filter(
          (row) =>
            row.address
              .toLowerCase()
              .includes(addressFilter.value.toLowerCase()) &&
            row.street_number.toLowerCase().includes(streetNumberFilter.value.toLowerCase()) &&
            row.cap.toLowerCase().includes(cityFilter.value.toLowerCase()) &&
            row.city.toLowerCase().includes(capFilter.value.toLowerCase()) &&
            row.province.toLowerCase().includes(provinceFilter.value.toLowerCase()) 
           
        );
      });
  
      const resetFilter = async () => {
        window.location.reload();
      };
  
      return {
        input,
        value,
        tableData,
        filteredTable,
        resetFilter,
addressFilter,
streetNumberFilter,
cityFilter,
capFilter,
provinceFilter,
      };
    },
  };
  </script>
  
  <style>
  .el-table {
    color: black;
  }
  
  .el-table thead {
    color: rgb(255, 255, 255);
  }
  
  .el-table td {
    color: black;
  }
  
  .el-table line {
    color: black;
  }
  </style>
  