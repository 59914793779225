<template>
  <h2>Booking svolti</h2>
  <!-- <el-input v-model="input" placeholder="Inserisci l'id">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input> -->
  <el-table
    :data="bookingTable"
    height="500"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <!-- <el-table-column prop="id" label="ID">
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column> -->
    <el-table-column prop="driver" label="Driver" />
    <el-table-column prop="dataInizio" label="data inizio" />
    <el-table-column prop="dataFine" label="data inizio" />
    <el-table-column prop="servizio" label="servizio"  />
    <el-table-column prop="type" label="tipo" />
    <el-table-column prop="min" label="ore"  />
    <!-- <el-table-column prop="min" label="importo">
      <template #default="scope">
        <el-input v-model="scope.row.min" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
import { ref, computed } from 'vue';
import contabilitaAPI from '../../../services/contabilitaAPI';
import { useRoute } from 'vue-router';
const dayjs = require('dayjs');
export default {
  setup() {
    const route = useRoute();
    const input = ref('');
    const tableData = ref([]);

    const loadBooking = async () => {
      try {
        const response = await contabilitaAPI.getDetailCod6(route.params.id);

        response.data.forEach((planner) => {
          if (planner.pickup_booking != null) {
            let tableRow = {
              id: planner.pickup_booking.booking.id,
              driver:planner.jolly.name + " "+ planner.jolly.surname ,
              servizio: typeBooking(
                planner.pickup_booking.booking.type_prenotazione
              ),

              dataInizio: dayjs(planner.date_start).format('DD/MM/YYYY HH:mm'),
              dataFine: dayjs(planner.date_end).format('DD/MM/YYYY HH:mm'),
              type: planner.type,
              min: formatDuration(
                dayjs(planner.date_end).diff(
                  dayjs(planner.date_start),
                  'minute'
                )
              ),
              
            };
            tableData.value.push(tableRow);
          }

          if (planner.escort_booking != null) {
            let tableRow = {
              id: planner.escort_booking.booking.id,
              driver:planner.jolly.name + " "+ planner.jolly.surname ,
              servizio: typeBooking(
                planner.escort_booking.booking.type_prenotazione
              ),
              dataInizio: dayjs(planner.date_start).format('DD/MM/YYYY HH:mm'),
              dataFine: dayjs(planner.date_end).format('DD/MM/YYYY HH:mm'),
              type: planner.type,
              min: dayjs(planner.date_end).diff(
                dayjs(planner.date_start),
                'hour'
              ),
            };
            tableData.value.push(tableRow);
          }

          if (planner.delivery_booking != null) {
            let tableRow = {
              id: planner.delivery_booking.booking.id,
              driver:planner.jolly.name + " "+ planner.jolly.surname ,
              servizio: typeBooking(
                planner.delivery_booking.booking.type_prenotazione
              ),
              dataInizio: dayjs(planner.date_start).format('DD/MM/YYYY HH:mm'),
              dataFine: dayjs(planner.date_end).format('DD/MM/YYYY HH:mm'),
              type: planner.type,
              min: dayjs(planner.date_end).diff(
                dayjs(planner.date_start),
                'hour'
              ),
            };
            tableData.value.push(tableRow);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    function formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
    }

    const typeBooking = (type) => {
      switch (type) {
        case 'pickup':
          return 'Pickup & Delivery';
        case 'pickup_loaner':
          return 'Auto di cortesia';
        case 'escort':
          return 'Accompagna un cliente';
        case 'delivery':
          return "Consegna un'auto";
        case 'escort_loaner':
          return 'Accompagna un cliente con auto partner';
      }
    };

    loadBooking();

    const bookingTable = computed(function () {
      return tableData.value.filter((row) =>
        row.id.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      loadBooking,
      bookingTable,
      input,
    };
  },
};
</script>

<style></style>
