<template>
    <!-- <el-form ref="tariffeForm" :model="agency" :rules="formRuleAgency">  -->
  
    <div class="point-container">
      <InputComponent
        :value="agency.company_name"
        prop="company_name"
        label="Ragione sociale"
         @input="(newValue) => (agency.company_name = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <InputComponent
      :value="agency.name"
      label="Nome"
      prop="name"
       @input="(newValue) => (agency.name = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
        />
        <InputComponent
        :value="agency.surname"
        label="Cognome"
        prop="surname"
         @input="(newValue) => (agency.surname = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <InputComponent
      :value="agency.mail"
      label="mail"
      prop="mail"
       @input="(newValue) => (agency.mail = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="agency.telephone_number"
      label="telefono"
      prop="telephone_number"
       @input="(newValue) => (agency.telephone_number = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="agency.document"
      label="documento"
      prop="document"
       @input="(newValue) => (agency.document = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="agency.priority"
      label="priorità"
      prop="priority"
      @input="(newValue) => (agency.priority = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
     
    </div>
      <!-- </el-form> -->
    </template>
    
    
    <script>
    import InputComponent from './InputComponent.vue';
    import {   reactive } from 'vue';
    //import FormPointComponent from './FormPointComponent.vue';
    export default {
      components: {
        InputComponent,
        //FormPointComponent,
        
        //DescriptionComponent,
      },
      props: {
        value: {
          type: Object,
          required: true,
        },
        form: {
          type: String,
          required: true,
        },
    
        type: {
          type: Number,
          required: false,
          default: 0,
        },
        style: {
          type: String,
          required: false,
          default: 'margin-top: 10%',
        },
        label: {
          type: String,
          required: false,
        },
        loading: {
          type: String,
          required: false,
        },
    
        handleInputBlur: {
          type: Function,
        },
    
        showGeocoding:{
          type: Boolean,
          required: false,
          default:true
        },
        disabled:{
          default:false
        }
      },
      setup() {
        let formRuleAgency = reactive({
        company_name: [
          { required: true, message: 'Inserire ragione sociale', trigger: 'blur' },
        ],
          name:  [
          { required: true, message: 'Inserire nome', trigger: 'blur' },
        ],
        surname:  [
          { required: true, message: 'Inserire cognome', trigger: 'blur' },
        ],
          mail:  [
          { required: true, message: 'Inserire mail', trigger: 'blur' },
        ],
          telephone_number:  [
          { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
        ],
          document:  [
          { required: true, message: 'Inserire documento', trigger: 'blur' },
        ]
      
    });
         return {
          formRuleAgency,
        }; 
      },
      data() {
        return {
          agency: this.value,
        };
      },
      watch: {
        value(newValue) {
          this.agency = newValue;
        },
      },
      getTitle(key) {
        return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
      },
      handleInput(newValue, key) {
        this.$set(this.agency, key, newValue);
      },
    };
    </script>
    
    <style scoped>
    .point-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      justify-content: space-between;
      
    }
    
    .point-container > div {
      flex: 10;
      min-width: 20%;
      justify-content: center;
    }
  
    </style>
    