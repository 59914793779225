<template>
  <h1>COD7</h1>

  <h1>fatture da inserire</h1>
  <el-input v-model="input" placeholder="Inserisci il cognome del cliente">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table
    :data="fattureTable"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" sortable />
    <el-table-column prop="auto" label="Auto" sortable />
    <el-table-column prop="marca" label="Marca" sortable />
    <el-table-column prop="modello" label="Modello" sortable />

    <el-table-column prop="num_valet" label="Valet" />

    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column prop="tot_fattura" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="tot_liquidare" label="Da liquidare">
      <template #default="scope">
        <el-input v-model="scope.row.tot_liquidare"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column label="Select" width="85">
      <template #default="scope">
        <el-checkbox
          v-model="scope.row.selected"
          @change="handleRowSelect(scope.row)"
        ></el-checkbox>
      </template>
    </el-table-column>
  </el-table>

  <div class="point-container">
    <div>
      <el-button @click="downloadPDF" type="primary"> Scarica PDF </el-button>
    </div>
    <div class="input-with-label">
      <label for="rif-fattura">Riferimento Fattura</label>
      <el-input id="rif-fattura" v-model="rif_fattura"></el-input>
    </div>
    <div>
      <router-link class="routerLink" to="/contabilita">
        <el-button @click="update()"> Conferma </el-button>
      </router-link>
    </div>
  </div>
  <br />
  <br />
  <br />

  <h1>fatture inserite</h1>
  <FilterTableCod3Component
    @nomeChanged="(newValue) => (nomeFilter = newValue)"
    @cognomeChanged="(newValue) => (cognomeFilter = newValue)"
    @targaChanged="(newValue) => (targaFilter = newValue)"
    @dataChanged="(newValue) => (dataFilter = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilter"
  >
  </FilterTableCod3Component>
  <el-table
    :data="fatturaTableData"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" sortable />
    <el-table-column prop="auto" label="Auto" sortable />
    <el-table-column prop="marca" label="Marca" sortable />
    <el-table-column prop="modello" label="Modello" sortable />

    <el-table-column prop="num_valet" label="Valet" />

    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column prop="tot_fattura" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="tot_liquidare" label="Liquidato">
      <template #default="scope">
        <el-input v-model="scope.row.tot_liquidare"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
  </el-table>

  <div style="text-align: center">
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChangePage"
      :page-size="pageSize"
      :total="fattureTableLiquidati.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import contabilitaAPI from '../../../services/contabilitaAPI';
import customerAPI from '../../../services/customerAPI';
import { useRoute } from 'vue-router';

import FilterTableCod3Component from '../../../components/FilterTableCod3Component.vue';

import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import 'jspdf-autotable';

const dayjs = require('dayjs');
export default {
  data() {
    return {
      pageFatture: 1,
      pageSize: 7,
    };
  },
  components: {
    FilterTableCod3Component,
  },

  setup() {
    const route = useRoute();
    const input = ref('');
    const tableData = ref([]);
    const tableDataLiquidati = ref([]);
    const selectedRow = ref([]);
    const rif_fattura = ref(0);

    const loadCustomer = async (id) => {
      try {
        let customer = await customerAPI.getCustomerByCar(id);
        return customer.data[0].customer;
      } catch (e) {
        console.log(e);
      }
    };

    const loadTariffeBooking = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod4Minute(
          route.params.id
        );
        response.data.forEach(async (resp) => {
          let customer = await loadCustomer(resp.minute.car.id);
          let tableRow = {
            customer_id: customer.id,
            customer_name: customer.name,
            customer_surname: customer.surname,

            auto: resp.minute.car ? resp.minute.car.targa : '',
            marca: resp.minute.car ? resp.minute.car.marca : '',
            modello: resp.minute.car ? resp.minute.car.modello : '',

            num_valet: resp.minute.valet,

            id: resp.id,

            date_valet:
              resp.minute.data != null ? resp.minute.data.split('T')[0] : null,

            imponibile: resp.minute.imponibile,
            tot_fattura: resp.minute.tot_fattura,
            tot_liquidare: resp.importo,
          };
          tableData.value.push(tableRow);
        });
      } catch {
        console.log('error');
      }
    };

    loadTariffeBooking();

    const loadTariffeBookingLiquidati = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod4MinuteLiquidato(
          route.params.id
        );

        response.data.forEach(async (resp) => {
          let customer = await loadCustomer(resp.minute.car.id);

          let tableRow = {
            customer_id: customer.id,
            customer_name: customer.name,
            customer_surname: customer.surname,

            auto: resp.minute.car ? resp.minute.car.targa : '',
            marca: resp.minute.car ? resp.minute.car.marca : '',
            modello: resp.minute.car ? resp.minute.car.modello : '',
            num_valet: resp.minute.valet,
            id: resp.id,
            date_valet:
              resp.minute.data != null ? resp.minute.data.split('T')[0] : null,

            imponibile: resp.minute.imponibile,
            tot_fattura: resp.minute.tot_fattura,
            tot_liquidare: resp.importo,
          };
          tableDataLiquidati.value.push(tableRow);
        });

      } catch {
        console.log('error');
      }
    };

    loadTariffeBookingLiquidati();

    const handleRowSelect = (row) => {
      if (row.selected) {
        selectedRow.value.push(row);
      }
    };

    const downloadPDF = () => {
      if (selectedRow.value.length === 0) {
        ElMessage.error('Non ci sono booking da scaricare!');
        return;
      }
      generatePDF(selectedRow.value);
    };

    const generatePDF = (selectedRows) => {
      const pdf = new jsPDF();
      const header = [
        'Cliente',
        'Auto',
        'Data',
        'Imponibile',
        'Totale',
        'Da liquidare',
      ];
      const data = selectedRows.map((row) => [
        row.customer_name + ' ' + row.customer_surname,
        row.auto,
        row.date_valet,
        row.imponibile,
        row.tot_fattura,
        row.tot_liquidare,
      ]);
      const totalImponibile = selectedRows.reduce(
        (acc, row) => acc + row.imponibile,
        0
      );
      const totalFattura = selectedRows.reduce(
        (acc, row) => acc + row.tot_fattura,
        0
      );
      const totalLiquidare = selectedRows.reduce(
        (acc, row) => acc + row.tot_liquidare,
        0
      );
      const footer = [
        [
          '',
          '',
          'Totale:',
          totalImponibile.toFixed(2),
          totalFattura.toFixed(2),
          totalLiquidare.toFixed(2),
        ],
      ];
      data.push(...footer);

      pdf.autoTable({
        head: [header],
        body: data,
      });
      const today = new Date();

      // Save the PDF or open it in a new tab
      pdf.save('fatture' + dayjs(today).format('DD-MM-YY') + '.pdf');
    };

    const update = () => {
      const promises = selectedRow.value.map((element) =>
        updRifFattura(element.id, rif_fattura.value)
      );

      Promise.all(promises)
        .then((responses) => {
          const allSuccessful = responses.every(
            (response) => response.status === 200
          );
          if (allSuccessful) {
            ElMessage({
              message: 'Fattura aggiornato con successo!',
              type: 'success',
            });
          } else {
            ElMessage({
              message: "Errore durante l'aggiornamento di alcune fatture!",
              type: 'error',
            });
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the updates
          console.error('Error during update:', error);
          ElMessage({
            message: "Errore durante l'aggiornamento!",
            type: 'error',
          });
        });
    };

    const updRifFattura = async (id, rif) => {
      let response = {};
      try {
        response = await contabilitaAPI.updateFatturaProvvigione(id, {
          rif_fattura: rif,
          liquidato: true,
        });
        return response;
      } catch {
        console.log('error');
      }
    };

    let nomeFilter = ref('');
    let cognomeFilter = ref('');
    let targaFilter = ref('');
    let dataFilter = ref('');

    const fattureTable = computed(function () {
      return tableData.value.filter((row) =>
        row.customer_surname.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const fattureTableLiquidati = computed(function () {
      return tableDataLiquidati.value.filter(
        (row) =>
          row.customer_surname
            .toLowerCase()
            .includes(cognomeFilter.value.toLowerCase()) &&
          row.customer_name
            .toLowerCase()
            .includes(nomeFilter.value.toLowerCase()) &&
          row.auto.toLowerCase().includes(targaFilter.value.toLowerCase()) &&
          dayjs(new Date(row.date_booking))
            .format('YYYY-MM-DD')
            .includes(
              dataFilter.value == ''
                ? ''
                : dayjs(new Date(dataFilter.value)).format('YYYY-MM-DD')
            )
      );
    });
    const resetFilter = async () => {
      window.location.reload();
    };

    return {
      loadTariffeBooking,
      fattureTable,
      input,
      resetFilter,
      fattureTableLiquidati,
      rif_fattura,
      update,
      downloadPDF,
      nomeFilter,
      cognomeFilter,
      targaFilter,
      dataFilter,
      handleRowSelect,
    };
  },
  computed: {
    fatturaTableData() {
      if (
        !this.fattureTableLiquidati ||
        this.fattureTableLiquidati.length === 0
      )
        return [];
      return this.fattureTableLiquidati.slice(
        this.pageSize * this.pageFatture - this.pageSize,
        this.pageSize * this.pageFatture
      );
    },
  },
  methods: {
    handleCurrentChangePage(val) {
      this.pageFatture = val;
    },
  },
};
</script>

<style>
.point-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.point-container > div {
  flex: 1;
  min-width: 30%;
}
.input-with-label {
  display: flex;
  align-items: center;
}

.input-with-label label {
  font-weight: bold;
}
</style>
