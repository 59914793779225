<template>
    <!-- <el-form ref="tariffeForm" :model="partner" :rules="formRulePartner">  -->
  
    <div class="point-container">
      <InputComponent
        :value="driver.name"
        prop="name"
        label="Nome"
         @input="(newValue) => (driver.name = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <InputComponent
      :value="driver.surname"
      label="Cognome"
      prop="surname"
       @input="(newValue) => (driver.surname = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
        />
    <InputComponent
        :value="driver.mail"
        label="Mail"
        prop="mail"
         @input="(newValue) => (driver.mail = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <el-form-item label="Data di nascita" prop="date_birth">
        <el-date-picker
          v-model="driver.date_birth"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
          format="DD/MM/YYYY"
        />
      </el-form-item>
      <!--<InputComponent
      :value="driver.date_birth"
      label="Data di nascita"
      prop="date_birth"
       @input="(newValue) => (driver.date_birth = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />-->
      <InputComponent
      :value="driver.telephone_number"
      label="Numero"
      prop="telephone_number"
       @input="(newValue) => (driver.telephone_number = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="driver.emergency_telephone_number"
      label="Numero emergenze"
      prop="emergency_telephone_number"
       @input="(newValue) => (driver.emergency_telephone_number = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="driver.iban"
      label="Iban"
      prop="iban"
       @input="(newValue) => (driver.iban = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="driver.CF"
      label="CF"
      prop="CF"
       @input="(newValue) => (driver.CF = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      v-if="isDetail"
      :value="driver.agenzia"
      label="Agenzia"
      prop="agenzia"
      @input="(newValue) => (driver.agenzia = newValue.target.value)"
      :showEeuro="false"
      :disabled="true"
      />
      
    </div>
  
    </template>
    
    
    <script>
    import InputComponent from './InputComponent.vue';
    import { reactive } from 'vue';
    //import FormPointComponent from './FormPointComponent.vue';
    export default {
      components: {
        InputComponent
      },
      props: {
        value: {
          type: Object,
          required: true,
        },
        form: {
          type: String,
          required: true,
        },
    
        type: {
          type: Number,
          required: false,
          default: 0,
        },
        style: {
          type: String,
          required: false,
          default: 'margin-top: 10%',
        },
        label: {
          type: String,
          required: false,
        },
        loading: {
          type: String,
          required: false,
        },
        handleInputBlur: {
          type: Function,
        },
        showGeocoding:{
          type: Boolean,
          required: false,
          default:true
        },
        disabled:{
          default:false
        },
        isDetail:{
          type: Boolean,
          required: true
        }
        
      },
      setup() {
        let formRuleDriver = reactive({
        company_name: [
          { required: true, message: 'Inserire ragione sociale', trigger: 'blur' },
        ],
          piva:  [
          { required: true, message: 'Inserire piva', trigger: 'blur' },
        ],
          mail:  [
          { required: true, message: 'Inserire mail', trigger: 'blur' },
        ],
          phone_number:  [
          { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
        ],
          CF:  [
          { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
          { min: 11, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
        ],
      
    });
         return {
          formRuleDriver,
        }; 
      },
      data() {
        return {
          driver: this.value,
        };
      },
      watch: {
        value(newValue) {
          this.driver = newValue;
        },
      },
      getTitle(key) {
        return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
      },
      handleInput(newValue, key) {
        this.$set(this.driver, key, newValue);
      },
    };
    </script>
    
    <style scoped>
    .point-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      justify-content: space-between;
      
    }
    
    .point-container > div {
      flex: 10;
      min-width: 20%;
      justify-content: center;
    }
  
    </style>
    