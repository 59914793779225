<template>
  <h1>COD3</h1>

  <h1>fatture da inserire</h1>
  <el-input v-model="input" placeholder="Inserisci il cognome del cliente">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table
    :data="fattureTable"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" sortable />
    <el-table-column prop="auto" label="Auto" sortable />
    <el-table-column prop="type" label="Tipo" sortable />
    <el-table-column prop="date_booking" label="Data booking" sortable />

    <el-table-column prop="fascia" label="Fascia km"> </el-table-column>

    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column prop="tot_fattura" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column label="Select" width="85">
      <template #default="scope">
        <el-checkbox v-model="scope.row.selected" @change="handleRowSelect(scope.row)"></el-checkbox>
      </template>
    </el-table-column>

  </el-table>

  <div class="point-container">
    <div>
      <el-button @click="downloadPDF" type="primary"> Scarica PDF </el-button>
    </div>

    <div class="input-with-label">
      <label for="rif-fattura">Riferimento Fattura</label>
      <el-input id="rif-fattura" v-model="rif_fattura"></el-input>
    </div>
    <div>
      <router-link class="routerLink" to="/contabilita">
        <el-button @click="update()"> Conferma </el-button>
      </router-link>
    </div>
  </div>
<br/>
<br/>
<br/>

<h1>fatture inserite</h1>
  <FilterTableCod3Component
    @nomeChanged="(newValue) => (nomeFilter = newValue)"
    @cognomeChanged="(newValue) => (cognomeFilter = newValue)"
    @targaChanged="(newValue) => (targaFilter = newValue)"
    @dataChanged="(newValue) => (dataFilter = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilter"
  >
  </FilterTableCod3Component>
  <el-table
    :data="fatturaTableData"
    style="width: 100%"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
  >
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" sortable />
    <el-table-column prop="auto" label="Auto" sortable />
    <el-table-column prop="type" label="Tipo" sortable />
    <el-table-column prop="date_booking" label="Data booking" sortable />

    <el-table-column prop="fascia" label="Fascia km"> </el-table-column>

    <el-table-column prop="imponibile" label="Imponibile">
      <template #default="scope">
        <el-input v-model="scope.row.imponibile"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column prop="tot_fattura" label="Tot fattura">
      <template #default="scope">
        <el-input v-model="scope.row.tot_fattura"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>

    <el-table-column prop="rif_fattura" label="Rif fattura" sortable />
  </el-table>

  <div style="text-align: center">
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChangePage"
      :page-size="pageSize"
      :total="fattureTableLiquidati.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import contabilitaAPI from '../../../services/contabilitaAPI';
import bookingAPI from '../../../services/bookingAPI';
import { useRoute } from 'vue-router';

import FilterTableCod3Component from '../../../components/FilterTableCod3Component.vue';

import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import 'jspdf-autotable';

const dayjs = require('dayjs');
export default {
  data() {
    return {
      pageFatture: 1,
      pageSize: 7,
    };
  },
  components: {
    FilterTableCod3Component,
  },

  setup() {
    const route = useRoute();
    const input = ref('');
    const tableData = ref([]);
    const tableDataLiquidati = ref([]);
    const selectedRow = ref([]);
    const rif_fattura = ref(0);

    const typeBooking = (type) => {
      switch (type) {
        case 'pickup':
          return 'Pickup & Delivery';
        case 'pickup_loaner':
          return 'Auto di cortesia';
        case 'escort':
          return 'Accompagna un cliente';
        case 'escort_loaner':
          return 'Accompagna un cliente con auto partner';
        case 'delivery':
          return "Consegna un'auto";
      }
    };

    const fasciaKm = (booking) => {
      try {
        let fascia = {
          fascia_gone: 0,
          fascia_return: 0,
        };

        let fasciaKm = '';

        if (booking.pickup_bookings && booking.pickup_bookings.length > 0) {
          for (let i = 0; i < booking.pickup_bookings.length; i++) {
            const currentBooking = booking.pickup_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              fascia.fascia_gone = currentBooking.simba_km_range;
            } else {
              fascia.fascia_return = currentBooking.simba_km_range;
            }
          }
        }
        if (booking.escort_bookings && booking.escort_bookings.length > 0) {
          for (let i = 0; i < booking.escort_bookings.length; i++) {
            const currentBooking = booking.escort_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              fascia.fascia_gone = currentBooking.simba_km_range;
            } else {
              fascia.fascia_return = currentBooking.simba_km_range;
            }
          }
        }
        if (booking.delivery_bookings && booking.delivery_bookings.length > 0) {
          for (let i = 0; i < booking.delivery_bookings.length; i++) {
            const currentBooking = booking.delivery_bookings[i];

            if (currentBooking.type_booking === 'gone') {
              fascia.fascia_gone = currentBooking.simba_km_range;
            } else {
              fascia.fascia_return = currentBooking.simba_km_range;
            }
          }
        }

        if (fascia.fascia_gone != 0 && fascia.fascia_return != 0) {
          fasciaKm = `A:${fascia.fascia_gone}km R:${fascia.fascia_return}km`;
        } else {
          fasciaKm =
            fascia.fascia_gone != 0
              ? `A:${fascia.fascia_gone}km`
              : `R:${fascia.fascia_return}km`;
        }

        return fasciaKm;
      } catch (e) {
        console.log(e);
      }
    };

    const loadTariffeBooking = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod3(route.params.id);
        const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed (January is 0)
    const currentYear = currentDate.getFullYear();

        response.data.forEach((booking) => {
          let tableRow = {
            customer_id: booking.customer.id,
            customer_name: booking.customer.name,
            customer_surname: booking.customer.surname,
            auto: booking.car ? booking.car.targa : '',
            id: booking.id,
            date_booking:
              booking.data != null ? booking.data.split('T')[0] : null,
            imponibile: booking.imponibile,
            tot_fattura: booking.tot_fattura,
            fascia: fasciaKm(booking),
            type: typeBooking(booking.type_prenotazione),
            selected:false
          };
          tableData.value.push(tableRow);

          if (tableRow.date_booking) {
        const bookingDate = new Date(tableRow.date_booking);
        const bookingMonth = bookingDate.getMonth();
        const bookingYear = bookingDate.getFullYear();

        // Check if the booking's month is the previous month
        const isPreviousMonth = (currentMonth === 0 && bookingMonth === 11 && bookingYear === currentYear - 1) || // Handle January case (previous month is December of the previous year)
                               (bookingMonth === currentMonth - 1 && bookingYear === currentYear);

        if (isPreviousMonth) {
          tableRow.selected=true;
          selectedRow.value.push(tableRow);
        }
      }
        });
      } catch {
        console.log('error');
      }
    };

    loadTariffeBooking();

    const loadTariffeBookingLiquidati = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod3Liquidato(
          route.params.id
        );

        response.data.forEach((booking) => {
          let tableRow = {
            customer_id: booking.customer.id,
            customer_name: booking.customer.name,
            customer_surname: booking.customer.surname,
            auto: booking.car ? booking.car.targa : '',
            id: booking.id,
            date_booking:
              booking.data != null ? booking.data.split('T')[0] : null,
            imponibile: booking.imponibile,
            tot_fattura: booking.tot_fattura,
            rif_fattura: booking.rif_fattura,
            fascia: fasciaKm(booking),
            type: typeBooking(booking.type_prenotazione),
          };
          tableDataLiquidati.value.push(tableRow);
        });
      } catch {
        console.log('error');
      }
    };

    loadTariffeBookingLiquidati();



    const handleRowSelect = (row) =>{
        if(row.selected){
          selectedRow.value.push(row);
        }
      }


    const downloadPDF = () => {
      //const selectedRows = fattureTableFalse.filter((row) => row.selected);
      if (selectedRow.value.length === 0) {
          ElMessage.error("Seleziona almeno una riga!");
          return;
        }

      /* if (tableData.value.length === 0) {
        ElMessage.error('Non ci sono booking da scaricare!');
        return;
      } */
      // Call a function to generate a PDF with the selected rows
      generatePDF(selectedRow.value);
    };

    const generatePDF = (selectedRows) => {
      const pdf = new jsPDF();
      const header = ['Cliente', 'Data', 'Fascia Km', 'Imponibile', 'Totale'];
      const data = selectedRows.map((row) => [
        row.customer_name + ' ' + row.customer_surname,
        row.date_booking,
        row.fascia,
        row.imponibile,
        row.tot_fattura,
      ]);
      const totalFattura = selectedRows.reduce(
        (acc, row) => acc + row.tot_fattura,
        0
      );
      const totalImponibile = selectedRows.reduce(
          (acc, row) => acc + row.imponibile,
          0
        );
      const footer = [['', '','Totale:',totalImponibile.toFixed(2), totalFattura.toFixed(2)]];
      data.push(...footer);

      pdf.autoTable({
        head: [header],
        body: data,
      });
      const today = new Date();

      // Save the PDF or open it in a new tab
      pdf.save('fatture' + dayjs(today).format('DD-MM-YY') + '.pdf');
    };
    const update = () => {
      const promises = selectedRow.value.map((element) =>
        updRifFattura(element.id, rif_fattura.value)
      );

      Promise.all(promises)
        .then((responses) => {
          const allSuccessful = responses.every(
            (response) => response.status === 200
          );
          if (allSuccessful) {
            ElMessage({
              message: 'Fattura aggiornato con successo!',
              type: 'success',
            });

            //route.push('/contabilità');
          } else {
            ElMessage({
              message: "Errore durante l'aggiornamento di alcune fatture!",
              type: 'error',
            });
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the updates
          console.error('Error during update:', error);
          ElMessage({
            message: "Errore durante l'aggiornamento!",
            type: 'error',
          });
        });
    };

    const updRifFattura = async (id, rif) => {
      let response = {};
      try {
        //let fattura = await contabilitaAPI.getFattura(id);
        response = await bookingAPI.updateDateBooking(id, {
          rif_fattura: rif,
          liquidato: true,
        });
        return response;
      } catch {
        console.log('error');
      }
    };

    let nomeFilter = ref('');
    let cognomeFilter = ref('');
    let targaFilter = ref('');
    let dataFilter = ref('');

    const fattureTable = computed(function () {
      return tableData.value.filter((row) =>
        row.customer_surname.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const fattureTableLiquidati = computed(function () {
      return tableDataLiquidati.value.filter(
        (row) =>
          row.customer_surname
            .toLowerCase()
            .includes(cognomeFilter.value.toLowerCase()) &&
          row.customer_name
            .toLowerCase()
            .includes(nomeFilter.value.toLowerCase()) &&
          row.auto.toLowerCase().includes(targaFilter.value.toLowerCase()) &&
          dayjs(new Date(row.date_booking))
            .format('YYYY-MM-DD')
            .includes(
              dataFilter.value == ''
                ? ''
                : dayjs(new Date(dataFilter.value)).format('YYYY-MM-DD')
            )
      );
    });
    const resetFilter = async () => {
      window.location.reload();
    };

    return {
      loadTariffeBooking,
      fattureTable,
      input,
      resetFilter,
      fattureTableLiquidati,
      rif_fattura,
      update,
      downloadPDF,
nomeFilter,
cognomeFilter,
targaFilter,
dataFilter,
handleRowSelect
    };
  },
  computed: {
    fatturaTableData() {
      if (
        !this.fattureTableLiquidati ||
        this.fattureTableLiquidati.length === 0
      )
        return [];
      return this.fattureTableLiquidati.slice(
        this.pageSize * this.pageFatture - this.pageSize,
        this.pageSize * this.pageFatture
      );
    },
  },
  methods: {
    handleCurrentChangePage(val) {
      this.pageFatture = val;
    },
  },
};
</script>

<style>
.point-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.point-container > div {
  flex: 1;
  min-width: 30%;
}
.input-with-label {
  display: flex;
  align-items: center;
}

.input-with-label label {
  font-weight: bold;
}
</style>
