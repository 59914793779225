<template>
  <div class="point-container">
    <InputComponent
      :value="point.address"
      @input="(newValue) => (point.address = newValue.target.value)"
      title="Punto di prelievo"
      label="Indirizzo"
      placeholder="Indirizzo"
      :showEeuro="false"
      @focus="handleInputFocusGone"
      @blur="handleInputBlur"
      :readonly="readonly"
    ></InputComponent>

    <InputComponent
      :value="point.street_number"
      @input="(newValue) => (point.street_number = newValue.target.value)"
      label="N. civico"
      placeholder="N. civico"
      :showEeuro="false"
      @focus="handleInputFocusGone"
      @blur="handleInputBlur"
      :readonly="readonly"
    ></InputComponent>

    <InputComponent
      :value="point.cap"
      @input="(newValue) => (point.cap = newValue.target.value)"
      label="CAP"
      placeholder="CAP"
      :showEeuro="false"
      @focus="handleInputFocusGone"
      @blur="handleInputBlur"
      :readonly="readonly"
    ></InputComponent>

    <InputComponent
      :value="point.city"
      @input="(newValue) => (point.city = newValue.target.value)"
      :span="4"
      label="Città"
      placeholder="Città"
      :showEeuro="false"
      @focus="handleInputFocusGone"
      @blur="handleInputBlur"
      :readonly="readonly"
    ></InputComponent>

    <InputComponent
      :value="point.province"
      @input="(newValue) => (point.province = newValue.target.value)"
      label="Provincia"
      placeholder="Provincia"
      :showEeuro="false"
      @focus="handleInputFocusGone"
      @blur="handleInputBlur"
      :readonly="readonly"
    ></InputComponent>
  </div>

  <div class="point-container" style="margin-top: 3px">
    <InputComponent
      v-if="showAbbonamento"
      :value="point.abbonamento"
      @input="(newValue) => (point.abbonamento = newValue.target.value)"
      title="Abbonamento"
      label="Abbonamento"
      placeholder="Abbonamento"
      :showEeuro="true"
      :readonly="false"
    ></InputComponent>

    <div v-if="showArea">
    <div v-if="selectArea">
      <SelectComponent
        @selected="handleSelectArea"
        :options="optionsArea"
        title="Zona"
        :addButtom="false"
        :isDisabled="false"
        :showEeuro="false"
      ></SelectComponent>
    </div>

    <div v-else-if="!selectArea">
      <InputComponent
       :value="area ? area.area : ''"
        title="Zona"
        label="Zona"
        placeholder="Zona"
        :showEeuro="false"
        :readonly="true"
      ></InputComponent>
    </div>
  </div>

    <ButtonComponent
      v-if="deletePoint"
      :ifCondition="ifCondition"
      :isRouter="false"
      @buttonClick="handleButtonClickDelete($event, counterEmployee)"
      type="danger"
      icon="Delete"
    ></ButtonComponent>

    <el-form-item v-if="modifyButton">
      <ButtonComponent
        label="Calcola"
        :isRouter="true"
        @buttonClick="handleButtonClick(point)"
      ></ButtonComponent>
    </el-form-item>
  </div>
  <DescriptionComponent
    v-if="showDescription"
    :label="label"
    :loading="loadingPoint"
  ></DescriptionComponent>

  <!-- <DescriptionComponent
    :label="pickup_gone.point.label"
    :loading="loadingPoint"
  ></DescriptionComponent> -->
</template>

<script>
import InputComponent from './InputComponent.vue';
import ButtonComponent from './ButtonComponent.vue';
import DescriptionComponent from './DescriptionComponent.vue';
import SelectComponent from './SelectComponent.vue';

export default {
  components: {
    InputComponent,
    ButtonComponent,
    DescriptionComponent,
    SelectComponent,
  },
  props: {
    inputPoint: {
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    modifyButton: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      default: false,
    },
    handleInputBlur: {
      type: Function,
    },
    loadingPoint: {
      type: String,
      required: false,
    },
    deletePoint: {
      type: Boolean,
    },
    ifCondition: {
      type: Boolean,
    },
    showAbbonamento: {
      type: Boolean,
      default: false,
    },
    counter: {},
    readonly: {
      required: false,
      default: false,
    },
    optionsArea: {},
    selectArea: {
      type: Boolean,
      default: false,
    },

    area:{
    },
    showArea:{
      default:true
    }
  },
  data() {
    return {
      //singleCar: this.car,
      point: this.inputPoint,
    };
  },
  watch: {
    inputPoint(newValue) {
      this.point = newValue;
    },
  },

  methods: {
    handleButtonClick() {
      this.$emit('buttonClick');
    },
    handleInputFocus() {
      this.$emit('focus');
    },

    handleInput(newValue, key) {
      this.$set(this.point, key, newValue);
    },
    handleButtonClickDelete() {
      this.$emit('buttonClick', this.point, this.counter);
    },
    handleSelectArea(newValue) {
      this.$emit('selected', newValue, this.counter);
    },
  },
};
</script>

<style>
.point-container {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;
}

.point-container > div {
  flex: 7;
  min-width: 15%;
  justify-content: center;
}
</style>
