<template>
    <el-form ref="tariffeForm" :model="partner" > 

        <section>
          <h3>PICKUP E DELIVERY</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.pickup_5km"
              label="tariffa simba 5km"
              @input="(newValue) => (partner.pickup_5km = newValue.target.value)"
          
            />
            <InputComponent
              :value="partner.partner_pickup_5km"
              label="tariffa partner 5km"
              @input="(newValue) => (partner.partner_pickup_5km = newValue.target.value)"
            />
          </div>

          <div class="point-container">
            <InputComponent
              :value="partner.pickup_10km"
              label="tariffa simba 10km"
              @input="(newValue) => (partner.pickup_10km = newValue.target.value)"
          
            />
            <InputComponent
              :value="partner.partner_pickup_10km"
              label="tariffa partner 10km"
              @input="(newValue) => (partner.partner_pickup_10km = newValue.target.value)"
            />
          </div>
          <div class="point-container">
            <InputComponent
              :value="partner.pickup_15km"
              label="tariffa simba 15km"
              @input="(newValue) => (partner.pickup_15km = newValue.target.value)"
            
            />
            <InputComponent
              :value="partner.partner_pickup_15km"
              label="tariffa partner 15km"
              @input="(newValue) => (partner.partner_pickup_15km = newValue.target.value)"
            />
          </div>
          
          <div class="point-container">
              <InputComponent
                :value="partner.pickup_20km"
                label="tariffa simba 20km"
                @input="(newValue) => (partner.pickup_20km = newValue.target.value)"
              
              />
              <InputComponent
                :value="partner.partner_pickup_20km"
                label="tariffa partner 20km"
                @input="(newValue) => (partner.partner_pickup_20km = newValue.target.value)"
              />
            </div>

            <div class="point-container">
              <InputComponent
                :value="partner.pickup_25km"
                label="tariffa simba 25km"
                @input="(newValue) => (partner.pickup_25km = newValue.target.value)"
              
              />
              <InputComponent
                :value="partner.partner_pickup_25km"
                label="tariffa partner 25km"
                @input="(newValue) => (partner.partner_pickup_25km = newValue.target.value)"
              />
            </div>
  
            <div class="point-container">
              <InputComponent
                :value="partner.pickup_30km"
                label="tariffa simba 30km"
                @input="(newValue) => (partner.pickup_30km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_pickup_30km"
                label="tariffa partner 30km"
                @input="(newValue) => (partner.partner_pickup_30km = newValue.target.value)"
              />
            </div>

            <div class="point-container">
              <InputComponent
                :value="partner.pickup_over_30km"
                label="tariffa simba over 30km"
                @input="(newValue) => (partner.pickup_over_30km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_pickup_over_30km"
                label="tariffa partner over 30km"
                @input="(newValue) => (partner.partner_pickup_over_30km = newValue.target.value)"
              />
            </div>
  
        </section>
    
        <section>
          <h3>PICKUP E DELIVERY CON AUTO DI CORTESIA</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.pickup_loaner_5km"
              label="tariffa simba 5km"
              @input="(newValue) => (partner.pickup_loaner_5km = newValue.target.value)"
              
            />
            <InputComponent
              :value="partner.partner_pickup_loaner_5km"
              label="tariffa partner 5km"
              @input="(newValue) => (partner.partner_pickup_loaner_5km = newValue.target.value)"
            />
          </div>
  

          <div class="point-container">
              <InputComponent
                :value="partner.pickup_loaner_10km"
                label="tariffa simba 10km"
                @input="(newValue) => (partner.pickup_loaner_10km = newValue.target.value)"
                
              />
              <InputComponent
                :value="partner.partner_pickup_loaner_10km"
                label="tariffa partner 10km"
                @input="(newValue) => (partner.partner_pickup_loaner_10km = newValue.target.value)"
              />
            </div>
    
            <div class="point-container">
              <InputComponent
                :value="partner.pickup_loaner_15km"
                label="tariffa simba 15km"
                @input="(newValue) => (partner.pickup_loaner_15km = newValue.target.value)"
             
              />
              <InputComponent
                :value="partner.partner_pickup_loaner_15km"
                label="tariffa partner 15km"
                @input="(newValue) => (partner.partner_pickup_loaner_15km = newValue.target.value)"
              />
            </div>
            
            <div class="point-container">
                <InputComponent
                  :value="partner.pickup_loaner_20km"
                  label="tariffa simba 20km"
                  @input="(newValue) => (partner.pickup_loaner_20km = newValue.target.value)"
        
                />
                <InputComponent
                  :value="partner.partner_pickup_loaner_20km"
                  label="tariffa partner 20km"
                  @input="(newValue) => (partner.partner_pickup_loaner_20km = newValue.target.value)"
                />
              </div>
    
              <div class="point-container">
                <InputComponent
                  :value="partner.pickup_loaner_30km"
                  label="tariffa simba 30km"
                  @input="(newValue) => (partner.pickup_loaner_30km = newValue.target.value)"
                 
                />
                <InputComponent
                  :value="partner.partner_pickup_loaner_30km"
                  label="tariffa partner 30km"
                  @input="(newValue) => (partner.partner_pickup_loaner_30km = newValue.target.value)"
                />
              </div>

              <div class="point-container">
                <InputComponent
                  :value="partner.pickup_loaner_over_30km"
                  label="tariffa simba over 30km"
                  @input="(newValue) => (partner.pickup_loaner_over_30km = newValue.target.value)"
                 
                />
                <InputComponent
                  :value="partner.partner_pickup_loaner_over_30km"
                  label="tariffa partner over 30km"
                  @input="(newValue) => (partner.partner_pickup_loaner_over_30km = newValue.target.value)"
                />
              </div>
          
        </section>
        <!-- <section>
          <h3>ACCOMPAGNA UN CLIENTE</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.escort_10km"
              label="tariffa simba 10km"
              @input="(newValue) => (partner.escort_10km = newValue.target.value)"
           
            />
            <InputComponent
              :value="partner.partner_escort_10km"
              label="tariffa partner 10km"
              @input="(newValue) => (partner.partner_escort_10km = newValue.target.value)"
             
            />
          </div>
  
          <div class="point-container">
            <InputComponent
              :value="partner.escort_15km"
              label="tariffa simba 15km"
              @input="(newValue) => (partner.escort_15km = newValue.target.value)"
             
            />
            <InputComponent
              :value="partner.partner_escort_15km"
              label="tariffa partner 15km"
              @input="(newValue) => (partner.partner_escort_15km = newValue.target.value)"
            />
          </div>
          
          <div class="point-container">
              <InputComponent
                :value="partner.escort_20km"
                label="tariffa simba 20km"
                @input="(newValue) => (partner.escort_20km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_escort_20km"
                label="tariffa partner 20km"
                @input="(newValue) => (partner.partner_escort_20km = newValue.target.value)"
              />
            </div>
  
            <div class="point-container">
              <InputComponent
                :value="partner.escort_30km"
                label="tariffa simba 30km"
                @input="(newValue) => (partner.escort_30km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_escort_30km"
                label="tariffa partner 30km"
                @input="handleInput('partner_escort_30km', $event)"
              />
            </div>
  
        </section> -->
  
        <section>
          <h3>ACCOMPAGNA UN CLIENTE CON AUTO DEL PARTNER</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.escort_loaner_5km"
              label="tariffa simba 5km"
              @input="(newValue) => (partner.escort_loaner_5km = newValue.target.value)"
            
            />
            <InputComponent
              :value="partner.partner_escort_loaner_5km"
              label="tariffa partner 5km"
              @input="(newValue) => (partner.partner_escort_loaner_5km = newValue.target.value)"
            
            />
          </div>
  
          <div class="point-container">
              <InputComponent
                :value="partner.escort_loaner_10km"
                label="tariffa simba 10km"
                @input="(newValue) => (partner.escort_loaner_10km = newValue.target.value)"
              
              />
              <InputComponent
                :value="partner.partner_escort_loaner_10km"
                label="tariffa partner 10km"
                @input="(newValue) => (partner.partner_escort_loaner_10km = newValue.target.value)"
              
              />
            </div>
    
            <div class="point-container">
              <InputComponent
                :value="partner.escort_loaner_15km"
                label="tariffa simba 15km"
                @input="(newValue) => (partner.escort_loaner_15km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_escort_loaner_15km"
                label="tariffa partner 15km"
                @input="(newValue) => (partner.partner_escort_loaner_15km = newValue.target.value)"
               
              />
            </div>
            
            <div class="point-container">
                <InputComponent
                  :value="partner.escort_loaner_20km"
                  label="tariffa simba 20km"
                  @input="(newValue) => (partner.escort_loaner_20km = newValue.target.value)"
                 
                />
                <InputComponent
                  :value="partner.partner_escort_loaner_20km"
                  label="tariffa partner 20km"
                  @input="(newValue) => (partner.partner_escort_loaner_20km = newValue.target.value)"
                  
                />
              </div>
    
              <div class="point-container">
                <InputComponent
                  :value="partner.escort_loaner_30km"
                  label="tariffa simba 30km"
                  @input="(newValue) => (partner.escort_loaner_30km = newValue.target.value)"
                
                />
                <InputComponent
                  :value="partner.partner_escort_loaner_30km"
                  label="tariffa partner 30km"
                  @input="(newValue) => (partner.partner_escort_loaner_30km = newValue.target.value)"
  
                />
              </div>

              <div class="point-container">
                <InputComponent
                  :value="partner.escort_loaner_over_30km"
                  label="tariffa simba over 30km"
                  @input="(newValue) => (partner.escort_loaner_over_30km = newValue.target.value)"
                
                />
                <InputComponent
                  :value="partner.partner_escort_loaner_over_30km"
                  label="tariffa partner over 30km"
                  @input="(newValue) => (partner.partner_escort_loaner_over_30km = newValue.target.value)"
  
                />
              </div>
        </section>
  
        <section>
          <h3>CONSEGNA UN'AUTO</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.delivery_5km"
              label="tariffa simba 5km"
              @input="(newValue) => (partner.delivery_5km = newValue.target.value)"
             
            />
            <InputComponent
              :value="partner.partner_delivery_5km"
              label="tariffa partner 5km"
              @input="(newValue) => (partner.partner_delivery_5km = newValue.target.value)"

            />
          </div>

          <div class="point-container">
            <InputComponent
              :value="partner.delivery_10km"
              label="tariffa simba 10km"
              @input="(newValue) => (partner.delivery_10km = newValue.target.value)"
             
            />
            <InputComponent
              :value="partner.partner_delivery_10km"
              label="tariffa partner 10km"
              @input="(newValue) => (partner.partner_delivery_10km = newValue.target.value)"

            />
          </div>
  
          <div class="point-container">
            <InputComponent
              :value="partner.delivery_15km"
              label="tariffa simba 15km"
              @input="(newValue) => (partner.delivery_15km = newValue.target.value)"
             
            />
            <InputComponent
              :value="partner.partner_delivery_15km"
              label="tariffa partner 15km"
              @input="(newValue) => (partner.partner_delivery_15km = newValue.target.value)"
            />
          </div>
          
          <div class="point-container">
              <InputComponent
                :value="partner.delivery_20km"
                label="tariffa simba 20km"
                @input="(newValue) => (partner.delivery_20km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_delivery_20km"
                label="tariffa partner 20km"
                @input="(newValue) => (partner.partner_delivery_20km = newValue.target.value)"
              />
            </div>
  
            <div class="point-container">
              <InputComponent
                :value="partner.delivery_25km"
                label="tariffa simba 25km"
                @input="(newValue) => (partner.delivery_25km = newValue.target.value)"
               
              />
              <InputComponent
                :value="partner.partner_delivery_25km"
                label="tariffa partner 25km"
                @input="(newValue) => (partner.partner_delivery_25km = newValue.target.value)"
              />
            </div>
  
            <div class="point-container">
              <InputComponent
                :value="partner.delivery_30km"
                label="tariffa simba 30km"
                @input="(newValue) => (partner.delivery_30km = newValue.target.value)"
                
              />
              <InputComponent
                :value="partner.partner_delivery_30km"
                label="tariffa partner 30km"
                @input="(newValue) => (partner.partner_delivery_30km = newValue.target.value)"
              />
            </div>
            <div class="point-container">
              <InputComponent
                :value="partner.delivery_over_30km"
                label="tariffa simba over 30km"
                @input="(newValue) => (partner.delivery_over_30km = newValue.target.value)"
                
              />
            <InputComponent
            :value="partner.partner_delivery_over_30km"
            label="tariffa partner over over 30km"
            @input="(newValue) => (partner.partner_delivery_over_30km = newValue.target.value)"
          />
        </div>

  
        </section>


        <section>
          <h3>VALET</h3>
          <div class="point-container">
            <InputComponent
              :value="partner.one_valet"
              label="1 valet"
              @input="(newValue) => (partner.one_valet = newValue.target.value)"
             
            />
            <InputComponent
              :value="partner.two_valet"
              label="2 valet"
              @input="(newValue) => (partner.two_valet = newValue.target.value)"

            />
         
            <InputComponent
              :value="partner.three_valet"
              label="3 valet"
              @input="(newValue) => (partner.three_valet = newValue.target.value)"
             
            />
          </div>
          
          
        </section>


      </el-form>
    </template>
    
    
    <script>
    import InputComponent from './InputComponent.vue';
    
    export default {
      components: {
        InputComponent,
      },
      props: {
        value: {
          type: Object,
          required: true,
        },
        form: {
          type: String,
          required: true,
        },
    
        type: {
          type: Number,
          required: false,
          default: 0,
        },
        style: {
          type: String,
          required: false,
          default: 'margin-top: 10%',
        },
        label: {
          type: String,
          required: false,
        },
        loading: {
          type: String,
          required: false,
        },
    
        handleInputBlur: {
          type: Function,
        },
      },
      setup() {
    
        /* return {
          formRule,
        }; */
      },
      data() {
        return {
          partner: this.value,
        };
      },
      watch: {
        value(newValue) {
          this.partner = newValue;
        },
      },
      getTitle(key) {
        return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
      },
      handleInput(newValue, key) {
        this.$set(this.partner, key, newValue);
      },
    };
    </script>
    
    <style scoped>
    .point-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      justify-content: space-between;
      
    }
    
    .point-container > div {
      flex: 10;
      min-width: 20%;
      justify-content: center;
    }
  
    </style>
    