<template>
  <TitleComponent :title="'Sede di ' + tableData.partner.company_name" />
  <el-card>
    <el-form ref="formRef" :model="tableData" :rules="formRule">
      <FormPointComponent
        :inputPoint="tableData"
        :handleInputBlur="handleInputBlur"
        :showDescription="true"
        :loadingPoint="loadingPoint"
        :label="tableData.label"
        :showAbbonamento="true"
        :area="tableData.area"
      ></FormPointComponent>
    </el-form>

    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/partner"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Modifica i dati della sede"
        :isRouter="true"
        linkPath="/partner"
        @buttonClick="update"
      ></ButtonComponent>
    </el-row>

    <br />
    <h3>Dipendenti</h3>

    <div
      v-for="(employee, counter_employee) in tableData.employees"
      v-bind:key="counter_employee"
    >
      <el-form ref="form2" :model="employee">
        <FormEmployeeComponent
          :value="employee"
          @change="handleChange"
          :ifCondition="counter_employee != 0 ? true : false"
          @buttonClick="rimuoviEmployee"
          :counterEmployee="counter_employee"
          :rules="formRuleEmployee"
          :showInputRole="true"
          :readonly="true"
        ></FormEmployeeComponent>
      </el-form>
    </div>

    <el-divider />

    <SelectComponent
      @selected="handleEmployeeSelection"
      :options="employees"
      title="Dipendenti"
      :addButtom="false"
      :isDisabled="false"
    />
    <div
      v-for="(employee_new, counter_employee_new) in employeeNew"
      v-bind:key="counter_employee_new"
    >
      <el-form
        ref="form2"
        :model="employee_new"
        :rules="formRuleEmployee(counter_employee_new)"
      >
        <FormEmployeeComponent
          :value="employee_new"
          :options="options"
          :ifCondition="counter_employee_new != 0 ? true : false"
          @buttonClick="rimuoviEmployee"
          :counterEmployee="counter_employee_new"
          :deleteEmployee="true"
          :rules="formRuleEmployee"
        ></FormEmployeeComponent>
      </el-form>
    </div>
    <ButtonComponent
      label="Aggiungi dipendenti"
      :isRouter="false"
      @buttonClick="aggiungiEmployee"
      type="success"
      :plain="true"
    ></ButtonComponent>

    <div v-if="employeeNew.length > 0">
      <el-row justify="center" style="margin-top: 3.5%">
        <ButtonComponent
          label="Annulla"
          :isRouter="true"
          linkPath="/partner"
          @buttonClick="annulla"
        ></ButtonComponent>

        <ButtonComponent
          label="Aggiungi i nuovi dipendenti"
          :isRouter="false"
          type="primary"
          @buttonClick="onSubmitEmployee('form2')"
        ></ButtonComponent>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import partnerAPI from '@/services/partnerAPI';
import pointAPI from '@/services/pointAPI';
import employeeAPI from '@/services/employeeAPI';

import { ref, toRaw, reactive } from 'vue';
import { useRoute } from 'vue-router';

import { ElMessage } from 'element-plus';

import FormPointComponent from '../../components/FormPointComponent.vue';

import TitleComponent from '../../components/TitleComponent.vue';
import SelectComponent from '../../components/SelectComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormEmployeeComponent from '../../components/FormEmployeeComponent.vue';

export default {
  components: {
    FormPointComponent,
    ButtonComponent,
    TitleComponent,
    FormEmployeeComponent,
    SelectComponent,
  },
  setup() {
    const route = useRoute();

    const options = [
      { value: 'employee', label: 'dipendente' },
      { value: 'adminEmployee', label: 'ammistratore' },
      { value: 'salesvendor', label: 'venditore' },
    ];

    let tableData = ref({
      id: '',
      address: '',
      street_number: '',
      cap: '',
      city: '',
      province: '',
      label: '',
      latitude: 0,
      longitude: 0,
      abbonamento: 0.0,
      employees: [],
      partner: {},
    });

    let employees = ref([]);

    const loadEmployess = async () => {
      try {
        let partner = await partnerAPI.getPartnerByHeadquarter(route.params.id);
        let resp = await employeeAPI.getEmployeesByPartner(partner.data[0].id);
        resp.data.forEach((headquarter) => {
          headquarter.employees.forEach((employee) => {
            let tableRow = {
              value: employee.id,
              label: `${employee.name} ${employee.surname}, ${employee.mail}`,
            };
            const exists = tableData.value.employees.some(
              (existingEmployee) => existingEmployee.id === tableRow.value
            );

            if (!exists) {
              employees.value.push(tableRow);
            }
          });
        });
      } catch (e) {
        console.log('errore load employee: ', e);
      }
    };

    let formRuleEmployee = (counter) => {
      return {
        name: [
          {
            required: true,
            message: 'Inserire il nome' + counter,
            trigger: 'blur',
          },
        ],
        surname: [
          {
            required: true,
            message: 'Inserire il cognome',
            trigger: 'blur',
          },
        ],
        date_birth: [
          {
            type: 'date',
            required: true,
            message: 'Inserire data di nascita',
            trigger: 'blur',
          },
        ],
        mail: [
          {
            required: true,
            message: 'Inserire mail',
            trigger: 'blur',
          },
        ],
        telephone_number: [
          {
            required: true,
            message: 'Inserire numero di telefono',
            trigger: 'blur',
          },
        ],
      };
    };
    const loadHeadquarter = async () => {
      try {
        const resp = await partnerAPI.getHeadquarterById(route.params.id);
        const partner = resp.data[0];

        tableData.value.id = partner.id;
        tableData.value.address = partner.address;
        tableData.value.street_number = partner.street_number;
        tableData.value.cap = partner.cap;
        tableData.value.city = partner.city;
        tableData.value.province = partner.province;
        tableData.value.label = partner.label;
        tableData.value.latitude = partner.latitude;
        tableData.value.longitude = partner.longitude;
        tableData.value.abbonamento = partner.abbonamento;
        tableData.value.partner = partner.partner;
        tableData.value.area = partner.area;

        tableData.value.employees = partner.employees;

        let point = {
          address: tableData.value.address,
          street_number: tableData.value.street_number,
          city: tableData.value.city,
          cap: tableData.value.cap,
          province: tableData.value.province,
        };

        let response = await loadPoints(point);

        tableData.value.label = response.label;

        responsePoint.value.address = response.point.address;
        responsePoint.value.street_number = response.point.street_number;
        responsePoint.value.city = response.point.city;
        responsePoint.value.cap = response.point.cap;
        responsePoint.value.province = response.point.province;
        responsePoint.value.latitude = response.point.latitude;
        responsePoint.value.longitude = response.point.longitude;

        loadEmployess();
      } catch (e) {
        console.log('errore load headquarter: ', e);
      }
    };

    loadHeadquarter();

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await pointAPI.getPoints({
          text: pointToString(point),
        });

        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    let responsePoint = ref({
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      latitude: '',
      longitude: '',
    });

    const handleInputBlur = async () => {
      if (
        tableData.value.address != '' &&
        tableData.value.street_number != '' &&
        tableData.value.city != '' &&
        tableData.value.cap != '' &&
        tableData.value.province != ''
      ) {
        let point = {
          address: tableData.value.address,
          street_number: tableData.value.street_number,
          city: tableData.value.city,
          cap: tableData.value.cap,
          province: tableData.value.province,
        };

        let response = await loadPoints(point);

        tableData.value.label = response.label;
        tableData.value.latitude = response.point.latitude;
        tableData.value.longitude = response.point.longitude;

        responsePoint.value.address = response.point.address;
        responsePoint.value.street_number = response.point.street_number;
        responsePoint.value.city = response.point.city;
        responsePoint.value.cap = response.point.cap;
        responsePoint.value.province = response.point.province;
        responsePoint.value.latitude = response.point.latitude;
        responsePoint.value.longitude = response.point.longitude;
      }
    };

    const update = () => {
      updHeadquarter(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Sede aggiornata con successo!',
            type: 'success',
          });
          route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updHeadquarter = async (headquarter) => {
      let response = {};
      try {
        if (
          headquarter.address != '' &&
          headquarter.street_number != '' &&
          headquarter.city != '' &&
          headquarter.cap != '' &&
          headquarter.province != '' &&
          headquarter.latitude != '' &&
          headquarter.longitude != ''
        ) {
          headquarter.address = responsePoint.value.address;
          headquarter.street_number = responsePoint.value.street_number;
          headquarter.city = responsePoint.value.city;
          headquarter.cap = responsePoint.value.cap;
          headquarter.province = responsePoint.value.province;
          headquarter.latitude = responsePoint.value.latitude;
          headquarter.longitude = responsePoint.value.longitude;

          response = await partnerAPI.updateHeadquarter(headquarter);
        }

        return response;
      } catch (e) {
        console.log('error', e);
      }
    };

    const employeeNew = reactive([
      {
        mail: '',
        name: '',
        surname: '',
        role: '',
        telephone_number: '',
        headquarters: [tableData.value],
      },
    ]);

    const rimuoviEmployee = (e, counter) => {
      employeeNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiEmployee = (e) => {
      employeeNew.push({
        mail: '',
        name: '',
        surname: '',
        role: 'employee',
        telephone_number: '',
        headquarters: [tableData.value],
      });
      e.preventDefault();
    };

    const addEmployee = async (employee) => {
      let response = {};
      try {
        if (!employee.id) {
          response = await partnerAPI.addEmployee(employee);
        } else {
          response = await employeeAPI.addHeadquarterToEmployee({
            id: employee.id,
            headquarter: toRaw(tableData.value),
          });
        }
        return response;
      } catch (error) {
        console.log(error);
        if (response.status === 400) {
          ElMessage({
            message: 'Errore!',
            type: 'error',
          });
          console.log('errore inatteso');
        } else if (response.status === 409) {
          ElMessage({
            message: 'Mail già usata!',
            type: 'error',
          });
          console.log('mail già utilizzata');
        } else {
          ElMessage({
            message: 'Errore catch!',
            type: 'error',
          });
        }
        console.log(error);
      }
    };

    return {
      tableData,
      update,
      rimuoviEmployee,
      aggiungiEmployee,
      employeeNew,
      addEmployee,
      formRuleEmployee,

      loadPoints,
      loadingPoint,
      handleInputBlur,
      options,
      employees,
    };
  },

  methods: {
    onSubmitEmployee(formName) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          this.employeeNew.forEach((employee) => {
            employee.role =employee.role[0];
              
            this.addEmployee(toRaw(employee)).then((response) => {
              if (response.status === 201) {
                this.$router.push('/partner');

                ElMessage({
                  message: 'Dipendeti aggiunti con successo!',
                  type: 'success',
                });
              } else {
                ElMessage({
                  message: 'Errore inserimento Dipendeti!',
                  type: 'error',
                });
                console.log('errore aggiunta dipendenti');
              }
            });
          });
        } else {
          console.log('erroree');
        }
      });
    },

    async handleEmployeeSelection(selectedId) {
      const selectedEmployee = this.employees.find(
        (employee) => employee.value === selectedId[0]
      );

      let employeeData = await employeeAPI.getEmployee(selectedEmployee.value);

      if (employeeData.data[0]) {
        for (const e of this.employeeNew) {
          if (e.name == '' && e.surname == '' && e.mail == '') {
            e.id = employeeData.data[0].id;
            e.name = employeeData.data[0].name;
            e.surname = employeeData.data[0].surname;
            e.mail = employeeData.data[0].mail;
            e.telephone_number = employeeData.data[0].telephone_number;
          }
        }
        /* if (!this.employeeNew.some(e => e.value === selectedId)) {
          this.employeeNew.push({
            id: selectedEmployee.value,
            name: selectedEmployee.label.split(',')[0].split(' ')[0], 
            surname: selectedEmployee.label.split(',')[0].split(' ')[1],
            email: selectedEmployee.label.split(',')[1].trim(),
          });
        } */
      }
    },
  },
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}
</style>
