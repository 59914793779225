<template>
  <h1>Zone</h1>
  <div class="add-button-zona">
    <ButtonComponent
      label="Aggiungi nuova zona"
      :isRouter="true"
      linkPath="/add-area"
      @buttonClick="back"
    ></ButtonComponent>
  </div>

  <div class="table-zone">
    <el-table
      :data="tableData"
      stripe
      height="500"
      style="width: 60%"
      :header-cell-style="{ background: 'rgb(35,44,81)' }"
    >
      <el-table-column prop="area" label="Nome area">
        <template #default="scope">
          <el-input v-model="scope.row.area"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="num_driver" label="N. driver">
      </el-table-column>

      <el-table-column prop="num_jolly" label="N. jolly">
      </el-table-column>

      <el-table-column>
        <template #default="scope">
          <el-button
            size="small"
            @click="handleClick(scope.row.id, scope.row.area)"
          >
            Modifica
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import areasAPI from '../../services/areasAPI.js';
import ButtonComponent from '../../components/ButtonComponent.vue';
import { ElMessage } from 'element-plus';

import { useRouter } from 'vue-router';

export default {
  name: 'AreaPage',
  components: {
    ButtonComponent,
  },
  setup() {
    const value = ref(0);
    const input = ref('');
    const tableData = ref([]);

    const router = useRouter();

    const loadAreas = async () => {
      try {
        const response = await areasAPI.getAreas();
        console.log('🚀 ~ loadAreas ~ response:', response);

        response.data.forEach((area) => {
          const num_driver = area.drivers.filter(
            (driver) => driver.is_jolly === false && driver.is_alive === true
          ).length;

          const num_jolly = area.drivers.filter(
            (driver) => driver.is_jolly === true && driver.is_alive === true
          ).length;

          let tableRow = {
            id: area.id,
            area: area.area,
            num_driver: num_driver,
            num_jolly: num_jolly,
          };
          tableData.value.push(tableRow);
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadAreas();

    const handleClick = async (id, area) => {
      try {
        const response = await areasAPI.modifyArea(id, { area: area });

        if (response.status == 200) {
          ElMessage({
            message: 'Modifica avvenuta con successo!',
            type: 'success',
          });
          router.go('/area');
        } else {
          ElMessage({
            message: 'Errore !',
            type: 'error',
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    return {
      input,
      handleClick,
      value,
      tableData,
    };
  },
};
</script>

<style>
.el-table {
  color: black;
}

.el-table thead {
  color: rgb(255, 255, 255);
}

.el-table td {
  color: black;
}

.el-table line {
  color: black;
}

.add-button-zona {
  margin-top: 3px;
  text-align: right;
}

.table-zone {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically if needed */
  width: 100%; /* Ensure parent container takes up full width */
  height: 100vh; /* Optional: To vertically center in the full viewport height */
}
</style>
