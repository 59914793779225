<template>
  <TitleComponent :title="tableData.company_name" />
  <el-card class="custom-card">
    <el-form ref="formRef" :model="tableData" :rules="formRule">
      <FormPartnerComponent :value="tableData" :type="type" :rules="formRule" />

      <h3>Sedi</h3>
      <div
        v-for="(headquarter, counter_headquarter) in tableData.headquarters"
        v-bind:key="counter_headquarter"
      >
        <el-form ref="form2" :model="headquarter">
          <FormPointComponent
            :inputPoint="headquarter"
            :options="options"
            @change="handleChange"
            :inputTransmission="true"
            :modifyButom="true"
            :deleteCar="false"
            :showAbbonamento="true"
            :readonly="true"
            :area="headquarter.area"
          ></FormPointComponent>
        </el-form>
      </div>
      <el-divider />
      <h4>Aggiungi nuove sedi</h4>
      <div
        v-for="(newHeadquarter, counter_headquarter) in headquarterNew"
        v-bind:key="counter_headquarter"
      >
        <el-form ref="form2" :model="newHeadquarter">
          <br />
          <FormPointComponent
            :inputPoint="newHeadquarter"
            :handleInputBlur="handleInputBlur(newHeadquarter)"
            :showDescription="true"
            :loadingPoint="loadingPoint"
            :label="newHeadquarter.label"
            :deletePoint="true"
            :ifCondition="counter_headquarter != 0 ? true : false"
            @buttonClick="rimuoviHeadquarter"
            :counter="counter_headquarter"
            :showAbbonamento="true"
            :selectArea="true"
            @selected="handleSelectArea"
            :optionsArea="areaExisisting"
          ></FormPointComponent>
        </el-form>
      </div>
      <br />
      <ButtonComponent
        label="Aggiungi sedi"
        :isRouter="false"
        @buttonClick="aggiungiHeadquarter"
        type="success"
        :plain="true"
      ></ButtonComponent>

      <div v-if="headquarterNew.length > 0">
        <el-row justify="center" style="margin-top: 3.5%">
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/partner"
            @buttonClick="annulla"
          ></ButtonComponent>

          <ButtonComponent
            label="Aggiungi le nuove sedi"
            :isRouter="false"
            type="primary"
            @buttonClick="onSubmitHeadquarter('form2')"
          ></ButtonComponent>
        </el-row>
      </div>
      <br />
      <br />
      <br />

      <el-collapse v-model="activeName" accordion>
        <el-collapse-item name="1">
          <template #title>
            <div class="collapse-title">Mostra le tariffe</div>
          </template>
          <FormTariffeComponent
            :value="tableData"
            :type="type"
            :rules="formRule"
          />
        </el-collapse-item>
      </el-collapse>
    </el-form>

    <el-row justify="center" style="margin-top: 3.5%">
      <ButtonComponent
        label="Annulla"
        :isRouter="true"
        linkPath="/partner"
        @buttonClick="back"
      ></ButtonComponent>
      <ButtonComponent
        label="Modifica i dati del partner"
        :isRouter="true"
        linkPath="/partner"
        @buttonClick="update"
      ></ButtonComponent>
    </el-row>
  </el-card>
</template>
<script>
import partnerAPI from '@/services/partnerAPI';
import pointAPI from '@/services/pointAPI';

import { ref, toRaw, reactive } from 'vue';
import { useRoute } from 'vue-router';

import { ElMessage } from 'element-plus';

import FormPartnerComponent from '../../components/FormPartnerComponent.vue';
import FormTariffeComponent from '../../components/FormTariffeComponent.vue';

import TitleComponent from '../../components/TitleComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormPointComponent from '../../components/FormPointComponent.vue';

import agencyAPI from '@/services/agencyAPI';
export default {
  components: {
    FormPartnerComponent,
    ButtonComponent,
    TitleComponent,
    FormTariffeComponent,
    FormPointComponent,
  },
  setup() {
    const route = useRoute();
    let tableData = ref({
      id: '',
      company_name: 'Audi',
      piva: '',
      CF: '',
      SDI: '',
      PEC: '',
      CAB: '',
      ABI: '',
      RID: '',

      mail: '',
      phone_number: '',
      IBAN: '',
      provvigione: '',
      delivery_5km: 0.0,
      delivery_10km: 0.0,
      partner_delivery_5km: 0.0,
      partner_delivery_10km: 0.0,
      delivery_15km: 0.0,
      partner_delivery_15km: 0.0,
      delivery_20km: 0.0,
      partner_delivery_20km: 0.0,
      delivery_25km: 0.0,
      partner_delivery_25km: 0.0,
      delivery_30km: 0.0,
      partner_delivery_30km: 0.0,
      delivery_over_30km: 0.0,
      partner_delivery_over_30km: 0.0,
      pickup_5km: 0.0,
      pickup_10km: 0.0,
      pickup_15km: 0.0,
      pickup_20km: 0.0,
      pickup_25km: 0.0,
      pickup_30km: 0.0,
      pickup_over_30km: 0.0,

      partner_pickup_5km: 0.0,
      partner_pickup_10km: 0.0,
      partner_pickup_15km: 0.0,
      partner_pickup_20km: 0.0,
      partner_pickup_25km: 0.0,
      partner_pickup_30km: 0.0,
      partner_pickup_over_30km: 0.0,

      pickup_loaner_5km: 0.0,
      partner_pickup_loaner_5km: 0.0,
      pickup_loaner_10km: 0.0,
      partner_pickup_loaner_10km: 0.0,
      pickup_loaner_15km: 0.0,
      partner_pickup_loaner_15km: 0.0,
      pickup_loaner_20km: 0.0,
      partner_pickup_loaner_20km: 0.0,
      pickup_loaner_30km: 0.0,
      partner_pickup_loaner_30km: 0.0,
      pickup_loaner_over_30km: 0.0,
      partner_pickup_loaner_over_30km: 0.0,

      escort_10km: 0.0,
      escort_5km: 0.0,
      escort_15km: 0.0,
      escort_20km: 0.0,
      escort_30km: 0.0,
      escort_over_30km: 0.0,
      partner_escort_5km: 0.0,
      partner_escort_10km: 0.0,
      partner_escort_15km: 0.0,
      partner_escort_20km: 0.0,
      partner_escort_30km: 0.0,
      partner_escort_over_30km: 0.0,

      escort_loaner_5km: 0.0,
      partner_escort_loaner_5km: 0.0,
      escort_loaner_10km: 0.0,
      partner_escort_loaner_10km: 0.0,
      escort_loaner_15km: 0.0,
      partner_escort_loaner_15km: 0.0,
      escort_loaner_20km: 0.0,
      partner_escort_loaner_20km: 0.0,
      escort_loaner_30km: 0.0,
      partner_escort_loaner_30km: 0.0,
      escort_loaner_over_30km: 0.0,
      partner_escort_loaner_over_30km: 0.0,
      one_valet: 0.0,
      two_valet: 0.0,
      three_valet: 0.0,
      headquarters: [],
    });

    const loadTariffe = async () => {
      try {
        const resp = await partnerAPI.getPartnerById(route.params.id);

        const partner = resp.data[0];
        tableData.value.id = partner.id;
        tableData.value.company_name = partner.company_name;
        tableData.value.piva = partner.piva;
        tableData.value.CF = partner.CF;
        tableData.value.SDI = partner.SDI;
        tableData.value.PEC = partner.PEC;
        tableData.value.mail = partner.mail;
        tableData.value.phone_number = partner.phone_number;
        tableData.value.IBAN = partner.IBAN;
        tableData.value.CAB = partner.CAB;
        tableData.value.ABI = partner.ABI;
        tableData.value.RID = partner.RID;
        tableData.value.provvigione = partner.provvigione;

        tableData.value.delivery_10km = partner.delivery_10km;
        tableData.value.delivery_5km = partner.delivery_5km;
        tableData.value.partner_delivery_5km = partner.partner_delivery_5km;
        tableData.value.partner_delivery_10km = partner.partner_delivery_10km;
        tableData.value.delivery_15km = partner.delivery_15km;
        tableData.value.partner_delivery_15km = partner.partner_delivery_15km;
        tableData.value.delivery_20km = partner.delivery_20km;
        tableData.value.partner_delivery_20km = partner.partner_delivery_20km;
        tableData.value.delivery_25km = partner.delivery_25km;
        tableData.value.partner_delivery_25km = partner.partner_delivery_25km;
        tableData.value.delivery_30km = partner.delivery_30km;
        tableData.value.partner_delivery_30km = partner.partner_delivery_30km;
        tableData.value.delivery_over_30km = partner.delivery_over_30km;
        tableData.value.partner_delivery_over_30km =
          partner.partner_delivery_over_30km;

        tableData.value.pickup_5km = partner.pickup_5km;
        tableData.value.pickup_10km = partner.pickup_10km;
        tableData.value.pickup_15km = partner.pickup_15km;
        tableData.value.pickup_20km = partner.pickup_20km;
        tableData.value.pickup_25km = partner.pickup_25km;
        tableData.value.pickup_30km = partner.pickup_30km;
        tableData.value.pickup_over_30km = partner.pickup_over_30km;
        tableData.value.partner_pickup_5km = partner.partner_pickup_5km;
        tableData.value.partner_pickup_10km = partner.partner_pickup_10km;
        tableData.value.partner_pickup_15km = partner.partner_pickup_15km;
        tableData.value.partner_pickup_20km = partner.partner_pickup_20km;
        tableData.value.partner_pickup_25km = partner.partner_pickup_25km;
        tableData.value.partner_pickup_30km = partner.partner_pickup_30km;
        tableData.value.partner_pickup_over_30km =
          partner.partner_pickup_over_30km;

        tableData.value.pickup_loaner_5km = partner.pickup_loaner_5km;
        tableData.value.partner_pickup_loaner_5km = partner.partner_pickup_loaner_5km;
        tableData.value.pickup_loaner_10km = partner.pickup_loaner_10km;
        tableData.value.partner_pickup_loaner_10km =
          partner.partner_pickup_loaner_10km;
        tableData.value.pickup_loaner_15km = partner.pickup_loaner_15km;
        tableData.value.partner_pickup_loaner_15km =
          partner.partner_pickup_loaner_15km;
        tableData.value.pickup_loaner_20km = partner.pickup_loaner_20km;
        tableData.value.partner_pickup_loaner_20km =
          partner.partner_pickup_loaner_20km;
        tableData.value.pickup_loaner_30km = partner.pickup_loaner_30km;
        tableData.value.partner_pickup_loaner_30km =
          partner.partner_pickup_loaner_30km;
        tableData.value.pickup_loaner_over_30km =
          partner.pickup_loaner_over_30km;
        tableData.value.partner_pickup_loaner_over_30km =
          partner.partner_pickup_loaner_over_30km;

        tableData.value.escort_5km = partner.escort_5km;
        tableData.value.escort_10km = partner.escort_10km;
        tableData.value.escort_15km = partner.escort_15km;
        tableData.value.escort_20km = partner.escort_20km;
        tableData.value.escort_30km = partner.escort_30km;
        tableData.value.escort_over_30km = partner.escort_over_30km;
        tableData.value.partner_escort_5km = partner.partner_escort_5km;
        tableData.value.partner_escort_10km = partner.partner_escort_10km;
        tableData.value.partner_escort_15km = partner.partner_escort_15km;
        tableData.value.partner_escort_20km = partner.partner_escort_20km;
        tableData.value.partner_escort_30km = partner.partner_escort_30km;
        tableData.value.partner_escort_over_30km =
          partner.partner_escort_over_30km;

        tableData.value.escort_loaner_5km = partner.escort_loaner_5km;
        tableData.value.escort_loaner_10km = partner.escort_loaner_10km;
        tableData.value.partner_escort_loaner_5km =
          partner.partner_escort_loaner_5km;
        tableData.value.partner_escort_loaner_10km =
          partner.partner_escort_loaner_10km;
        tableData.value.escort_loaner_15km = partner.escort_loaner_15km;
        tableData.value.partner_escort_loaner_15km =
          partner.partner_escort_loaner_15km;
        tableData.value.escort_loaner_20km = partner.escort_loaner_20km;
        tableData.value.partner_escort_loaner_20km =
          partner.partner_escort_loaner_20km;
        tableData.value.escort_loaner_30km = partner.escort_loaner_30km;
        tableData.value.partner_escort_loaner_30km =
          partner.partner_escort_loaner_30km;
        tableData.value.escort_loaner_over_30km =
          partner.escort_loaner_over_30km;
        tableData.value.partner_escort_loaner_over_30km =
          partner.partner_escort_loaner_over_30km;

        tableData.value.one_valet = partner.one_valet;
        tableData.value.two_valet = partner.two_valet;
        tableData.value.three_valet = partner.three_valet;

        tableData.value.headquarters = partner.headquarters;
      } catch (e) {
        console.log('errore load tariffe: ', e);
      }
    };

    loadTariffe();

    const headquarterNew = reactive([
      {
        address: '',
        street_number: '',
        cap: '',
        city: '',
        province: '',
        label: '',
        latitude: 0,
        longitude: 0,
        abbonamento: 0,
        employees: [],
        partner: tableData.value,

        address_geocoded: '',
        street_number_geocoded: '',
        city_geocoded: '',
        cap_geocoded: '',
        province_geocoded: '',
      },
    ]);

    const rimuoviHeadquarter = (e, counter) => {
      headquarterNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiHeadquarter = (e) => {
      headquarterNew.push({
        address: '',
        street_number: '',
        cap: '',
        city: '',
        province: '',
        label: '',
        latitude: 0,
        longitude: 0,
        abbonamento: 0,
        employees: [],
        partner: tableData.value,
        address_geocoded: '',
        street_number_geocoded: '',
        city_geocoded: '',
        cap_geocoded: '',
        province_geocoded: '',
      });
      e.preventDefault();
    };

    let areaExisisting = ref([]);
    let areaExisistingResponse = ref([]);
    const loadAreas = async () => {
      try {
        const resp = await agencyAPI.getAreas();
        areaExisistingResponse.value = resp.data;

        resp.data.forEach((area) => {
          let tableRow = {
            value: area.id,
            label: `${area.area}`,
          };
          areaExisisting.value.push(tableRow);
        });
      } catch (e) {
        console.log('errore load area: ', e);
      }
    };
    loadAreas();

    const handleSelectArea = (newValue, counter) => {
      const selectedArea = areaExisistingResponse.value.find(
        (hq) => hq.id === newValue[0]
      );

      if (selectedArea) {
        headquarterNew[counter].area = selectedArea;
      }
    };

    const pointToString = (point) => {
      const address = point.address ? point.address : '';
      const street_number = point.street_number ? point.street_number : '';
      const city = point.city ? point.city : '';
      const province = point.province ? point.province : '';
      const cap = point.cap ? point.cap : '';

      return (
        address + ' ' + street_number + ' ' + cap + ', ' + city + ' ' + province
      );
    };
    let loadingPoint = ref(true);

    const loadPoints = async (point) => {
      try {
        const response = await pointAPI.getPoints({
          text: pointToString(point),
        });

        loadingPoint.value = false;
        return response.data;
      } catch (error) {
        console.log(error);
        return [];
      }
    };

    /* let responsePoint = ref([{
      address: '',
      street_number: '',
      city: '',
      cap: '',
      province: '',
      latitude: '',
      longitude: '',
    }]); */

    const handleInputBlur = async (headquarter) => {
      if (
        headquarter.address != '' &&
        headquarter.street_number != '' &&
        headquarter.city != '' &&
        headquarter.cap != '' &&
        headquarter.province != ''
      ) {
        let point = {
          address: headquarter.address,
          street_number: headquarter.street_number,
          city: headquarter.city,
          cap: headquarter.cap,
          province: headquarter.province,
        };

        let response = await loadPoints(point);

        headquarter.label = response.label;
        headquarter.latitude = response.point.latitude;
        headquarter.longitude = response.point.longitude;

        headquarter.address_geocoded = response.point.address;
        headquarter.street_number_geocoded = response.point.street_number;
        headquarter.city_geocoded = response.point.city;
        headquarter.cap_geocoded = response.point.cap;
        headquarter.province_geocoded = response.point.province;
      }
    };

    const update = () => {
      updPartner(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Partner aggiornato con successo!',
            type: 'success',
          });
          route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updPartner = async (partner) => {
      let response = {};
      try {
        response = await partnerAPI.updatePartner(partner);
        return response;
      } catch {
        console.log('error');
      }
    };

    const addHeadquarter = async (headquarter) => {
      let response = {};
      try {
        response = await partnerAPI.addHeadquarter(headquarter);
        return response;
      } catch (error) {
        ElMessage({
          message: 'Errore!',
          type: 'error',
        });
        console.log('errore inatteso');

        console.log(error);
      }
    };
    return {
      tableData,
      update,
      rimuoviHeadquarter,
      aggiungiHeadquarter,
      headquarterNew,
      loadPoints,
      loadingPoint,
      handleInputBlur,
      addHeadquarter,

      areaExisistingResponse,
      areaExisisting,
      handleSelectArea,
    };
  },

  methods: {
    onSubmitHeadquarter(formName) {
      this.$refs[formName][0].validate((valid) => {
        if (valid) {
          this.headquarterNew.forEach((headquarter) => {
            this.addHeadquarter(toRaw(headquarter)).then((response) => {
              if (response.status === 201) {
                this.$router.push('/partner');

                ElMessage({
                  message: 'Sedi aggiunte con successo!',
                  type: 'success',
                });
              } else {
                ElMessage({
                  message: 'Errore inserimento Dipendeti!',
                  type: 'error',
                });
                console.log('errore aggiunta dipendenti');
              }
            });
          });
        } else {
          console.log('erroree');
        }
      });
    },
  },
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
