<template>
  <TitleComponent :title="tableData.name + ' ' + tableData.surname" />
  <el-form ref="form" :model="tableData" :rules="formRule">
    <FormEmployeeComponent
      :value="tableData"
      :options="options"
      @change="handleChange"
      :rules="formRuleEmployee"
      :showInputRole="true"
    ></FormEmployeeComponent>
  </el-form>

  <el-row justify="center" style="margin-bottom: 3%">
    <ButtonComponent
      label="Annulla"
      :isRouter="true"
      linkPath="/partner"
      @buttonClick="back"
    ></ButtonComponent>
    <ButtonComponent
      label="Modifica i dati del dipendente"
      :isRouter="true"
      linkPath="/partner"
      @buttonClick="update"
    ></ButtonComponent>
  </el-row>

  <h3>Sedi</h3>
  <div
    v-for="(headquarter, counter_headquarter) in tableData.headquarters"
    v-bind:key="counter_headquarter"
  >
    <el-form ref="form2" :model="headquarter">
      <FormPointComponent
        :inputPoint="headquarter"
        :options="options"
        @change="handleChange"
        :inputTransmission="true"
        :modifyButom="true"
        :deleteCar="false"
        :readonly="true"
      ></FormPointComponent>
    </el-form>
  </div>

  <h4>Aggiungi nuove sedi</h4>
  <div
    v-for="(headquarternew, counter_headquarter) in headquarterNew"
    v-bind:key="counter_headquarter"
  >
    <el-form ref="form2" :model="headquarternew">
      <br />
      <SelectComponent
        @selected="handleSelectHeadquarter"
        :options="headquarterPartner"
        title="Scegli una delle sedi del partner"
        :addButtom="counter_headquarter != 0 ? true : false"
        :isDisabled="false"
        :value="headquarternew.id"
        :counterElement="counter_headquarter"
        @buttonClick="rimuoviHeadquarter"
      />
      <!-- <SelectComponent
       @selected="(newValue) => (headquarternew.id = newValue)"
       :options="headquarterPartner"
       title="Scegli una delle sedi del partner"
       :addButtom="counter_headquarter != 0 ? true : false"
       :isDisabled="false"
       :value="headquarternew.id"
       :counterElement="counter_headquarter"
       @buttonClick="rimuoviHeadquarter"
     /> -->
    </el-form>
  </div>
  <br />
  <ButtonComponent
    label="Aggiungi sedi"
    :isRouter="false"
    @buttonClick="aggiungiHeadquarter"
    type="success"
    :plain="true"
  ></ButtonComponent>
  <el-row justify="center" style="margin-top: 3.5%">
    <ButtonComponent
      label="Annulla"
      :isRouter="true"
      linkPath="/partner"
      @buttonClick="annulla"
    ></ButtonComponent>

    <ButtonComponent
      label="Aggiungi le nuove sedi"
      :isRouter="false"
      type="primary"
      @buttonClick="onSubmitHeadquarter"
    ></ButtonComponent>
  </el-row>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, ref } from 'vue';
import partnerAPI from '../../services/partnerAPI.js';
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';

import TitleComponent from '../../components/TitleComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormEmployeeComponent from '../../components/FormEmployeeComponent.vue';
import FormPointComponent from '../../components/FormPointComponent.vue';
import SelectComponent from '../../components/SelectComponent.vue';

export default {
  components: {
    FormPointComponent,
    ButtonComponent,
    TitleComponent,
    FormEmployeeComponent,
    SelectComponent,
  },

  setup() {
    const route = useRoute();

    const options = [
      { value: 'employee', label: 'dipendente' },
      { value: 'adminEmployee', label: 'ammistratore' },
      { value: 'salesvendor', label: 'venditore' },
    ];

    const props1 = {
      //checkStrictly: true,
      expandTrigger: 'hover',
    };

    const role_val = ref('');

    const tableData = ref({
      id: '',
      name: '',
      surname: '',
      date_birth: '',
      mail: '',
      telephone_number: '',
      role: role_val.value,
      partner: {},
      headquarters: [],
    });

    let valid_employe = ref(false);

    let formRule = reactive({
      name: [{ required: true, message: 'Inserire il nome', trigger: 'blur' }],
      surname: [
        { required: true, message: 'Inserire il cognome', trigger: 'blur' },
      ],
      date_birth: [
        {
          type: 'date',
          required: true,
          message: 'Inserire data di nascita',
          trigger: 'blur',
        },
      ],
      mail: [{ required: true, message: 'Inserire mail', trigger: 'blur' }],
      telephone_number: [
        {
          required: true,
          message: 'Inserire numero di telefono',
          trigger: 'blur',
        },
      ],
      role: [{ required: true, message: 'Selezionare ruolo', trigger: 'blur' }],
    });

    const handleChange = (val) => {
      console.log(val);
      tableData.value.role = val[0];

      console.log(role_val.value);
    };

    const loadEmployee = async () => {
      try {
        const resp = await partnerAPI.getEmployeeById(route.params.id);

        const employee = resp.data[0];

        tableData.value.id = employee.id;
        tableData.value.name = employee.name;
        tableData.value.surname = employee.surname;
        tableData.value.date_birth = employee.date_birth;
        tableData.value.mail = employee.mail;
        tableData.value.telephone_number = employee.telephone_number;
        tableData.value.role = employee.role;

        tableData.value.headquarters = employee.headquarters;
        tableData.value.partner = employee.headquarters[0].partner;
        loadHeadquarterPartner(employee.headquarters[0].partner.id);
      } catch (e) {
        console.log('errore load headquarter: ', e);
      }
    };

    let headquarterPartner = ref([]);
    let fullHeadquarters = ref([]);
    let headquarterNewEmployee = ref([]);

    const loadHeadquarterPartner = async (partnerID) => {
      try {
        const resp = await partnerAPI.getPartnerById(partnerID);

        //headquarterPartner.value = resp.data[0].headquarters;
        fullHeadquarters.value = resp.data[0].headquarters;

        resp.data[0].headquarters.forEach((headquarter) => {
          let tableRow = {
            value: headquarter.id,
            label: `${headquarter.address} ${headquarter.street_number}, ${headquarter.city} ${headquarter.province}`,
          };
          headquarterPartner.value.push(tableRow);
        });
      } catch (e) {
        console.log('errore load headquarter: ', e);
      }
    };

    const handleSelectHeadquarter = (newValue) => {
      
      const selectedHeadquarter = fullHeadquarters.value.find(
        (hq) => hq.id === newValue[0]
      );

      if (selectedHeadquarter) {
        headquarterNewEmployee.value.push(selectedHeadquarter);
      }
    };

    let headquarterNew = reactive([
      {
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      },
    ]);

    const aggiungiHeadquarter = (e) => {
      headquarterNew.push({
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      });
      e.preventDefault();
    };

    const rimuoviHeadquarter = (e, counter) => {
      headquarterNew.splice(counter, 1);
      e.preventDefault();
    };

    loadEmployee();

    const update = () => {
      updEmployee(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Sede aggiornata con successo!',
            type: 'success',
          });
          route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updEmployee = async (employee) => {
      let response = {};
      try {
        response = await partnerAPI.updateEmployee(employee);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      handleChange,
      role_val,
      props1,
      tableData,
      options,
      valid_employe,
      formRule,
      headquarterNew,
      headquarterPartner,
      aggiungiHeadquarter,
      rimuoviHeadquarter,
      update,
      handleSelectHeadquarter,
      headquarterNewEmployee,
      updEmployee,
      route,
    };
  },

  methods: {
    onSubmitHeadquarter() {
      this.tableData.headquarters = [
        ...this.tableData.headquarters,
        ...this.headquarterNewEmployee,
      ];

      this.updEmployee(toRaw(this.tableData)).then((response) => {
        if (response.status === 200) {
          this.$router.push('/partner');

          ElMessage({
            message: 'Modifica avvenuta con successo!',
            type: 'success',
          });
        } else {
          ElMessage({
            message: 'Errore inserimento Dipendeti!',
            type: 'error',
          });
          console.log('errore aggiunta dipendenti');
        }
      });
    },
  },
};
</script>

<style>
.el-col {
  margin-top: 4%;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
