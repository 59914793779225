<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-item: center;
    "
  >
    <div
      style="
        width: 400px;
        height: 250px;
        border: 1px solid #409eff;
        border-radius: 20px;
        padding: 20px;
      "
    >
      <h1>Accedi</h1>
      <el-form :model="loginData" label-width="100px">
        <el-form-item label="Nome utente">
          <el-input v-model="loginData.username" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="loginData.password"
            type="password"
            placeholder="Inserire password"
            show-password
          />
        </el-form-item>

        <el-form-item style="text-align: center; margin-left: 0px">
          <el-button type="primary" @click="onSubmit"> Accedi </el-button>
        </el-form-item>

      </el-form>
      
      <router-link :to="`/reset-password`" class="routerLink">
        <el-link style="margin-top: 11px" :underline="false"
          >Hai dimenticato la password? clicca quì!</el-link
        >
      </router-link>
    </div>

  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { reactive,toRaw } from 'vue';
import { isLoggedStore } from '@/store/logged';
import authAPI from '../services/authAPI.js';
import { ElMessage } from 'element-plus';
import customerAPI from '@/services/customerAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const store = isLoggedStore();
    const loginData = reactive({
      username: '',
      password: '',
    });

    const logIn_data = async (loginData) => {
      /* ... */
      console.log(loginData)
      try{
         const response = await authAPI.login(loginData);
          console.log(response)
          console.log(response.data.access_token)
  
         return response;
      }catch(error){
          console.log(error);
      }
    };

    const parseJwt= (token) =>{
    if (!token) {
      return;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");

    return JSON.parse(window.atob(base64));

  }
    //Inizializzo i pulsanti
    const onSubmit = () => {
         logIn_data(toRaw( loginData) ).then(async (response) => {
          try{  if (response.status === 201) {
            store.accessToken=response.data.access_token;
            store.refreshToken=response.data.refreshtoken;

            const authRefresh= parseJwt(response.data.refreshtoken);
            store.refreshExp= authRefresh.exp*1000;

            const auth= parseJwt(response.data.access_token);
            store.jwtExp= auth.exp*1000;

            store.id= auth.sub;
            store.mail= auth.user.mail;
            store.role= auth.user.role;

            const employee=await customerAPI.getCustomer(auth.sub);
            console.log("----------------employee------------- ",  toRaw(employee.data))
            store.employee= toRaw(employee.data[0]);

          if (response.status === 201 && auth.user.role=="admin") {
            store.id=auth.user.id;
            store.jwt=response.data.access_token;
            store.role = auth.user.role;
            store.isLogged = true;
            router.push('/booking');
          }else {
              ElMessage({
                  message: "Non sei autorizzato ",
                  type: "Error",
                });
            console.log('Non sei autorizzato');
            }
        }
        else if(response.status === 401)  {
           ElMessage({
                message: "Non sei autorizzato ",
                type: "Error",
              });
          console.log('Non sei autorizzato');
        }
        else if (response.status === 404) {
           ElMessage({
                message: "Username o password errati",
                type: "Error",
              });
          console.log('errore inatteso');
        } else if (response.status === 400) {
           ElMessage({
                message: "Error",
                type: "Error",
              });
      }} catch(error){
           ElMessage({
                message: "Username o password errati",
                type: "Error",
              });
          console.log('errore inatteso');
        
      }
      });
    };

    return {
      loginData,
      onSubmit,
      store
    };
  },
};
</script>