import API from './API';

export default { 
    getMinute() {
        return API.get('/minute');
    },

    confermaMinute(minuteid) {
      return API.get('/minute/confirm/' + minuteid);
  },
}