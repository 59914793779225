<template>
  <div class="select-container">
    <div class="cascader-container">
      <el-cascader
        v-model="selectedElement"
        :placeholder="title"
        :options="options"
        filterable
        :props="props1"
        clearable
        :class="{ 'highlight': highlight }"
        :prop="prop"
      />
    </div>
    <div class="button-container">
      <ButtonComponent v-if="addButtom"
        :isRouter="false"
        @buttonClick="handleButtonClickDelete($event, counterElement)"
        type="danger"
        icon="Delete"
      ></ButtonComponent>
    </div>
  </div>
  
</template>

<script>
import ButtonComponent from "./ButtonComponent.vue"
export default {
  components: {
    ButtonComponent
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    addButtom: {
      type: Boolean,
      require: true,
    },
    highlight: {
      type: Boolean,
      require: true,
    },
    linkPath: {
      type: String,
      required: false,
    },
    model: {
      required: false,
    },
    isDisabled: {
      type: Boolean,
      require: true,
    },
    span: {
      type: Number,
      required: false,
      default: 8,
    },
    style: {
      type: String,
      required: false,
    },

    prop: { 
      type: String,
       required: false
     },

    value: {
      required: false,
    },

    /* buttonClickDelete:{
      required: false,
    }, */
    counterElement:{
      required:false
    },
    /* delete:{
      required:false,
      default:true,
      type:Boolean
    } */
  },

  data() {
    return {
      //selectedElement: '',
      selectedElement: this.value,
    };
  },

  watch: {
    value(newValue) {
      this.selectedElement = newValue;
    },

    selectedElement(newValue) {
      this.$emit('selected', newValue);
    },

  },
  methods: {
        
    handleButtonClickDelete() {
      this.$emit('buttonClick', this.selectedElement, this.counterElement);
    }

  }
};
</script>
<style>

.highlight {
  border: 2px solid rgb(205, 54, 54); 
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  margin-bottom: 2%;
}

.button-container {
  justify-content: center; 
  align-items: center;
}
</style>