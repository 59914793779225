<template>
    <TitleComponent :title="tableData.name + ' ' + tableData.surname" />
    <el-card class="custom-card">
      <el-form ref="formRef" :model="tableData" :rules="formRule">
        <div style="text-align: right;">
          <ButtonComponent
            label="Elimina driver"
            type="danger"
            :isRouter="false"
            @buttonClick="deleteDriver(tableData.id)"
          ></ButtonComponent>
        </div>
        <FormDriverComponent :value="tableData" :rules="formRule" :is-detail="true"/>

        <el-divider v-if="tableData.areas.length != 0" />

        <h4 v-if="tableData.areas.length != 0">Zone</h4>
        <div v-for="(item, index) in tableData.areas" :key="index" class="select-container">
            <InputComponent
            :value="item.area"
            title="Punto di prelievo"
            :label="'Area ' + (index +1)"
            :showEeuro="false"
            :readonly="true"
            ></InputComponent> 
            <ButtonComponent 
              :isRouter="false"
              @buttonClick="deleteZona(item.id)"
              type="danger"
              icon="Delete"
            ></ButtonComponent>
        </div>
        
        
        <el-row justify="center" style="margin-top: 3.5%">
          <ButtonComponent
            label="Modifica i dati del driver"
            :isRouter="true"
            linkPath="/driver"
            @buttonClick="update"
          ></ButtonComponent>
        </el-row>

        <el-divider />

        <h4>Aggiungi nuove sedi</h4>
        <div
          v-for="(zonanew, index) in zonaNew"
          v-bind:key="index"
        >
          <el-form ref="form2" :model="zonanew">
            <br />
            <SelectComponent
              @selected="handleSelectZona"
              :options="zoneDriver"
              title="Scegli una delle sedi del partner"
              :addButtom="index != 0 ? true : false"
              :isDisabled="false"
              :value="zonanew.id"
              :counterElement="index"
              @buttonClick="rimuoviSede"
            />
          </el-form>
        </div>
        <br/>
        <ButtonComponent
          label="Aggiungi sedi"
          :isRouter="false"
          @buttonClick="aggiungiArea"
          type="success"
          :plain="true"
        ></ButtonComponent>

        <el-row justify="center" style="margin-top: 3.5%">
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/driver"
            @buttonClick="back"
          ></ButtonComponent>
          <ButtonComponent
            label="Aggiungi le nuove sedi"
            type="primary"
            :isRouter="false"
            @buttonClick="onSubmit('formRef')"
          ></ButtonComponent>
        </el-row>

      </el-form>
    </el-card>
</template>

<script>
import TitleComponent from '../../components/TitleComponent.vue';
import FormDriverComponent from '../../components/FormDriverComponent.vue'
import ButtonComponent from '../../components/ButtonComponent.vue'
import InputComponent from '../../components/InputComponent.vue'
import SelectComponent from '../../components/SelectComponent.vue';
import { ElMessage } from 'element-plus';

import { useRoute, useRouter } from 'vue-router';
import driverAPI from '../../services/driverAPI.js';
import agencyAPI from '../../services/agencyAPI.js'
import { ref, toRaw, reactive } from "vue";

export default {
  components: {
    TitleComponent,
    FormDriverComponent,
    ButtonComponent,
    InputComponent,
    SelectComponent
  },
  setup() {
    
    const route = useRoute();
    const router = useRouter();
    //const driverId = route.params.id.split("-")[0];
    let tableData = ref({
        id: "",
        name: "",
        surname: "",
        mail: "",
        telephone_number: "",
        date_birth: "",
        emergency_telephone_number: "",
        iban: "",
        CF: "",
        agenzia: "",
        agenziaId: "",
        areas: []
    });

    let zonaNew = reactive([
      {
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      },
    ]);
    let zoneDriver = ref([]);

    let addNewArea = ref([]);
    let fullZone = ref([]);

    const loadDriver = async () => {
      try {
        const resp = await driverAPI.getDriverById(route.params.id);

        const driver = resp.data[0];
        tableData.value.id = driver.id;
        tableData.value.name = driver.name;
        tableData.value.surname = driver.surname;
        tableData.value.mail = driver.mail;
        tableData.value.date_birth = driver.date_birth;
        tableData.value.telephone_number = driver.telephone_number;
        tableData.value.emergency_telephone_number = driver.emergency_telephone_number;
        tableData.value.iban = driver.iban;
        tableData.value.CF = driver.CF;
        tableData.value.agenzia = driver.agency.company_name;
        tableData.value.agenziaId = driver.agency.id;
        tableData.value.areas = driver.areas;

        loadAllSedi();
      } catch (e) {
        console.log('errore load tariffe: ', e);
      }
    };

    loadDriver();

    const loadAllSedi = async () => {
      try {
        
        const resp = await agencyAPI.getAgenciesById(tableData.value.agenziaId);
        fullZone.value = resp.data[0].areas;

        resp.data[0].areas.forEach(async (a) => {

            let tableRow = {
                value: a.id,
                label: a.area,
            };

            zoneDriver.value.push(tableRow)
        })
      } catch (e) {
        console.log('errore load sedi: ', e);
      }
    };

    const update = () => {
      updDriver(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Driver aggiornato con successo!',
            type: 'success',
          });
          router.push('/driver');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updDriver = async (driver) => {
      let response = {};
      try {
        response = await driverAPI.updateDriver(driver);
        return response;
      } catch {
        console.log('error');
      }
    };

    const handleSelectZona = (newValue) => {
      
      const selectedZona = fullZone.value.find(
        (hq) => hq.id === newValue[0]
      );

      if (selectedZona) {
        addNewArea.value.push(selectedZona);
      }
    };

    const aggiungiArea = (e) => {
      zonaNew.push({
        id: '',
        province: '',
        cap: '',
        city: '',
        address: '',
        street_number: '',
        latitude: 0,
        longitude: 0,
      });
      e.preventDefault();
    };

    const rimuoviSede = (e, counter) => {
      zonaNew.splice(counter, 1);
      e.preventDefault();
    };

    const onSubmit = async () => {
      addNewArea.value.forEach(area => {
        if(tableData.value.areas.find(a => a.id == area.id)) {
          console.log()
        } else {
          tableData.value.areas.push(area)
        }
      })  
      update();
    };

    const deleteDriver = (id) => {
      deleteD(id).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Driver eliminato con successo!',
            type: 'success',
          });
          router.push('/driver');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const deleteD = async (id) => {
      let response = {};
      try {
        response = await driverAPI.deleteDriver(id);
        return response;
      } catch {
        console.log('error');
      }
    };

    const deleteZona = (id) => {
      deleteZ(id).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: 'Driver eliminato con successo!',
            type: 'success',
          });
          router.push('/driver');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const deleteZ = async (id) => {
      let response = {};
      try {
        response = await driverAPI.deleteDriver(id);
        return response;
      } catch {
        console.log('error');
      }
    };

    return { 
        loadDriver,
        tableData,
        update,
        zonaNew,
        addNewArea,
        handleSelectZona,
        zoneDriver,
        aggiungiArea,
        rimuoviSede,
        onSubmit,
        deleteDriver,
        deleteZona
    }
  }
}
</script>

<style>
.highlight {
    border: 2px solid rgb(205, 54, 54); 
  }
.point-container {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: space-between;
}

.point-container > div {
  flex: 7;
  min-width: 15%;
  justify-content: center;
}

.select-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1px; /* Increased gap slightly for better spacing */
}

.button-container {
  margin-top: 0px;
  justify-content: center; /* Center the button */
  align-items: center; /* Center the button vertically */
}
</style>