<template>
  <TitleComponent :title="tableData.company_name" />
  <el-card class="custom-card">
    <el-form ref="formRef" :model="tableData" :rules="formRule">
      <FormAgencyComponent :value="tableData" :type="type" :rules="formRule" />

      <h3>Aree</h3>
      <div
        v-for="(area, counter_area) in tableData.areas"
        v-bind:key="counter_area"
      >
        <el-form ref="form2" :model="area">
          <InputComponent
            :value="area.area"
            label="area"
            prop="area"
            @input="(newValue) => (area.area = newValue.target.value)"
            :showEeuro="false"
            :disabled="disabled"
          />
        </el-form>
      </div>

      <el-row justify="center" style="margin-top: 3.5%">
        <ButtonComponent
          label="Annulla"
          :isRouter="true"
          linkPath="/agency"
          @buttonClick="back"
        ></ButtonComponent>
        <ButtonComponent
          label="Modifica i dati dell'agenzia"
          :isRouter="true"
          linkPath="/agency"
          @buttonClick="update"
        ></ButtonComponent>
      </el-row>


      <el-divider />
      <h4>Aggiungi nuove aree</h4>
      <div
        v-for="(areaNew, counter_area) in areasNew"
        v-bind:key="counter_area"
      >
        <el-form ref="form2" :model="areaNew">
          <br />
          <SelectComponent
          @selected="handleSelectArea"
          :options="areaExisisting"
          title="Scegli un'area"
          :addButtom="counter_area != 0 ? true : false"
          :isDisabled="false"
          :value="areaNew.id"
          :counterElement="counter_area"
          @buttonClick="rimuoviArea"
        />
        </el-form>
      </div>
      <br />
      <ButtonComponent
        label="Aggiungi area"
        :isRouter="false"
        @buttonClick="aggiungiArea"
        type="success"
        :plain="true"
      ></ButtonComponent>

      <div v-if="areasNew.length > 0">
        <el-row justify="center" style="margin-top: 3.5%">
          <ButtonComponent
            label="Annulla"
            :isRouter="true"
            linkPath="/partner"
            @buttonClick="annulla"
          ></ButtonComponent>

          <ButtonComponent
            label="Aggiungi le nuove sedi"
            :isRouter="false"
            type="primary"
            @buttonClick="onSubmitArea"
          ></ButtonComponent>
        </el-row>
      </div>
    </el-form>


  </el-card>
</template>

<script>
import agencyAPI from '@/services/agencyAPI';

import { ref, toRaw , reactive} from 'vue';
import { useRoute } from 'vue-router';

import { ElMessage } from 'element-plus';

import InputComponent from '../../components/InputComponent.vue';
import TitleComponent from '../../components/TitleComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import FormAgencyComponent from '../../components/FormAgencyComponent.vue';
import SelectComponent  from '../../components/SelectComponent.vue';

export default {
  components: {
    FormAgencyComponent,
    ButtonComponent,
    TitleComponent,
    InputComponent,
    SelectComponent
  },
  setup() {
    const route = useRoute();
    let tableData = ref({
      id: '',
      company_name: '',
      name: '',
      surname: '',
      telephone_number: '',
      mail: '',
      priority: 0,
      document: '',
      areas: [],
    });

    const loadAgency = async () => {
      const resp = (await agencyAPI.getAgenciesById(route.params.id)).data[0];
      tableData.value.id = resp.id;
      tableData.value.company_name = resp.company_name;
      tableData.value.name = resp.name;
      tableData.value.surname = resp.surname;
      tableData.value.telephone_number = resp.telephone_number;
      tableData.value.mail = resp.mail;
      tableData.value.priority = resp.priority;
      tableData.value.document = resp.document;
      tableData.value.areas = resp.areas;
    };
    loadAgency();


    const update = () => {
      updArea(toRaw(tableData.value)).then((response) => {
        if (response.status === 200) {
          // if (customer.isAlive == true) {
          ElMessage({
            message: 'Agenzia aggiornata con successo!',
            type: 'success',
          });
          //route.push('/partner');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const updArea = async (agency) => {
      let response = {};
      try {
        response = await agencyAPI.updateAgency(agency);

        return response;
      } catch {
        console.log('error');
      }
    };


      let areaExisisting = ref([]);
      let areaExisistingResponse = ref([]);
      let areaAddNew = ref([]);
      const loadAreas = async () => {
      try {
        const resp = await agencyAPI.getAreas();
        areaExisistingResponse.value= resp.data;

        resp.data.forEach((area) => {

            let tableRow = {
              value: area.id,
              label: `${area.area}`,
            };
            areaExisisting.value.push(tableRow);

        });
      } catch (e) {
        console.log('errore load area: ', e);
      }
    };
    loadAreas();

    
    const handleSelectArea = (newValue) => {
      
      const selectedArea = areaExisistingResponse.value.find(
        (hq) => hq.id === newValue[0]
      );

       if (selectedArea) {
        areaAddNew.value.push(selectedArea);
      } 
    };


    const areasNew = reactive([
      {
        area: '',
      },
    ]);

    const rimuoviArea = (e, counter) => {
      areasNew.splice(counter, 1);
      e.preventDefault();
    };

    const aggiungiArea = (e) => {
      areasNew.push({
        area: '',
      });
      e.preventDefault();
    };

    return {
      tableData,
      update,
      updArea,
      rimuoviArea,
      aggiungiArea,
      areasNew,
      loadAreas,
areaExisistingResponse,
 areaExisisting,
 handleSelectArea,
 areaAddNew
    };
  },

  methods: {
    
    onSubmitArea() {
      this.tableData.areas = [
        ...this.tableData.areas,
        ...this.areaAddNew,
      ];

      this.updArea(toRaw(this.tableData)).then((response) => {
        if (response.status === 200) {
          this.$router.push('/agency');

          ElMessage({
            message: 'Modifica avvenuta con successo!',
            type: 'success',
          });
        } else {
          ElMessage({
            message: 'Errore inserimento Dipendeti!',
            type: 'error',
          });
          console.log('errore aggiunta dipendenti');
        }
      });
    },
  },
};
</script>

<style>
.el-card {
  margin-top: 6px;
}

.el-row {
  margin-bottom: -10px;
}

.salva {
  margin-top: -40px;
  margin-bottom: 10px;
}

.collapse-title {
  font-size: 18px;
  font-weight: bold;
}
</style>
