<template>
    <h1>Booking in conferma</h1>
    <FilterTableBookingComponent
        :servizi="serviziBooking"
        @idChanged="(newValue) =>
        (idFilterInConferma = newValue)"
        @nomeChanged="(newValue) =>
        (nomeFilterInConferma = newValue)"
        @targaChanged="(newValue) =>
        (targaFilterInConferma = newValue)"
        @servizioChanged="(newValue) =>
        (servizioFilterInConferma = newValue)"
        @dataChanged="(newValue) =>
        (dataFilterInConferma = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilterInConferma">
    </FilterTableBookingComponent>
    <el-table :data="homeTableDataBookingInConferma" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="N° booking">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="ora" label="Data" />
        <el-table-column prop="customer" label="Cliente" />
        <el-table-column prop="auto" label="Auto"  />
        <el-table-column prop="targa" label="Targa"  />
        <el-table-column prop="tag" label="Stato" filter-placement="bottom-end">

            <template #default="scope">
                <div v-if="scope.row.tag== 'Non ancora accettato'">
                    <el-tag :width="150" :type="scope.row.tag == 'Non ancora accettato' ? 'error' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
                <div v-else-if="scope.row.tag== 'Accettato dal cliente'">
                    <el-tag :width="150" :type="scope.row.tag == 'Accettato dal cliente' ? 'success' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="sede" label="Sede" />
        <el-table-column prop=typeBooking label="Servizio"  />
        <el-table-column prop=partner label="Partner" sortable />
        <el-table-column prop=driver label="Driver" />

        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/booking/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeBookingInConferma"
        :page-size="pageSize"
        :total="homeTableBookingInConferma.length">
    </el-pagination>
    </div>

    <h1>Booking attivi</h1>
    <FilterTableBookingComponent
        :servizi="serviziBooking"
        @idChanged="(newValue) =>
        (idFilterAttivi = newValue)"
        @nomeChanged="(newValue) =>
        (nomeFilterAttivi = newValue)"
        @targaChanged="(newValue) =>
        (targaFilterAttivi = newValue)"
        @servizioChanged="(newValue) =>
        (servizioFilterAttivi = newValue)"
        @dataChanged="(newValue) =>
        (dataFilterAttivi = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilterAttivi">
    </FilterTableBookingComponent>
    <el-table :data="homeTableDataBookingAttivi" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="N° booking">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="ora" label="Data" />
        <el-table-column prop="customer" label="Cliente" />
        <el-table-column prop="auto" label="Auto"  />
        <el-table-column prop="targa" label="Targa"  />
        <el-table-column prop="tag" label="Stato" filter-placement="bottom-end">

            <template #default="scope">
                <div v-if="scope.row.tag== 'Non ancora accettato'">
                    <el-tag :width="150" :type="scope.row.tag == 'Non ancora accettato' ? 'error' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
                <div v-else-if="scope.row.tag== 'Accettato dal cliente'">
                    <el-tag :width="150" :type="scope.row.tag == 'Accettato dal cliente' ? 'success' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="sede" label="Sede" />
        <el-table-column prop=typeBooking label="Servizio"  />
        <el-table-column prop=partner label="Partner" sortable />
        <el-table-column prop=driver label="Driver" />

        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/booking/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeBookingAttivi"
        :page-size="pageSize"
        :total="homeTableBookingAttivi.length">
    </el-pagination>
    </div>

    <h1>Booking futuri</h1>
    <FilterTableBookingComponent
        :servizi="serviziBooking"
        @idChanged="(newValue) =>
        (idFilterFuturi = newValue)"
        @nomeChanged="(newValue) =>
        (nomeFilterFuturi = newValue)"
        @targaChanged="(newValue) =>
        (targaFilterFuturi = newValue)"
        @servizioChanged="(newValue) =>
        (servizioFilterFuturi = newValue)"
        @dataChanged="(newValue) =>
        (dataFilterFuturi = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilterFuturi">
    </FilterTableBookingComponent>
    <el-table :data="homeTableDataBookingFuturi" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="N° booking">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="ora" label="Data" />
        <el-table-column prop="customer" label="Cliente" />
        <el-table-column prop="auto" label="Auto"  />
        <el-table-column prop="targa" label="Targa"  />
        <el-table-column prop="tag" label="Stato" filter-placement="bottom-end">

            <template #default="scope">
                <div v-if="scope.row.tag== 'Non ancora accettato'">
                    <el-tag :width="150" :type="scope.row.tag == 'Non ancora accettato' ? 'error' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
                <div v-else-if="scope.row.tag== 'Accettato dal cliente'">
                    <el-tag :width="150" :type="scope.row.tag == 'Accettato dal cliente' ? 'success' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="sede" label="Sede" />
        <el-table-column prop=typeBooking label="Servizio"  />
        <el-table-column prop=partner label="Partner" sortable />
        <el-table-column prop=driver label="Driver" />

        <!--<el-table-column prop="type" label="Aggiungi lavori">
            <template #default="scope">
                <router-link :to="{ path: `/tasks/${scope.row.id}` }" class="routerLink">
                    <el-button size="small" v-if="scope.row.type==1 || scope.row.type==2 || scope.row.type==4 || scope.row.type==5 || scope.row.type==6 || scope.row.type==7">
                        Aggiungi lavori
                    </el-button>
                </router-link>
            </template>
        </el-table-column>

        <el-table-column prop="newDate" label="Modifica data/ora" >
            <template #default="scope">
                <router-link :to="{ path: `/update-date/${scope.row.id}` }" class="routerLink">
                    <el-button size="small" :disabled="!formatDateGone(scope.row.dateGone, scope.row.isGone) || !formatDateReturn(scope.row.dateReturn, scope.row.isReturn)">
                        Modifica data/ora
                    </el-button>
                </router-link>
            </template>
        </el-table-column> -->
        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/booking/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeBookingFuturi"
        :page-size="pageSize"
        :total="homeTableBookingFuturi.length">
    </el-pagination>
    </div>

    <h1>Booking passati</h1>
    <FilterTableBookingComponent
        :servizi="serviziBooking"
        @idChanged="(newValue) =>
        (idFilterPassati = newValue)"
        @nomeChanged="(newValue) =>
        (nomeFilterPassati = newValue)"
        @targaChanged="(newValue) =>
        (targaFilterPassati = newValue)"
        @servizioChanged="(newValue) =>
        (servizioFilterPassati = newValue)"
        @dataChanged="(newValue) =>
        (dataFilterPassati = newValue)"
        @resetClick="resetFilter"
        :nome="nomeFilterPassati">
    </FilterTableBookingComponent>
    <el-table :data="homeTableDataBookingPassati" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="N° booking">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="ora" label="Data" />
        <el-table-column prop="customer" label="Cliente" />
        <el-table-column prop="auto" label="Auto"  />
        <el-table-column prop="targa" label="Targa"  />
        <el-table-column prop="tag" label="Stato" filter-placement="bottom-end">

            <template #default="scope">
                <div v-if="scope.row.tag== 'Non ancora accettato'">
                    <el-tag :width="150" :type="scope.row.tag == 'Non ancora accettato' ? 'error' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
                <div v-else-if="scope.row.tag== 'Accettato dal cliente'">
                    <el-tag :width="150" :type="scope.row.tag == 'Accettato dal cliente' ? 'success' : ''">
                        {{ scope.row.tag }}
                    </el-tag>
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="sede" label="Sede" />
        <el-table-column prop=typeBooking label="Servizio"  />
        <el-table-column prop=partner label="Partner" sortable />
        <el-table-column prop=driver label="Driver" />

        <!--<el-table-column prop="type" label="Aggiungi lavori">
            <template #default="scope">
                <router-link :to="{ path: `/tasks/${scope.row.id}` }" class="routerLink">
                    <el-button size="small" v-if="scope.row.type==1 || scope.row.type==2 || scope.row.type==4 || scope.row.type==5 || scope.row.type==6 || scope.row.type==7">
                        Aggiungi lavori
                    </el-button>
                </router-link>
            </template>
        </el-table-column>

        <el-table-column prop="newDate" label="Modifica data/ora" >
            <template #default="scope">
                <router-link :to="{ path: `/update-date/${scope.row.id}` }" class="routerLink">
                    <el-button size="small" :disabled="!formatDateGone(scope.row.dateGone, scope.row.isGone) || !formatDateReturn(scope.row.dateReturn, scope.row.isReturn)">
                        Modifica data/ora
                    </el-button>
                </router-link>
            </template>
        </el-table-column> -->
        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/booking/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeBookingPassati"
        :page-size="pageSize"
        :total="homeTableBookingPassati.length">
    </el-pagination>
    </div>
</template>

<script>
import { ref, computed } from "vue";
const dayjs = require("dayjs");
import bookingAPI from '../../services/bookingAPI.js';
import FilterTableBookingComponent from "@/components/FilterTableBookingComponent.vue";
import { useRoute } from 'vue-router';
export default {
    data() {
        return {
            pageBookingInConferma: 1,
            pageBookingAttivi: 1,
            pageBookingFuturi: 1,
            pageBookingPassati: 1,
            pageSize: 7
        }
    },
    setup() {
        const route = useRoute();
        const state = (state) => {
            switch (state) {
                case 'before':
                state = 'Non ancora accettato';
                break;
                case 'accepted':
                state = 'Accettato dal cliente';
                break;
                case 'startAPickup':
                state = 'Driver in viaggio';
                break;
                case 'driverScanJollyAPickup':
                state = 'Jolly e driver presso la sede';
                break;
                case 'customerAPickup':
                state = 'Driver arrivato dal cliente';
                break;
                case 'photoAPickup':
                state = 'Il driver ha fatto le foto';
                break;
                case 'scanAPickup':
                state = "Il driver ha ritirato l'auto";
                break;
                case 'partnerAPickup':
                state = 'Driver arrivato in concessionaria';
                break;

                case 'startRPickup':
                state = 'Driver in viaggio';
                break;
                case 'partnerRPickup':
                state = 'Driver arrivato in concessionaria';
                break;
                case 'customerRPickup':
                state = 'Driver arrivato dal cliente';
                break;
                case 'photoRPickup':
                state = 'Il driver ha fatto le foto';
                break;
                case 'scanRPickup':
                state = "Il driver ha consegnato l'auto";
                break;
                case 'driverScanJollyRPickup':
                state = 'Jolly presso il cliente';
                break;

                case 'startAPickupLoaner':
                state = 'Driver in arrivo';
                break;
                case 'partnerAPickupLoaner':
                state = 'Il Driver ha ritirato auto di cortesia';
                break;
                case 'customerAPickupLoaner':
                state = 'Driver arrivato dal cliente';
                break;
                case 'photoAPickupLoaner':
                state = 'Il driver ha fatto le foto';
                break;
                case 'scanAPickupLoaner':
                state = "Il cliente ha ritirato l'auto di cortesia";
                break;
                case 'partnersecondAPickupLoaner':
                state = 'Driver arrivato in concessionaria';
                break;

                case 'startRPickupLoaner':
                state = 'Driver in arrivo';
                break;
                case 'partnerRPickupLoaner':
                state = 'Il Driver ha ritirato auto di cortesia';
                break;
                case 'customerRPickupLoaner':
                state = 'Driver arrivato dal cliente';
                break;
                case 'photoRPickupLoaner':
                state = 'Il driver ha fatto le foto';
                break;
                case 'scanRPickupLoaner':
                state = "Il cliente ha ritirato l'auto di cortesia";
                break;

                case 'startADelivery':
                state = 'Driver in viaggio';
                break;
                case 'partnerADelivery':
                state = "Il driver ha ritirato l'auto";
                break;
                case 'photofirstADelivery':
                state = 'Il driver ha fatto le foto';
                break;
                case 'customerADelivery':
                state = 'Driver arrivato al punto di consegna';
                break;
                case 'photosecondADelivery':
                state = "Il driver ha consegnato l'auto";
                break;
                case 'driverScanJollyADelivery':
                state = 'Il jolly ha accompagnato il driver ';
                break;

                case 'startRDelivery':
                state = 'Driver in viaggio';
                break;
                case 'customerRDelivery':
                state = "Il driver ha ritirato l'auto";
                break;
                case 'photofirstRDelivery':
                state = 'Il driver ha fatto le foto';
                break;
                case 'partnerRDelivery':
                state = 'Driver arrivato al punto di consegna';
                break;
                case 'photosecondRDelivery':
                state = "Il driver ha consegnato l'auto";
                break;
                case 'driverScanJollyRDelivery':
                state = 'Il jolly ha accompagnato il driver ';
                break;

                case 'startAEscort':
                state = 'Driver in viaggio';
                break;
                case 'customerAEscort':
                state = 'Driver arrivato al punto di incontro con il cliente';
                break;
                case 'scanfirstAEscort':
                state = 'Il driver ha fatto salire il cliente in auto';
                break;
                case 'scanssecondAEscort':
                state = 'Il driver ha fatto portato il cliente al punto';
                break;

                case 'startREscort':
                state = 'Driver in viaggio';
                break;
                case 'customerREscort':
                state = 'Driver arrivato al punto di incontro con il cliente';
                break;
                case 'scanfirstREscort':
                state = 'Il driver ha fatto salire il cliente in auto';
                break;
                case 'scansecondREscort':
                state = 'Il driver ha fatto portato il cliente al punto';
                break;

                case 'end':
                state = 'Concluso';
                break;
            }
            return state;
        };

        const serviziBooking = ref([
            {
                value: 'Pickup & Delivery',
                label: 'Pickup & Delivery',
            },
            {
                value: 'Accompagna un cliente',
                label: 'Accompagna un cliente',
            },
            {
                value: "Consegna un'auto",
                label: "Consegna un'auto",
            },
            {
                value: "Accompagna un cliente e lavori",
                label: "Accompagna un cliente e lavori",
            },
            {
                value: 'Accompagna un cliente con auto di cortesia',
                label: 'Accompagna un cliente con auto di cortesia',
            },
            {
                value: 'Accompagna un cliente con auto partner e lavori',
                label: 'Accompagna un cliente con auto partner e lavori',
            },
            {
                value: 'Pickup & Delivery con auto partner',
                label: 'Pickup & Delivery con auto partner',
            }
        ]);

        const typeBooking = (type) => {
            switch (type) {
                case 1:
                    return "Pickup & Delivery"
                case 2:
                    return "Accompagna un cliente"
                case 3:
                    return "Consegna un'auto"
                case 4:
                    return "Accompagna un cliente e lavori"
                case 5:
                    return "Accompagna un cliente con auto partner"
                case 6:
                    return "Accompagna un cliente con auto partner e lavori"
                case 7:
                    return "Pickup & Delivery con auto partner"
            }

        }
        const areaFilter = route.query.area || '';
        const loadBooking = async (id) => {
            try {

                let singleBooking = {
                    dateGone: "",
                    dateReturn: "",
                    typeOfBooking: 0,
                    isGone: false,
                    isReturn: false,
                    is_alive: false,
                    driver: {
                        driverGone: "",
                        driverReturn: ""
                    },
                    current_driver: "",
                    date: {
                        current_date: "",
                        newDate: ""
                    }
                }

                const response = await bookingAPI.getBookingById(id);
                const booking = response.data;

                if (booking.pickup_bookings && booking.pickup_bookings.length > 0) {
                    for (let i = 0; i < booking.pickup_bookings.length; i++) {
                        const currentBooking = booking.pickup_bookings[i];
                        booking.loaner == true ? singleBooking.typeOfBooking = 7 : singleBooking.typeOfBooking = 1;

                        singleBooking.is_alive = booking.pickup_bookings[i].driver_pickup.is_alive;
                        if (currentBooking.type_booking === "gone") {
                            singleBooking.dateGone = dayjs(currentBooking.date_partner).format("DD/MM/YY HH:mm");
                            singleBooking.isGone = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverGone = "" : singleBooking.driver.driverGone = booking.pickup_bookings[i].driver_pickup.name + " " + booking.pickup_bookings[i].driver_pickup.surname;

                        } else {
                            singleBooking.dateReturn = dayjs(currentBooking.date_partner).format("DD/MM/YY HH:mm");
                            singleBooking.isReturn = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverReturn = "" : singleBooking.driver.driverReturn = booking.pickup_bookings[i].driver_pickup.name + " " + booking.pickup_bookings[i].driver_pickup.surname;
                        }
                    }
                }
                if (booking.escort_bookings && booking.escort_bookings.length > 0) {
                    for (let i = 0; i < booking.escort_bookings.length; i++) {

                        const currentBooking = booking.escort_bookings[i];
                        singleBooking.is_alive = booking.escort_bookings[i].driver_escort.is_alive;
                        
                        if (currentBooking.tasks == true && booking.loaner == true) {
                            singleBooking.typeOfBooking = 6;
                        } else if (currentBooking.tasks == false && booking.loaner == true) {
                            singleBooking.typeOfBooking = 5;
                        } else if (currentBooking.tasks == true && booking.loaner == false) {
                            singleBooking.typeOfBooking = 4;
                        } else {
                            singleBooking.typeOfBooking = 2;
                        }

                        if (currentBooking.type_booking === "gone") {
                            singleBooking.dateGone = dayjs(currentBooking.date_partner).format("DD/MM/YY HH:mm");
                            singleBooking.isGone = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverGone = "" : singleBooking.driver.driverGone = booking.escort_bookings[i].driver_escort.name + " " + booking.escort_bookings[i].driver_escort.surname;

                        } else {
                            singleBooking.dateReturn = dayjs(currentBooking.date_partner).format("DD/MM/YY HH:mm");
                            singleBooking.isReturn = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverReturn = "" : singleBooking.driver.driverReturn = booking.escort_bookings[i].driver_escort.name + " " + booking.escort_bookings[i].driver_escort.surname;
                        }
                    }
                }
                if (booking.delivery_bookings && booking.delivery_bookings.length > 0) {
                    for (let i = 0; i < booking.delivery_bookings.length; i++) {
                        const currentBooking = booking.delivery_bookings[i];
                        singleBooking.typeOfBooking = 3;
                        singleBooking.is_alive = booking.delivery_bookings[i].driver_delivery.is_alive;
                        
                        if (currentBooking.type_booking === "gone") {
                            singleBooking.dateGone = dayjs(currentBooking.date_point).format("DD/MM/YY HH:mm");
                            singleBooking.isGone = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverGone = "" : singleBooking.driver.driverGone = booking.delivery_bookings[i].driver_delivery.name + " " + booking.delivery_bookings[i].driver_delivery.surname;
                        } else {
                            singleBooking.dateReturn = dayjs(currentBooking.date_point).format("DD/MM/YY HH:mm");
                            singleBooking.isReturn = true;
                            singleBooking.is_alive == true ? singleBooking.driver.driverReturn = "" : singleBooking.driver.driverReturn = booking.delivery_bookings[i].driver_delivery.name + " " + booking.delivery_bookings[i].driver_delivery.surname;
                        }
                    }
                }

                if (singleBooking.dateGone != "" && singleBooking.dateReturn != "") {
                    singleBooking.date.current_date = `A: ${singleBooking.dateGone} R: ${singleBooking.dateReturn}`
                } else {
                    singleBooking.date.current_date = singleBooking.dateGone != "" ? `A: ${singleBooking.dateGone}` : `R: ${singleBooking.dateReturn}`
                }

                if(singleBooking.is_alive == false) {
                    if (singleBooking.driver.driverGone != "" && singleBooking.driver.driverReturn != "") {
                        singleBooking.current_driver = `A: ${singleBooking.driver.driverGone} R: ${singleBooking.driver.driverReturn}`
                    } else {
                        singleBooking.current_driver = singleBooking.driver.driverGone != "" ? `A: ${singleBooking.driver.driverGone}` : `R: ${singleBooking.driver.driverReturn}`
                    }
                }

                return singleBooking;

            } catch (e) {
                console.log(e);
            }
        }

        //#region BOOKING IN CONFERMA 
        let idFilterInConferma = ref("");
        let nomeFilterInConferma = ref("");
        let targaFilterInConferma = ref("");
        let servizioFilterInConferma = ref("");
        let dataFilterInConferma = ref("");

        let tableDataBookingInConferma = ref([{
                id: "",
                sede: "",
                customer: "",
                tag: "",
                auto: "",
                targa: "",
                ora: "",
                newDate: "",
                tasks: [],
                dateGone: "",
                dateReturn: "",
                isGone: false,
                isReturn: false,
                type: 0, //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
                headquarterId: "",
                dataBooking: "",
                partner: "",
                driver:""
        }]);
        tableDataBookingInConferma.value = [];

        //#region BOOKING ATTIVI 
        let idFilterAttivi = ref("");
        let nomeFilterAttivi = ref("");
        let targaFilterAttivi = ref("");
        let servizioFilterAttivi = ref("");
        let dataFilterAttivi = ref("");

        let tableDataBookingAttivi = ref([{
                id: "",
                sede: "",
                customer: "",
                tag: "",
                auto: "",
                targa: "",
                ora: "",
                newDate: "",
                tasks: [],
                dateGone: "",
                dateReturn: "",
                isGone: false,
                isReturn: false,
                type: 0, //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
                headquarterId: "",
                dataBooking: "",
                partner: "",
                driver: "",
        }]);
    
        const loadAllBookingsAttivi = async () => {
            try {
                    
                tableDataBookingAttivi.value=[];
                const today = dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss');
                const tomorrow = dayjs(today).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

                const response = await bookingAPI.getBookingAttiviAll(today, tomorrow, areaFilter);
                response.data.forEach(async (booking) => {
                    const tasks = [];
                    const data = await loadBooking(booking.id);

                    let tableRow = {
                        id: booking.id,
                        sede: booking.headquarter.address + ' ' + booking.headquarter.street_number + ', ' + booking.headquarter.city,
                        customer: booking.customer.name + ' ' + booking.customer.surname,
                        tag: state(booking.state),
                        auto: booking.car != null ? booking.car.marca + ', ' + booking.car.modello : "-",
                        targa: booking.car != null ? booking.car.targa : "-",
                        ora: data.date.current_date,
                        newDate: data.date.newDate,
                        tasks: tasks,
                        type: data.typeOfBooking,
                        isGone: data.isGone,
                        isReturn: data.isReturn,
                        dateGone: data.dateGone,
                        dateReturn: data.dateReturn,
                        typeBooking: typeBooking(data.typeOfBooking),
                        headquarterId: booking.headquarter.id,
                        dataBooking: booking.data,
                        partner: booking.headquarter.partner.company_name,
                        driver: data.current_driver

                    };
                    tableRow.stato = state(tableRow.stato);

                    if(data.is_alive == true) {
                        tableDataBookingInConferma.value.push(tableRow);
                    } else {
                        tableDataBookingAttivi.value.push(tableRow);
                    }

                });
            } catch (e) {
                console.log(e);
            }
        }

        loadAllBookingsAttivi();

        const homeTableBookingAttivi = computed(function () {
            return tableDataBookingAttivi.value.filter((row) =>
                row.id.toLowerCase().includes(idFilterAttivi.value.toLowerCase()) &&
                row.customer.toLowerCase().includes(nomeFilterAttivi.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilterAttivi.value.toLowerCase()) &&
                row.typeBooking.includes(servizioFilterAttivi.value[0] == undefined ? '' : servizioFilterAttivi.value[0]) &&
                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilterAttivi.value == '' ? '' : dayjs(new Date(dataFilterAttivi.value)).format("YYYY-MM-DD"))
            );
        });
        //#endregion BOOKING ATTIVI

        //#region BOOKING FUTURI
        let idFilterFuturi = ref("");
        let nomeFilterFuturi = ref("");
        let targaFilterFuturi = ref("");
        let servizioFilterFuturi = ref("");
        let dataFilterFuturi = ref("");

        let tableDataBookingFuturi = ref([{
                id: "",
                sede: "",
                customer: "",
                tag: "",
                auto: "",
                targa: "",
                ora: "",
                newDate: "",
                tasks: [],
                dateGone: "",
                dateReturn: "",
                isGone: false,
                isReturn: false,
                type: 0, //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
                headquarterId: "",
                dataBooking: "",
                partner: "",
                driver: ""
        }]);

        const loadAllBookingsFuturi = async () => {
            try {

                tableDataBookingFuturi.value = [];
                const today = dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm');
                const tomorrow = dayjs(today).add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

                const response = await bookingAPI.getBookingFuturiAll(tomorrow, areaFilter);
               
                response.data.forEach(async (booking) => {
                    const tasks = [];
                    const data = await loadBooking(booking.id);

                    let tableRow = {
                        id: booking.id,
                        sede: booking.headquarter.address + ' ' + booking.headquarter.street_number + ', ' + booking.headquarter.city,
                        customer: booking.customer.name + ' ' + booking.customer.surname,
                        tag: state(booking.state),
                        auto: booking.car != null ? booking.car.marca + ', ' + booking.car.modello : "-",
                        targa: booking.car != null ? booking.car.targa : "-",
                        ora: data.date.current_date,
                        newDate: data.date.newDate,
                        tasks: tasks,
                        type: data.typeOfBooking,
                        isGone: data.isGone,
                        isReturn: data.isReturn,
                        dateGone: data.dateGone,
                        dateReturn: data.dateReturn,
                        typeBooking:typeBooking(data.typeOfBooking),
                        headquarterId: booking.headquarter.id,
                        dataBooking: booking.data,
                        partner: booking.headquarter.partner.company_name,
                        driver: data.current_driver
                        //orario_arrivo: booking.pickup_bookings[0].type=="gone"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
                        //orario_rilascio: booking.pickup_bookings[0].type=="return"? dayjs( booking.pickup_bookings[0]).format('DD/MM/YYYY HH:mm') : dayjs( booking.pickup_bookings[1]).format('DD/MM/YYYY HH:mm'),
                    };
                    tableRow.stato = state(tableRow.stato);
                    if(data.is_alive == true) {
                        tableDataBookingInConferma.value.push(tableRow);
                    } else {
                        tableDataBookingFuturi.value.push(tableRow);
                    }

                });
            } catch (e) {
                console.log(e);
            }
        }

        loadAllBookingsFuturi();

        const homeTableBookingFuturi = computed(function () {
                return tableDataBookingFuturi.value.filter((row) =>
                row.id.toLowerCase().includes(idFilterFuturi.value.toLowerCase()) &&
                row.customer.toLowerCase().includes(nomeFilterFuturi.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilterFuturi.value.toLowerCase()) &&
                row.typeBooking.includes(servizioFilterFuturi.value[0] == undefined ? '' : servizioFilterFuturi.value[0]) &&
                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilterFuturi.value == '' ? '' : dayjs(new Date(dataFilterFuturi.value)).format("YYYY-MM-DD"))
            );
        });
        //#endregion BOOKING FUTURI

        //#region BOOKING PASSATI
        let idFilterPassati = ref("");
        let nomeFilterPassati = ref("");
        let targaFilterPassati = ref("");
        let servizioFilterPassati = ref("");
        let dataFilterPassati = ref("");

        let tableDataBookingPassati = ref([{
                id: "",
                sede: "",
                customer: "",
                tag: "",
                auto: "",
                targa: "",
                ora: "",
                newDate: "",
                tasks: [],
                dateGone: "",
                dateReturn: "",
                isGone: false,
                isReturn: false,
                type: 0, //0: default, 1:pickup, 2:escort, 3:delivery, 4:escort+tagliando, 5:escort+auto partner, 6: escort+tagliando+auto partner, 7:pickup+ auto cortesia
                headquarterId: "",
                dataBooking: "",
                partner: "",
                driver: ""
        }]);

        const loadAllBookingsPassati = async () => {
            try {

                tableDataBookingPassati.value = [];
                const today = dayjs(new Date()).hour(0).minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm');

                const response = await bookingAPI.getBookingPassatiAll(today, areaFilter);
                response.data.forEach(async (booking) => {
                    const tasks = [];
                    const data = await loadBooking(booking.id);

                    let tableRow = {
                        id: booking.id,
                        sede: booking.headquarter.address + ' ' + booking.headquarter.street_number + ', ' + booking.headquarter.city,
                        customer: booking.customer.name + ' ' + booking.customer.surname,
                        tag: state(booking.state),
                        auto: booking.car != null ? booking.car.marca + ', ' + booking.car.modello : "-",
                        targa: booking.car != null ? booking.car.targa : "-",
                        ora: data.date.current_date,
                        newDate: data.date.newDate,
                        tasks: tasks,
                        type: data.typeOfBooking,
                        isGone: data.isGone,
                        isReturn: data.isReturn,
                        dateGone: data.dateGone,
                        dateReturn: data.dateReturn,
                        typeBooking:typeBooking(data.typeOfBooking),
                        headquarterId: booking.headquarter.id,
                        stato: booking.state,
                        dataBooking: booking.data,
                        partner: booking.headquarter.partner.company_name,
                        driver: data.current_driver
                    };

                    tableRow.stato = state(tableRow.stato);
                    if(data.is_alive == true) {
                        tableDataBookingInConferma.value.push(tableRow);
                    } else {
                        tableDataBookingPassati.value.push(tableRow);
                    }

                });
            } catch (e) {
                console.log(e);
            }
        }

        loadAllBookingsPassati();

        const homeTableBookingPassati = computed(function () {
            return tableDataBookingPassati.value.filter((row) =>
                row.id.toLowerCase().includes(idFilterPassati.value.toLowerCase()) &&
                row.customer.toLowerCase().includes(nomeFilterPassati.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilterPassati.value.toLowerCase()) &&
                row.typeBooking.includes(servizioFilterPassati.value[0] == undefined ? '' : servizioFilterPassati.value[0]) &&
                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilterPassati.value == '' ? '' : dayjs(new Date(dataFilterPassati.value)).format("YYYY-MM-DD"))
            );
        });
        //#endregion BOOKING PASSATI

        const resetFilter = async () => {
            window.location.reload();
        }

        const homeTableBookingInConferma = computed(function () {
            return tableDataBookingInConferma.value.filter((row) =>
                row.id.toLowerCase().includes(idFilterInConferma.value.toLowerCase()) &&
                row.customer.toLowerCase().includes(nomeFilterInConferma.value.toLowerCase()) &&
                row.targa.toLowerCase().includes(targaFilterInConferma.value.toLowerCase()) &&
                row.typeBooking.includes(servizioFilterInConferma.value[0] == undefined ? '' : servizioFilterInConferma.value[0]) &&
                dayjs(new Date(row.dataBooking)).format("YYYY-MM-DD").includes(dataFilterInConferma.value == '' ? '' : dayjs(new Date(dataFilterInConferma.value)).format("YYYY-MM-DD"))
            );
        });

        return {
            tableDataBookingInConferma,
            homeTableBookingInConferma,
            //loadAllBookingsInConferma,
            tableDataBookingAttivi,
            homeTableBookingAttivi,
            loadAllBookingsAttivi,
            tableDataBookingFuturi,
            homeTableBookingFuturi,
            loadAllBookingsFuturi,
            tableDataBookingPassati,
            homeTableBookingPassati,
            loadAllBookingsPassati,
            loadBooking,
            state,
            serviziBooking,
            typeBooking,
            idFilterInConferma,
            nomeFilterInConferma,
            targaFilterInConferma,
            servizioFilterInConferma,
            dataFilterInConferma,
            idFilterAttivi,
            nomeFilterAttivi,
            targaFilterAttivi,
            servizioFilterAttivi,
            dataFilterAttivi,
            idFilterFuturi,
            nomeFilterFuturi,
            targaFilterFuturi,
            servizioFilterFuturi,
            dataFilterFuturi,
            idFilterPassati,
            nomeFilterPassati,
            targaFilterPassati,
            servizioFilterPassati,
            dataFilterPassati,
            resetFilter
        }
    },
    components: { FilterTableBookingComponent },
    computed: {
        homeTableDataBookingInConferma() {
            if (!this.homeTableBookingInConferma || this.homeTableBookingInConferma.length === 0) return [];
            return this.homeTableBookingInConferma.slice(this.pageSize * this.pageBookingInConferma - this.pageSize, this.pageSize * this.pageBookingInConferma)
        },
        homeTableDataBookingAttivi() {
            if (!this.homeTableBookingAttivi || this.homeTableBookingAttivi.length === 0) return [];
            return this.homeTableBookingAttivi.slice(this.pageSize * this.pageBookingAttivi - this.pageSize, this.pageSize * this.pageBookingAttivi)
        },
        homeTableDataBookingFuturi() {
            if (!this.homeTableBookingFuturi || this.homeTableBookingFuturi.length === 0) return [];
            return this.homeTableBookingFuturi.slice(this.pageSize * this.pageBookingFuturi - this.pageSize, this.pageSize * this.pageBookingFuturi)
        },
        homeTableDataBookingPassati() {
            if (!this.homeTableBookingPassati || this.homeTableBookingPassati.length === 0) return [];
            return this.homeTableBookingPassati.slice(this.pageSize * this.pageBookingPassati - this.pageSize, this.pageSize * this.pageBookingPassati)
        }
    },
    methods: {
        handleCurrentChangeBookingInConferma(val) {
            this.pageBookingInConferma = val;
        },
        handleCurrentChangeBookingAttivi(val) {
            this.pageBookingAttivi = val;
        },
        handleCurrentChangeBookingFuturi(val) {
            this.pageBookingFuturi = val;
        },
        handleCurrentChangeBookingPassati(val) {
            this.pageBookingPassati = val;
        },
    },
};
</script>

