<template >
    <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <div>
            <el-text style="font-size:medium; font-weight:bold">Filtra</el-text>
             <el-icon><Filter /></el-icon>
            </div>
          </template>
          <div class="flex gap-20 mb-4" style="margin-top:20px">
            <span>Nome cliente</span>
            <el-input v-model="nome" style="width: 10%; margin-left:10px" :placeholder="placeholder" />

            <span>Cognome cliente</span>
            <el-input v-model="cognome" style="width: 10%; margin-left:10px" :placeholder="placeholder" />


            <span style="margin-left:30px">Targa</span>
            <el-input v-model="targa" style="width:  10%; margin-left:10px" :placeholder="placeholder" />
            
            <span style="margin-left:30px">Data booking</span>
            <el-date-picker v-model="data" type="date" :size="size" style="width:  10%; margin-left:10px"/>
            
            
            <el-button style="margin-left: 10px"  @click="handleButtonClick"  type="primary" >Reset filtri</el-button>
          </div>
        </el-collapse-item>
    </el-collapse>
  </template>
  
  <script>
  export default {
  props: {
    servizi: {
      required: true
    }
  
  },
  data() {
    return {
      nome: '',
      cognome:'',
      targa: '',
      data: ''
    };
  },
  watch: {
    nome(val) {
      this.$emit('nomeChanged', val);
    },
    cognome(val) {
      this.$emit('cognomeChanged', val);
    },
    targa(val) {
      this.$emit('targaChanged', val);
    },
    data(val) {
      this.$emit('dataChanged', val);
    },
  },
  methods: {
    handleButtonClick() {
        this.$emit('resetClick');
    }
  }
  };
  </script>
  
  <style>
  .vuedatepicker.dropdown-menu {
  z-index: 9999 !important;
  }
  .filter-title el-text {
  margin-right: 5px;
  }
  </style>