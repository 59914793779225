<template>
<h1>Contabilità</h1>
<el-row>
    <el-col :sm="sm">
        <el-row class="cod">
            <el-button color="#2c3e50" @click="
            update();
            fatture();
          ">COD 1</el-button>
            &nbsp;
            <el-text class="text" v-if="value1" tag="p" size="small">COD1: ABBONAMENTO MENSILE PARTNER</el-text>
        </el-row>

        <el-row class="cod">
            <el-button color="#2c3e50" @click="
            update_partner();
            minuti();
          ">COD 2</el-button>
            <el-text class="text" v-if="value1_cod2" tag="p" size="small">COD2: VENDITA SU FINANZIAMENTO</el-text>
        </el-row>

        <el-row class="cod">
            <el-button color="#2c3e50" @click="
            update();
            booking();
          ">COD 3</el-button>
            <el-text class="text" v-if="value1" tag="p" size="small">COD3: RIEPILOGO BOOKING</el-text>
        </el-row>

        <el-row class="cod">
          <el-button color="#2c3e50" @click="
          update();
          booking();
        ">COD 4</el-button>
          <el-text class="text" v-if="value1" tag="p" size="small">COD4: RIEPILOGO PROVVIGIONI BOOKING</el-text>
      </el-row>

        <el-row class="cod">
            <el-button color="#2c3e50" @click="
            update();
            task();
          ">COD 5</el-button>
            <el-text class="text" v-if="value1" tag="p" size="small">COD5: GIROCONTO
            </el-text>
        </el-row>

        <el-row class="cod">
            <el-button color="#2c3e50" @click="
          update_agency();
            remuneration();
          ">COD 6</el-button>

            <el-text class="text" v-if="value1_cod6" tag="p" size="small">COD6: RETRIBUZIONI DRIVER</el-text>
        </el-row>

        <el-row class="cod">
          <el-button color="#2c3e50" @click="
          update_partner();
          valet_pay();
        ">COD 7</el-button>

          <el-text class="text" v-if="value1_cod6" tag="p" size="small">COD7: RIEPILOGO PROVVIGIONI VALET</el-text>
      </el-row>
    </el-col>
    <el-col v-if="value2" :sm="19">
        <el-input v-model="input" placeholder="Inserisci la città dell'heartquarter">
            <template #append>
                <el-button>
                    <el-icon>
                        <search />
                    </el-icon>
                </el-button>
            </template>
        </el-input>
        <el-table :data="partnerTable" :show-header="true" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)' }">
            <el-table-column prop="partner" label="ragione sociale" width="200" />
            <el-table-column prop="headquarters" class-name="sub_row" label="sedi di ogni partner">
                <template #default="scope">
                    <el-table :data="scope.row.headquarters" :show-header="false">
                        <el-table-column prop="address" label="indirizzo" width="200" />
                        <el-table-column prop="street_number" label="n. civico" width="50" />
                        <el-table-column prop="cap" label="CAP" width="100" />
                        <el-table-column prop="city" label="città" width="110" />
                        <el-table-column prop="province" label="provincia" width="50" />
                        <el-table-column width="70">
                            <template #default="scope">
                                <router-link :to="{
                      path: '/contabilita' + path_variable + `${scope.row.id}`,
                    }" class="routerLink">
                                    <el-button size="small" @click="handleClick(scope.row.id)">
                                        <el-icon>
                                            <DArrowRight />
                                        </el-icon>
                                    </el-button>
                                </router-link>
                            </template>
                        </el-table-column>

                    </el-table>
                </template>
            </el-table-column>
        </el-table>
    </el-col>

    <el-col v-else-if="value2_cod2" :sm="21">
        <el-input v-model="input" placeholder="Inserisci la ragione sociale del partner">
            <template #append>
                <el-button>
                    <el-icon>
                        <search />
                    </el-icon>
                </el-button>
            </template>
        </el-input>
        <el-table :data="partnerTable" height="100%" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)' }">
            <el-table-column prop="partner" label="ragione sociale" :span="5" />
            <el-table-column prop="mail" label="mail" :span="5" />
            <el-table-column prop="phone_number" label="telefono" :span="5" />

            <el-table-column label="Dettagli" :span="5">
                <template #default="scope">
                    <router-link :to="{ path: '/contabilita' + path_variable + `${scope.row.id}` }" class="routerLink">
                        <el-button size="small" @click="handleClick(scope.row.id)">
                            Visualizza
                        </el-button>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
    </el-col>

    <el-col v-else-if="value2_cod6" :sm="21">
        <el-input v-model="input" placeholder="Inserisci il nome dell'agenzia">
            <template #append>
                <el-button>
                    <el-icon>
                        <search />
                    </el-icon>
                </el-button>
            </template>
        </el-input>
        <el-table :data="agencyTable" height="100%" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)' }">
            <el-table-column prop="agency" label="ragione sociale" />
            <el-table-column prop="name" label="nome ref." />
            <el-table-column prop="surname" label="cognome ref." />
            <el-table-column prop="mail" label="mail" />
            <el-table-column prop="telefono" label="telefono" />

            <el-table-column label="Dettagli">
                <template #default="scope">
                    <router-link :to="{ path: '/contabilita' + path_variable + `${scope.row.id}` }" class="routerLink">
                        <el-button size="small" @click="handleClick(scope.row.id)">
                            Visualizza
                        </el-button>
                    </router-link>
                </template>
            </el-table-column>
        </el-table>
    </el-col>
</el-row>
</template>

<script>
import {
    ref,
    computed
} from 'vue';
import partnerAPI from '../../services/partnerAPI';
import agencyAPI from '../../services/agencyAPI';

export default {

    setup() {
        //codice per popolare la tabella degli Headquarter del COD1
        const input = ref('');
        const tableData = ref([]);
        const tableDataPartner = ref([]);
        const tableDataAgency = ref([]);

        const loadHeadquarter = async () => {
            try {
                const response = await partnerAPI.getHeadquarters();
                response.data.forEach((headquarter) => {
                    let tableRow = {
                        id: headquarter.id,
                        province: headquarter.province,
                        cap: headquarter.cap,
                        city: headquarter.city,
                        address: headquarter.address,
                        partner: headquarter.partner.company_name,
                    };

                    tableData.value.push(tableRow);
                });
            } catch {
                console.log('error');
            }
        };

        const loadPartner = async () => {
            try {
                const response = await partnerAPI.getHeadquartersToPartner();

                response.data.forEach((partner) => {
                    let tableRow = {
                        id: partner.id,
                        partner: partner.company_name,
                        mail: partner.mail,
                        phone_number: partner.phone_number,
                        headquarters: partner.headquarters,
                    };
                    tableDataPartner.value.push(tableRow);
                });
            } catch {
                console.log('error');
            }
        };

        const loadAgency = async () => {
            try {
                const response = await agencyAPI.getAgencies();

                response.data.forEach((agency) => {
                    let tableRow = {
                        id: agency.id,
                        agency: agency.company_name,
                        name: agency.name,
                        surname: agency.surname,
                        mail: agency.mail,
                        telefono: agency.telephone_number,
                    };
                    tableDataAgency.value.push(tableRow);
                });
            } catch {
                console.log('error');
            }
        };

        loadHeadquarter();
        loadPartner();
        loadAgency();

        const headquarterTable = computed(function () {
            return tableData.value.filter((row) =>
                row.city.toLowerCase().includes(input.value.toLowerCase())
            );
        });

        const partnerTable = computed(function () {
            return tableDataPartner.value.filter((row) =>
                row.partner.toLowerCase().includes(input.value.toLowerCase())
            );
        });

        const agencyTable = computed(function () {
            return tableDataAgency.value.filter((row) =>
                row.agency.toLowerCase().includes(input.value.toLowerCase())
            );
        });

        return {
            input,
            tableData,
            tableDataPartner,
            partnerTable,
            headquarterTable,
            agencyTable
        };
    },

    data() {
        const sm = ref(0);
        const path_variable = ref('');
        const value1 = ref(true);
        const value1_cod2 = ref(true);
        const value1_cod6 = ref(true);

        const value2 = ref(false);
        const value2_cod2 = ref(false);
        const value2_cod6 = ref(false);
        

        sm.value = 24;

        const update = () => {
            if (value1.value && !value2.value) {
                //scompare la scritta
                value1.value = false;
                value1_cod2.value = false;
                value1_cod6.value = false;

                //compare tabella
                value2.value = true;
                value2_cod2.value = false;
                value2_cod6.value = false;
                sm.value = 3;
            } else {
                //compare scritta
                value1.value = true;
                value1_cod2.value = true;
                value1_cod6.value = true;

                //scompare tabella
                value2.value = false;
                value2_cod2.value = false;
                value2_cod6.value = false;
                sm.value = 24;
            }

            return {
                value1,
                value2,
            };
        };

        const update_partner = () => {
            if (value1_cod2.value && !value2_cod2.value) {
                //scompare la scritta
                value1_cod2.value = false;
                value1_cod6.value = false;
                value1.value = false;
                //compare tabella
                value2_cod2.value = true;
                value2.value = false;
                value2_cod6.value = false;
                sm.value = 3;
            } else {
                //compare scritta
                value1_cod2.value = true;
                value1.value = true;
                value1_cod6.value = true;

                //scompare tabella
                value2_cod2.value = false;
                value2.value = false;
                value2_cod6.value = false;
                sm.value = 24;
            }

            return {
                value2_cod2,
                value1_cod2,
            };
        };

        const update_agency = () => {
            if (value1_cod6.value && !value2_cod6.value) {
                //scompare la scritta
                value1_cod6.value = false;
                value1_cod2.value = false;
                value1.value = false;

                //compare tabella
                value2_cod6.value = true;
                value2.value = false;
                value2_cod2.value = false;
                sm.value = 3;
            } else {
                //compare scritta
                value1_cod6.value = true;
                value1_cod2.value = true;
                value1.value = true;

                //scompare tabella
                value2_cod6.value = false;
                value2_cod2.value = false;
                value2.value = false;
                sm.value = 24;
            }

            return {
                value2_cod6,
                value1_cod6,
            };
        };

        const fatture = () => {
            path_variable.value = '/cod1/';
        };

        const minuti = () => {
            path_variable.value = '/cod2/';
        };

        const booking = () => {
            path_variable.value = '/cod3/';
        };

        const booking_pay = () => {
            path_variable.value = '/cod4/';
        };

        const valet_pay = () => {
            path_variable.value = '/cod7/';
        };

        const task = () => {
            path_variable.value = '/cod5/';
        };

        const remuneration = () => {
            path_variable.value = '/cod6/';
        };

        return {
            update,
            update_partner,
            update_agency,
            fatture,
            minuti,
            booking,
            task,
            remuneration,
            booking_pay,
            valet_pay,
            value1,
            value1_cod2,
            value2_cod2,
            value2,
            path_variable,
            sm,
            value1_cod6,
            value2_cod6,
            isCollapseOpen:true
        };
    }
};
</script>

<style>
.cod {
    margin-top: 20px;
    color: #5499c7;
}

.text {
    margin-left: 30px;
    text-align: center;
    color: #5499c7;
}

.el-table__header-wrapper {
    display: block !important;
}
</style>
