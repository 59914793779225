<template>
    <div :style="style" >
        <el-descriptions
        :column="3"
        :size="size"
        border
      >
        <el-descriptions-item >
          <template #label >
            <div class="cell-item"  >
              <el-icon :style="iconStyle" v-if="icon=='office-building'">
                <office-building />
              </el-icon> 

              <el-icon :style="iconStyle" v-if="icon=='PriceTag'">
                <PriceTag />
              </el-icon> 
              
             {{ name }}
             
            </div>
          </template>

          <span v-if="!loading"> {{ label  }}</span>
          <span v-else class="loading-label" > Loading...</span>
        
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </template>
  
  <script>
/*   import { ElSpinner } from 'element-plus'; */
  export default {
    components: {
    //ElSpinner,
  },
    props: {
      
      label: {
        type: String,
        required: true,
      },    
      

      loading: {
        type: Boolean,
        required: true,
        default:true
      },    


      name:{
        type:String,
        required:false,
        default:"Indirizzo geolocalizzato"
      },

      icon:{
        required:false,
        default:"office-building"
      },

      style:{
        required:false,
        default:"padding-right:47%"
      }
      
    },
   
  };
  </script>
  
  <style scoped>
  .cell-item {
    display: flex;
    align-items: center;
  }
  
  
  .cell-item el-icon {
    margin-right: 5px; 
  }
  
  .el-descriptions__label {
    padding: 4px 8px; 
    background-color: #f5f5f5;
    border-radius: 4px;
  }


  .loading-label {
    color: #81888e; /* Change the color to your desired color */
  }
  </style>
  