<template>
  <div v-if="isRouter">
    <router-link class="routerLink" :to="{ path: `${linkPath}` }">
      <el-button
        :style="style"
        @click="handleButtonClick"
        :type="type"
        :plain="plain"
        >{{ label }}</el-button
      >
    </router-link>
  </div>

  <div v-if="!isRouter && icon">
    <el-button
      v-if="ifCondition"
      :style="style"
      :type="type"
      @click="handleButtonClick"
      :icon="icon"
    ></el-button>
  </div>

  <div v-if="!isRouter && label">
    <el-button v-if="ifCondition" :type="type" @click="handleButtonClick">
        {{ label}}
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
    isRouter: {
      type: Boolean,
      required: true,
    },
    linkPath: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    ifCondition: {
      type: Boolean,
      required: true,
      default: true,
    },
    icon: {
      type: String,
      required: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },

    style: {
      type: String,
      required: false,
      default: 'margin: 1px 3px',
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('buttonClick');
    },
  },
};
</script>
