import API from './API';

export default {
  getFatture(id) {
    return API.get(`/fattura?filter=headquarter.id||$eq||${id}&`, {
      params: {
        join: ['headquarter'],
      },
    });
  },

  updateFattura(id, fattura) {
    return API.patch('/fattura/' + id, fattura);
  },

  getFattura(id) {
    return API.get('/fattura/' + id);
  },

  updateMinute(id, minute) {
    return API.patch('/minute/' + id, minute);
  },

  getBookingCod3(headquarterId) {
    const state = 'canceled';
    return API.get(
      `/booking?filter=state||$ne||${state}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC&filter=liquidato||$eq||${false}`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod3Liquidato(headquarterId) {
    const state = 'canceled';
    return API.get(
      `/booking?filter=state||$ne||${state}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC&filter=liquidato||$eq||${true}`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  /* getBookingCod4(headquarterId  ) {
    const state = "canceled";
    return API.get(`/booking?filter=state||$ne||${state}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC&filter=headquarter.partner.provvigione||$gt||${0}&filter=liquidato||$eq||${false}`, {
    //return API.get(`/booking`, {
      params: {
        join: ['car','pickup_bookings','delivery_bookings','escort_bookings', 'headquarter', 'customer', 'tasks', 'headquarter.partner'],

      },
      order: { data: 'ASC' }
    });
  }, */

  getBookingCod4(headquarterId) {
    const state = 'accepted';
    return API.get(
      `/provvigione?filter=type||$eq||${state}&filter=booking.headquarter.id||$eq||${headquarterId}&sort=booking.data,ASC&filter=liquidato||$eq||${false}`,
      {
        //return API.get(`/booking`, {
        params: {
          join: [
            'booking',
            'booking.car',
            'booking.pickup_bookings',
            'booking.delivery_bookings',
            'booking.escort_bookings',
            'booking.headquarter',
            'booking.customer',
            'booking.tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod4Minute(partnerId) {
    return API.get(
      `/provvigione?filter=minute.partner.id||$eq||${partnerId}&sort=minute.data,ASC&filter=liquidato||$eq||${false}`,
      {
        //return API.get(`/booking`, {
        params: {
          join: ['minute', 'minute.car', 'minute.partner'],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod4MinuteLiquidato(partnerId) {
    return API.get(
      `/provvigione?filter=minute.partner.id||$eq||${partnerId}&sort=minute.data,ASC&filter=liquidato||$eq||${true}`,
      {
        //return API.get(`/booking`, {
        params: {
          join: ['minute', 'minute.car', 'minute.partner'],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod4Liquidato(headquarterId) {
    const state = 'accepted';
    return API.get(
      `/provvigione?filter=type||$eq||${state}&filter=booking.headquarter.id||$eq||${headquarterId}&sort=booking.data,ASC&filter=liquidato||$eq||${true}`,
      {
        //return API.get(`/booking`, {
        params: {
          join: [
            'booking',
            'booking.car',
            'booking.pickup_bookings',
            'booking.delivery_bookings',
            'booking.escort_bookings',
            'booking.headquarter',
            'booking.customer',
            'booking.tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod5(headquarterId) {
    const state = 'canceled';
    return API.get(
      `/booking?filter=state||$ne||${state}&filter=headquarter.id||$eq||${headquarterId}&sort=data,ASC&filter=liquidato||$eq||${true}`,
      {
        params: {
          join: [
            'car',
            'pickup_bookings',
            'delivery_bookings',
            'escort_bookings',
            'headquarter',
            'customer',
            'tasks',
          ],
        },
        order: { data: 'ASC' },
      }
    );
  },

  updateFatturaProvvigione(id, data) {
    return API.patch('/provvigione/' + id, data);
  },
  updateFatturaRemuneration(id, data) {
    return API.patch('/remuneration/' + id, data);
  },
  getBookingCod6() {
    return API.get(
      `/remuneration?filter=liquidato||$eq||${false}&sort=data,ASC`,
      {
        params: {
          join: ['driver'],
        },
        order: { data: 'ASC' },
      }
    );
  },

  getBookingCod6Liquidato() {
    return API.get(
      `/remuneration?filter=liquidato||$eq||${true}&sort=data,ASC`,
      {
        params: {
          join: ['driver', 'driver.agency'],
        },
        order: { data: 'ASC' },
      }
    );
  },

  patchCod6Liquidato(id, data) {
    return API.patch('/remuneration/' + id, data);
  },

  getDetailCod6(id) {
    return API.get(`/planner?filter=jolly.id||$eq||${id}&sort=date,ASC`, {
      params: {
        join: [
          'jolly',
          'pickup_booking',
          'escort_booking',
          'delivery_booking',
          'pickup_booking.booking',
          'escort_booking.booking',
          'delivery_booking.booking',
        ],
      },
      order: { date: 'ASC' },
    });
  },
};
