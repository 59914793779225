<template>
    <div :style="style" >
        <el-form-item :label="label" :style="styleForm" :prop="prop">
          <el-input v-model="internalValue" :placeholder="placeholder"  :disabled="disabled" :readonly="readonly" @focus="handleInputFocus" @blur="handleInputBlur"   :class="{ 'highlight': highlight }">
          <template v-if="showEeuro" #append>€</template>
          </el-input>
        </el-form-item>
    </div>
</template>
    
    <script>
    export default {
      props: {
        title: {
          type: String,
          required: false,
        },
        label: {
          type: String,
          required: true,
        },    
        prop: {
          type: String,
          required: false,
        },
        placeholder: {
          type: String,
          required: true,
        },
        value: {
          type: String,
          required: true,
        },
        span: {
          type: Number,
          required: false,
          default: 3,
        },
        style: {
          type: String,
          required: false,
        },
        styleForm: {
          type: String,
          required: false,
        },
        highlight: {
          type: Boolean,
          require: false,
        },
    
        divWidth:{
          type:String
        },
        showEeuro:{
          type:Boolean,
          default:true
        },
        disabled:{
        required:false,
        default:false
        },
        readonly:{
          required:false,
          default:false
        }
      },
      data() {
        return {
          internalValue: this.value,
          fieldsOfInterest: ['address', 'cap', 'province', 'street_number', 'city', 'name', 'surname'],
        
        };
      },
      watch: {
        value(newValue) {
          this.internalValue = newValue;
        }
      },
      methods: {
    
        handleInputFocus() {
          this.$emit('focus');
        },
        handleInputBlur() {
          this.$emit('blur');
          this.$emit('input', this.internalValue);
        },
        
      },
    };
    </script>
    
    <style scoped>
    
    .highlight {
      border: 2px solid rgb(205, 54, 54); 
    }
    </style>
    +