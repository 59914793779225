<template >
    <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <div >
            <el-text style="font-size:medium; font-weight:bold">Filtra</el-text>
             <el-icon><Filter /></el-icon>
            </div>
          </template>
          <div class="flex gap-20 mb-4" style="margin-top:20px">
            <span>Ragione sociale</span>
            <el-input v-model="company_name" style="width: 20%; margin-left:10px" :placeholder="placeholder" />
            <span style="margin-left:30px">mail</span>
            <el-input v-model="mail" style="width:  20%; margin-left:10px" :placeholder="placeholder" />
            <span style="margin-left:30px">sede</span>
            <el-input v-model="area" style="width:  20%; margin-left:10px" :placeholder="placeholder" />
            <el-button style="margin-left: 10px"  @click="handleButtonClick"  type="primary" >Reset filtri</el-button>
          </div>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      company_name:'',
      mail:'',
      area:'',
    };
  },
  watch: {
    company_name(val) {
      this.$emit('companyNameChanged', val);
    },
    mail(val) {
      this.$emit('mailChanged', val);
    },
    area(val) {
      this.$emit('areaChanged', val);
    },
  },
  methods: {
    handleButtonClick() {
        this.$emit('resetClick');
    }
  }
};
</script>

<style>
.vuedatepicker.dropdown-menu {
  z-index: 9999 !important;
}
.filter-title {
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.filter-title el-text {
  margin-right: 5px;
}
</style>