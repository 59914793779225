<template>
    <!-- <el-form ref="tariffeForm" :model="partner" :rules="formRulePartner">  -->
  
    <div class="point-container">
      <InputComponent
        :value="customer.name"
        prop="name"
        label="Nome"
         @input="(newValue) => (customer.name = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <InputComponent
      :value="customer.surname"
      label="Cognome"
      prop="surname"
       @input="(newValue) => (customer.surname = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
        />
    <InputComponent
        :value="customer.mail"
        label="Mail"
        prop="mail"
         @input="(newValue) => (customer.mail = newValue.target.value)"
        :showEeuro="false"
        :disabled="disabled"
      />
      <el-form-item label="Data di nascita" prop="date_birth">
        <el-date-picker
          v-model="customer.date_birth"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
          format="DD/MM/YYYY"
        />
      </el-form-item>
      <!--<InputComponent
      :value="customer.date_birth"
      label="Data di nascita"
      prop="date_birth"
       @input="(newValue) => (customer.date_birth = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />-->
      <InputComponent
      :value="customer.telephone_number"
      label="Numero"
      prop="telephone_number"
       @input="(newValue) => (customer.telephone_number = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
      <InputComponent
      :value="customer.address"
      label="Indirizzo"
      prop="address"
       @input="(newValue) => (customer.address = newValue.target.value)"
      :showEeuro="false"
      :disabled="disabled"
      />
    </div>
  
    </template>
    
    
    <script>
    import InputComponent from './InputComponent.vue';
    import { reactive } from 'vue';
    //import FormPointComponent from './FormPointComponent.vue';
    export default {
      components: {
        InputComponent,
      },
      props: {
        value: {
          type: Object,
          required: true,
        },
        form: {
          type: String,
          required: true,
        },
    
        type: {
          type: Number,
          required: false,
          default: 0,
        },
        style: {
          type: String,
          required: false,
          default: 'margin-top: 10%',
        },
        label: {
          type: String,
          required: false,
        },
        loading: {
          type: String,
          required: false,
        },
    
        handleInputBlur: {
          type: Function,
        },
    
        showGeocoding:{
          type: Boolean,
          required: false,
          default:true
        },
        disabled:{
          default:false
        }
      },
      setup() {
        let formRuleCustomer = reactive({
          mail:  [
          { required: true, message: 'Inserire mail', trigger: 'blur' },
        ],
          phone_number:  [
          { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
        ],
        date_birth: [
          {
            type: 'date',
            required: true,
            message: 'Inserire data di nascita',
            trigger: 'blur',
          },
        ],
    });
         return {
          formRuleCustomer,
        }; 
      },
      data() {
        return {
          customer: this.value,
        };
      },
      watch: {
        value(newValue) {
          this.customer = newValue;
        },
      },
      getTitle(key) {
        return key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ');
      },
      handleInput(newValue, key) {
        this.$set(this.customer, key, newValue);
      },
    };
    </script>
    
    <style scoped>
    .point-container {
      display: flex;
      flex-wrap: wrap;
      gap: 7px;
      justify-content: space-between;
      
    }
    
    .point-container > div {
      flex: 10;
      min-width: 20%;
      justify-content: center;
    }
  
    </style>
    