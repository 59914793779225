import API from './API';

export default { 

    getAreaFromDriverAgencyId(agencyId) {
        return API.get(`/areas?filter=areas.agency.id||$eq||${agencyId}&`,{
            params: {
                 join: [
                  'agency',
                  'agency.driver'
                ], 
            },
        });
    },


    getAreas(){
        return API.get(`/area?sort=area,ASC`,{
            params: {
                 join: [
                    'drivers'
                ], 
            },
        });
    },


    modifyArea(id,area){
        return API.patch(`/area/`+id, area);
    },

    addAreas(areas) {
        return API.post('/area/bulk/', areas);
      },
}