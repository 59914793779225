<template >
  <el-collapse accordion>
      <el-collapse-item name="1">
        <template #title>
          <div>
          <el-text style="font-size:medium; font-weight:bold">Filtra</el-text>
           <el-icon><Filter /></el-icon>
          </div>
        </template>
        <div class="flex gap-20 mb-4" style="margin-top:20px">
          <span>ID</span>
          <el-input v-model="id" style="width: 10%; margin-left:10px" :placeholder="placeholder" />
          <span style="margin-left:30px">Nome cliente</span>
          <el-input v-model="nome" style="width: 10%; margin-left:10px" :placeholder="placeholder" />
          <span style="margin-left:30px">Targa</span>
          <el-input v-model="targa" style="width:  10%; margin-left:10px" :placeholder="placeholder" />
          <span style="margin-left:30px">Data booking</span>
          <el-date-picker v-model="data" type="date" :size="size" style="width:  10%; margin-left:10px"/>
          <el-text style="margin-left:30px" type="info" >Servizio </el-text>
          <el-cascader v-model="servizio" :options="servizi" style="margin-left:10px"/>
          <el-button style="margin-left: 10px"  @click="handleButtonClick"  type="primary" >Reset filtri</el-button>
        </div>
      </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
props: {
  servizi: {
    required: true
  }

},
data() {
  return {
    id: '',
    nome: '',
    targa: '',
    servizio: '',
    data: ''
  };
},
watch: {
  id(val) {
    this.$emit('idChanged', val);
  },
  nome(val) {
    this.$emit('nomeChanged', val);
  },
  targa(val) {
    this.$emit('targaChanged', val);
  },
  servizio(val) {
    this.$emit('servizioChanged', val);
  },
  data(val) {
    this.$emit('dataChanged', val);
  },
},
methods: {
  handleButtonClick() {
      this.$emit('resetClick');
  }
}
};
</script>

<style>
.vuedatepicker.dropdown-menu {
z-index: 9999 !important;
}
.filter-title el-text {
margin-right: 5px;
}
</style>