<template>
    <!-- START REGION ALL CUTOMER -->
    <h1>Tutti i clienti</h1>
    <div class="add-button">
        <div class="bottom" style="margin-top: 10px">
            <router-link :to="{ path: '/customer/add-customer', query: {q : 1}}" class="routerLink">
                <ButtonComponent
                label="Aggiungi nuovo cliente"
                :isRouter="false"
                @buttonClick="back"
                ></ButtonComponent>
            </router-link>
        </div>
    </div>
    <FilterTableCustomerComponent
    @nomeChanged="(newValue) =>
    (nomeFilter = newValue)"
    @mailChanged="(newValue) =>
    (mailFilter = newValue)"
    @telefonoChanged="(newValue) =>
    (telefonoFilter = newValue)"
    @indirizzoChanged="(newValue) =>
    (indirizzoFilter = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilter">
    </FilterTableCustomerComponent>
    <el-table :data="homeTableDataAllClienti" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="Id">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="nome" label="Nome" />
        <el-table-column prop="cognome" label="Cognome" />
        <el-table-column prop="mail" label="Mail"  />
        <el-table-column prop="telefono" label="Telefono"  />
        <el-table-column prop="indirizzo" label="Indirizzo" />

        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/customer/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeAllCustomer"
        :page-size="pageSize"
        :total="homeTableAllClienti.length">
    </el-pagination>
    </div>
    <!-- END REGION ALL CUTOMER -->

    <!-- START REGION CUTOMER IN CONFERMA -->
    <h1>Clienti in conferma</h1>
    <FilterTableCustomerComponent
    @nomeChanged="(newValue) =>
    (nomeFilterInConferma = newValue)"
    @mailChanged="(newValue) =>
    (mailFilterInConferma = newValue)"
    @telefonoChanged="(newValue) =>
    (telefonoFilterInConferma = newValue)"
    @indirizzoChanged="(newValue) =>
    (indirizzoFilterInConferma = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilterInConferma">
    </FilterTableCustomerComponent>
    <el-table :data="homeTableDataClientiInConferma" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="Id">
            <template #default="scope">
                <el-input :value="scope.row.id"/>
            </template>
        </el-table-column>
        <el-table-column prop="nome" label="Nome" />
        <el-table-column prop="cognome" label="Cognome" />
        <el-table-column prop="mail" label="Mail"  />
        <el-table-column prop="telefono" label="Telefono"  />
        <el-table-column prop="indirizzo" label="Indirizzo" />
        <el-table-column prop="valet" label="Valet" />
        <el-table-column prop="imponibile" label="Imponibile" />

        <el-table-column >
            <template #default="scope">
               <!-- <router-link :to="{ path: `/customer/detail/${scope.row.id}` }" class="routerLink">-->
                    <el-button size="small" @click="confermaMinute(scope.row.min_id)">
                        Conferma
                    </el-button>
               <!-- </router-link>-->
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeCustomerInConferma"
        :page-size="pageSize"
        :total="homeTableClientiInConferma.length">
    </el-pagination>
    </div>
    <!-- END REGION CUTOMER IN CONFERMA -->

    <!-- START REGION AZIENDA -->
    <h1>Aziende</h1>
    <div class="add-button">
        <div class="bottom" style="margin-top: 10px">
            <router-link :to="{ path: '/customer/add-customer', query: {q : 2}}" class="routerLink">
                <ButtonComponent
                label="Aggiungi nuova azienda"
                :isRouter="false"
                @buttonClick="back"
                ></ButtonComponent>
            </router-link>
        </div>
      </div>
    <FilterTableAziendeComponent
    @ragioneSocialeChanged="(newValue) =>
    (ragioneSocialeFilterAziende = newValue)"
    @mailChanged="(newValue) =>
    (mailFilterAziende = newValue)"
    @telefonoChanged="(newValue) =>
    (telefonoFilterAziende = newValue)"
    @referenteChanged="(newValue) =>
    (referenteFilterAziende = newValue)"
    @resetClick="resetFilter"
    :ragioneSociale="ragioneSocialeFilterAziende">
    </FilterTableAziendeComponent>
    <el-table :data="homeTableDataAziende" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="Id">
            <template #default="scope">
                <el-input :value="scope.row.id"/>
            </template>
        </el-table-column>
        <el-table-column prop="ragioneSociale" label="Ragione Sociale" />
        <el-table-column prop="referente" label="Referente" />
        <el-table-column prop="mail" label="Mail"  />
        <el-table-column prop="telefono" label="Telefono"  />

        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/customer/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChangeAziende"
        :page-size="pageSize"
        :total="homeTableAziende.length">
    </el-pagination>
    </div>
    <!-- END REGION AZIENDA -->

</template>

<script>
import customerAPI from '../../services/customerAPI.js';
//import minuteAPI from '../../services/minuteAPI.js';
import FilterTableCustomerComponent from "@/components/FilterTableCustomerComponent.vue";
import FilterTableAziendeComponent from "@/components/FilterTableAziendeComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue"
import { ref, computed } from "vue";
import carAPI from '@/services/carAPI';
import minuteAPI from '@/services/minuteAPI';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';

export default { 
    data() {
        return {
            pageAllCustomer: 1,
            pageCustomerInConferma: 1,
            pageAziende: 1,
            pageSize: 7
        }
    },
    setup() { 
        const router = useRouter();

        let nomeFilter = ref("");
        let mailFilter = ref("");
        let telefonoFilter = ref("");
        let indirizzoFilter = ref("");

        let nomeFilterInConferma = ref("");
        let mailFilterInConferma = ref("");
        let telefonoFilterInConferma = ref("");
        let indirizzoFilterInConferma = ref("");

        let ragioneSocialeFilterAziende = ref("");
        let mailFilterAziende = ref("");
        let telefonoFilterAziende = ref("");
        let referenteFilterAziende = ref("");

        let tableDataAllClienti = ref([{
                id: "",
                nome: "",
                cognome: "",
                mail: "",
                telefono: "",
                indirizzo: ""
                
        }]);

        let tableDataClientiInConferma = ref([{
                min_id: "",
                id: "",
                nome: "",
                cognome: "",
                mail: "",
                telefono: "",
                indirizzo: "",
                valet: 0,
                imponibile: ""
                
        }]);

        let tableDataAziende = ref([{
                id: "",
                ragioneSociale: "",
                referente: "",
                mail: "",
                telefono: ""
                
        }]);

        const loadAllCustomers = async () => {
            try {
                tableDataAllClienti.value=[];
                tableDataAziende.value=[];
                const response = await customerAPI.getCustomer();
                response.data.forEach(async (customer) => {
                    await loadMinutes(customer.id);
                    if(customer.mail != 'Eliminato' && customer.role != 'admin') {
                        if(customer.company_name != null && customer.company_name != '') {
                            let tableRow = {
                                id: customer.id,
                                ragioneSociale: customer.company_name,
                                referente: customer.name + ' ' + customer.surname,
                                mail: customer.mail,
                                telefono: customer.telephone_number
                            }
                            tableDataAziende.value.push(tableRow)
                        } else if(customer.is_alive == true){
                            let tableRow = {
                                id: customer.id,
                                nome: customer.name,
                                cognome: customer.surname,
                                mail: customer.mail,
                                telefono: customer.telephone_number,
                                indirizzo: customer.address
                            };
                            tableDataAllClienti.value.push(tableRow);
                        }
                     }
                });
            } catch (e) {
                console.log(e);
            }
        }

        loadAllCustomers();

        const loadMinutes = async (customerid) => {
            try {
                tableDataClientiInConferma.value=[];

                const response = await carAPI.getCarsByCustomer(customerid);
                response.data.forEach((minute) => {
                    minute.minutes.forEach((m) => {
                        if (m.accepted == false) {
                            let tableRow = {
                                min_id: m.id,
                                id: minute.customer.id,
                                nome: minute.customer.name,
                                cognome: minute.customer.surname,
                                mail: minute.customer.mail,
                                telefono: minute.customer.telephone_number,
                                indirizzo: minute.customer.address,
                                valet: m.valet,
                                imponibile: m.imponibile
                            };
                            tableDataClientiInConferma.value.push(tableRow);
                        }
                    })

                });
                    
            } catch (e) {
                console.log(e);
            }
        }

        const confermaMinute = async (minuteid) => {
            try {
                const response = await minuteAPI.confermaMinute(minuteid);
                if (response.status === 200) {
                  router.go();
                  ElMessage({
                    message: 'Cliente confermato con successo!',
                    type: 'success',
                  });
                } else {
                  ElMessage({
                    message: 'Errore!',
                    type: 'error',
                  });
                  console.log('errore inatteso');
                } 
            } catch (e) {
                console.log(e);
            }
        }


        const homeTableAllClienti = computed(function () {
            return tableDataAllClienti.value.filter((row) =>
                (row.nome.toLowerCase().includes(nomeFilter.value.toLowerCase()) ||
                row.cognome.toLowerCase().includes(nomeFilter.value.toLowerCase())) &&
                row.mail.toLowerCase().includes(mailFilter.value.toLowerCase()) &&
                row.telefono.toLowerCase().includes(telefonoFilter.value.toLowerCase()) &&
                row.indirizzo.toLowerCase().includes(indirizzoFilter.value.toLowerCase())
            );
        });

        const homeTableClientiInConferma = computed(function () {
            return tableDataClientiInConferma.value.filter((row) =>
                (row.nome.toLowerCase().includes(nomeFilterInConferma.value.toLowerCase()) ||
                row.cognome.toLowerCase().includes(nomeFilterInConferma.value.toLowerCase())) &&
                row.mail.toLowerCase().includes(mailFilterInConferma.value.toLowerCase()) &&
                row.telefono.toLowerCase().includes(telefonoFilterInConferma.value.toLowerCase()) &&
                row.indirizzo.toLowerCase().includes(indirizzoFilterInConferma.value.toLowerCase())
            );
        });

        const homeTableAziende = computed(function () {
            return tableDataAziende.value.filter((row) =>
                (row.ragioneSociale.toLowerCase().includes(ragioneSocialeFilterAziende.value.toLowerCase()) &&
                row.referente.toLowerCase().includes(referenteFilterAziende.value.toLowerCase())) &&
                row.mail.toLowerCase().includes(mailFilterAziende.value.toLowerCase()) &&
                row.telefono.toLowerCase().includes(telefonoFilterAziende.value.toLowerCase()) 
            );
        });

        const resetFilter = async () => {
            window.location.reload();
        }

        return { 
            resetFilter,
            loadAllCustomers,
            homeTableAllClienti,
            homeTableClientiInConferma,
            homeTableAziende,
            nomeFilter,
            mailFilter,
            telefonoFilter,
            indirizzoFilter,
            nomeFilterInConferma,
            mailFilterInConferma,
            telefonoFilterInConferma,
            indirizzoFilterInConferma,
            ragioneSocialeFilterAziende,
            referenteFilterAziende,
            mailFilterAziende,
            telefonoFilterAziende,
            confermaMinute
        }
    },
    components: { FilterTableCustomerComponent, ButtonComponent, FilterTableAziendeComponent },
    computed: {
        homeTableDataAllClienti() {
            if (!this.homeTableAllClienti || this.homeTableAllClienti.length === 0) return [];
            return this.homeTableAllClienti.slice(this.pageSize * this.pageAllCustomer - this.pageSize, this.pageSize * this.pageAllCustomer)
        },
        homeTableDataClientiInConferma() {
            if (!this.homeTableClientiInConferma || this.homeTableClientiInConferma.length === 0) return [];
            return this.homeTableClientiInConferma.slice(this.pageSize * this.pageCustomerInConferma - this.pageSize, this.pageSize * this.pageCustomerInConferma)
        },
        homeTableDataAziende() {
            if (!this.homeTableAziende || this.homeTableAziende.length === 0) return [];
            return this.homeTableAziende.slice(this.pageSize * this.pageAziende - this.pageSize, this.pageSize * this.pageAziende)
        },
    },
    methods: {
        handleCurrentChangeAllCustomer(val) {
            this.pageAllCustomer = val;
        },
        handleCurrentChangeCustomerInConferma(val) {
            this.pageCustomerInConferma = val;
        },
        handleCurrentChangeAziende(val) {
            this.pageAziende = val;
        }
    }
}
</script>