<template >
    <el-collapse accordion>
        <el-collapse-item name="1">
          <template #title>
            <div>
            <el-text style="font-size:medium; font-weight:bold">Filtra</el-text>
             <el-icon><Filter /></el-icon>
            </div>
          </template>
          <div class="flex gap-20 mb-4" style="margin-top:20px">
            <span>Nome e cognome driver</span>
            <el-input v-model="nome" style="width: 10%; margin-left:10px" :placeholder="placeholder" />
            <span style="margin-left:30px">Mail</span>
            <el-input v-model="mail" style="width:  10%; margin-left:10px" :placeholder="placeholder" />
            <span style="margin-left:30px">Telefono</span>
            <el-input v-model="telefono" style="width:  10%; margin-left:10px" :placeholder="placeholder" />
            <span style="margin-left:30px">Agenzia</span>
            <el-input v-model="agenzia" style="width:  10%; margin-left:10px" :placeholder="placeholder" />
            <el-button style="margin-left: 10px"  @click="handleButtonClick"  type="primary" >Reset filtri</el-button>
          </div>
        </el-collapse-item>
    </el-collapse>
  </template>
  
  <script>
  export default {
  props: {
    servizi: {
      required: true
    }
  
  },
  data() {
    return {
      nome: '',
      mail: '',
      telefono: '',
      agenzia: ''
    };
  },
  watch: {
    nome(val) {
      this.$emit('nomeChanged', val);
    },
    mail(val) {
      this.$emit('mailChanged', val);
    },
    telefono(val) {
      this.$emit('telefonoChanged', val);
    },
    agenzia(val) {
      this.$emit('agenziaChanged', val);
    },
  },
  methods: {
    handleButtonClick() {
        this.$emit('resetClick');
    }
  }
  };
  </script>
  
  <style>
  .vuedatepicker.dropdown-menu {
  z-index: 9999 !important;
  }
  .filter-title el-text {
  margin-right: 5px;
  }
  </style>