<template>
    <h1>Driver</h1>
    <div class="add-button">
        <ButtonComponent
          label="Aggiungi nuovo driver"
          :isRouter="true"
          linkPath="/driver/add-driver"
          @buttonClick="back"
        ></ButtonComponent>
      </div>
    <FilterTableDriverComponent
    @nomeChanged="(newValue) =>
    (nomeFilter = newValue)"
    @mailChanged="(newValue) =>
    (mailFilter = newValue)"
    @telefonoChanged="(newValue) =>
    (telefonoFilter = newValue)"
    @agenziaChanged="(newValue) =>
    (agenziaFilter = newValue)"
    @resetClick="resetFilter"
    :nome="nomeFilter">
    </FilterTableDriverComponent>
    <el-table :data="homeTableData" stripe :header-cell-style="{ background: 'rgb(231,228,58)'}" style="width: 100%">
        <el-table-column prop="id" label="Id">
            <template #default="scope">
                <el-input :value="scope.row.id" />
            </template>
        </el-table-column>
        <el-table-column prop="nome" label="Nome" />
        <el-table-column prop="cognome" label="Cognome" />
        <el-table-column prop="mail" label="Mail"  />
        <el-table-column prop="telefono" label="Telefono"  />
        <el-table-column prop="agenzia" label="Agenzia" />

        <el-table-column width="50">
            <template #default="scope">
                <router-link :to="{ path: `/driver/detail/${scope.row.id}` }" class="routerLink">
                    <el-button size="small">
                        <el-icon>
                            <DArrowRight />
                        </el-icon>
                    </el-button>
                </router-link>
            </template>
        </el-table-column>
    </el-table>

    <div style="text-align: center">
    <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :total="homeTable.length">
    </el-pagination>
    </div>

</template>

<script>
import driverAPI from '../../services/driverAPI.js';
import FilterTableDriverComponent from "@/components/FilterTableDriverComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue"
import { ref, computed } from "vue";

export default { 
    data() {
        return {
            page: 1,
            pageSize: 7
        }
    },
    setup() { 
        let nomeFilter = ref("");
        let mailFilter = ref("");
        let telefonoFilter = ref("");
        let agenziaFilter = ref("");

        let tableData = ref([{
                id: "",
                nome: "",
                cognome: "",
                mail: "",
                telefono: "",
                agenzia: ""
                
        }]);

        const loadAllDrivers = async () => {
            try {
                    
                tableData.value=[];

                const response = await driverAPI.getDrivers();
                response.data.forEach(async (driver) => {
                    if(!driver.is_alive && !driver.is_deleted) {
                        let tableRow = {
                        id: driver.id,
                        nome: driver.name,
                        cognome: driver.surname,
                        mail: driver.mail,
                        telefono: driver.telephone_number,
                        agenzia: driver.agency.company_name
                        };
                        tableData.value.push(tableRow);
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }

        loadAllDrivers();

        const homeTable = computed(function () {
            return tableData.value.filter((row) =>
                (row.nome.toLowerCase().includes(nomeFilter.value.toLowerCase()) ||
                row.cognome.toLowerCase().includes(nomeFilter.value.toLowerCase())) &&
                row.mail.toLowerCase().includes(mailFilter.value.toLowerCase()) &&
                row.telefono.toLowerCase().includes(telefonoFilter.value.toLowerCase()) &&
                row.agenzia.toLowerCase().includes(agenziaFilter.value.toLowerCase())
            );
        });

        const resetFilter = async () => {
            window.location.reload();
        }

        return { 
            resetFilter,
            loadAllDrivers,
            homeTable,
            nomeFilter,
            mailFilter,
            telefonoFilter,
            agenziaFilter
        }
    },
    components: { FilterTableDriverComponent, ButtonComponent },
    computed: {
        homeTableData() {
            if (!this.homeTable || this.homeTable.length === 0) return [];
            return this.homeTable.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
    },
    methods: {
        handleCurrentChange(val) {
            this.page = val;
        }
    }
}
</script>