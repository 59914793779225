<template>
    <div class="point-container">
      <InputComponent
        :value="customer.company_name"
        :span="6"
        title="Ragione sociale"
        label="Ragione sociale"
        placeholder="Ragione sociale"
        :showEeuro="false"
        @input="(newValue) => (customer.company_name = newValue.target.value)"
        prop="company_name"
      />
      <InputComponent
        :value="customer.SDI"
        :span="5"
        title="SDI"
        label="SDI"
        placeholder="SDI"
        :showEeuro="false"
        @input="(newValue) => (customer.sdi = newValue.target.value)"
      />
      <InputComponent
        :value="customer.PEC"
        :span="5"
        title="pec"
        label="pec"
        placeholder="pec"
        :showEeuro="false"
        @input="(newValue) => (customer.pec = newValue.target.value)"
      />
  
      <InputComponent
        :value="customer.piva"
        :span="6"
        title="P.iva"
        label="P.iva"
        placeholder="P.iva"
        :showEeuro="false"
        @input="(newValue) => (customer.piva = newValue.target.value)"
      />
  
      <InputComponent
        :value="customer.CF"
        :span="6"
        title="CF"
        label="CF"
        placeholder="CF"
        :showEeuro="false"
        @input="(newValue) => (customer.CF = newValue.target.value)"
      />
    </div>
  
    <div style="text-align: left; margin-top: 1%; font-weight: bold">
      Dati di un referente dell'azienda:
    </div>
    <div class="point-container">
      <InputComponent
        :value="customer.name"
        :span="6"
        title="Nome"
        label="Nome"
        placeholder="Nome"
        :showEeuro="false"
        @input="(newValue) => (customer.name = newValue.target.value)"
      />
      <InputComponent
        :value="customer.surname"
        :span="6"
        title="Cognome"
        label="Cognome"
        prop="surname"
        placeholder="Cognome"
        :showEeuro="false"
        @input="(newValue) => (customer.surname = newValue.target.value)"
      />
  
      <el-form-item label="Data di nascita" prop="date_birth">
        <el-date-picker
          v-model="customer.date_birth"
          type="date"
          placeholder="Pick a date"
          style="width: 100%"
          format="DD/MM/YYYY"
        />
      </el-form-item>
    </div>
  
    <div class="point-container">
      <InputComponent
        :value="customer.address"
        :span="6"
        title="Indirizzo"
        label="Indirizzo"
        placeholder="Indirizzo"
        prop="address"
        :showEeuro="false"
        @blur="handleInputBlur"
        @input="(newValue) => (customer.address = newValue.target.value)"
      />
      <InputComponent
        :value="customer.street_number"
        :span="4"
        title="N. civico"
        label="N. civico"
        prop="street_number"
        placeholder="N. civico"
        :showEeuro="false"
        @blur="handleInputBlur"
        @input="(newValue) => (customer.street_number = newValue.target.value)"
      />
      <InputComponent
        :value="customer.city"
        :span="5"
        title="Città"
        label="Città"
        prop="city"
        placeholder="Città"
        :showEeuro="false"
        @blur="handleInputBlur"
        @input="(newValue) => (customer.city = newValue.target.value)"
      />
      <InputComponent
        :value="customer.cap"
        :span="4"
        title="CAP"
        label="CAP"
        prop="cap"
        placeholder="CAP"
        :showEeuro="false"
        @blur="handleInputBlur"
        @input="(newValue) => (customer.cap = newValue.target.value)"
      />
      <InputComponent
        :value="customer.province"
        :span="4"
        title="Provincia"
        @blur="handleInputBlur"
        label="Provincia"
        prop="province"
        placeholder="Provincia"
        :showEeuro="false"
        @input="(newValue) => (customer.province = newValue.target.value)"
      />
  
      <DescriptionComponent
        v-if="showGeocoding == true"
        :label="label"
        :loading="loading"
      ></DescriptionComponent>
    </div>
  
    <div class="point-container">
      <InputComponent
        :value="customer.mail"
        :span="6"
        title="Mail"
        label="Mail"
        placeholder="Mail"
        prop="mail"
        :showEeuro="false"
        @input="(newValue) => (customer.mail = newValue.target.value)"
      />
      <InputComponent
        :value="customer.telephone_number"
        :span="6"
        title="Numero di telefono"
        label="Numero di telefono"
        prop="telephone_number"
        placeholder="Numero di telefono"
        :showEeuro="false"
        @input="(newValue) => (customer.telephone_number = newValue.target.value)"
      />
    </div>
  </template>
  
  <script>
  import InputComponent from "./InputComponent.vue";
  import DescriptionComponent from "./DescriptionComponent.vue";
  import { reactive } from "vue";
  export default {
    components: {
      InputComponent,
      DescriptionComponent,
    },
    props: {
      value: {
        type: Object,
        required: true,
      },
      form: {
        type: String,
        required: true,
      },
  
      type: {
        type: Number,
        required: false,
        default: 0,
      },
      style: {
        type: String,
        required: false,
        default: "margin-top: 10%",
      },
      label: {
        type: String,
        required: false,
      },
      loading: {
        type: String,
        required: false,
      },
  
      handleInputBlur: {
        type: Function,
      },
  
      showGeocoding: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup() {
      let formRule = reactive({
        name: [
          {
            required: true,
            message: "Inserire il nome",
            trigger: "blur",
          },
        ],
        surname: [
          {
            required: true,
            message: "Inserire il cognome",
            trigger: "blur",
          },
        ],
        date_birth: [
          {
            type: "date",
            required: true,
            message: "Inserire data di nascita",
            trigger: "blur",
          },
        ],
        mail: [
          {
            required: true,
            message: "Inserire mail",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "Inserire indirizzo",
            trigger: "blur",
          },
        ],
        street_number: [
          {
            required: true,
            message: "Inserire N.civico",
            trigger: "blur",
          },
        ],
        city: [
          {
            required: true,
            message: "Inserire città",
            trigger: "blur",
          },
        ],
        cap: [
          {
            required: true,
            message: "Inserire cap",
            trigger: "blur",
          },
        ],
        province: [
          {
            required: true,
            message: "Inserire provincia",
            trigger: "blur",
          },
        ],
        telephone_number: [
          {
            required: true,
            message: "Inserire numero di telefono",
            trigger: "blur",
          },
        ],
        CF: [
          {
            required: true,
            message: "Inserire codice fiscale",
            trigger: "blur",
          },
          {
            min: 16,
            max: 16,
            message: "Numero di cifre errato",
            trigger: "blur",
          },
        ],
        intestatario: [
          {
            required: true,
            message: "Inserire intestatario",
            trigger: "blur",
          },
        ],
        marca: [
          {
            required: true,
            message: "Inserire marca",
            trigger: "blur",
          },
        ],
        modello: [
          {
            required: true,
            message: "Inserire modello",
            trigger: "blur",
          },
        ],
        targa: [
          {
            required: true,
            message: "Inserire targa",
            trigger: "blur",
          },
        ],
        transmission: [
          {
            required: true,
            message: "Scegliere cambio",
            trigger: "blur",
          },
        ],
      });
  
      return {
        formRule,
      };
    },
    data() {
      return {
        customer: this.value,
      };
    },
    watch: {
      value(newValue) {
        this.customer = newValue;
      },
    },
    getTitle(key) {
      // Convert the key to a more readable title format (e.g., 'name' to 'Nome')
      return key.charAt(0).toUpperCase() + key.slice(1).replace("_", " ");
    },
    handleInput(newValue, key) {
      // Update the customer object with the new value
      this.$set(this.customer, key, newValue);
    },
  };
  </script>
  
  <style scoped>
  .point-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .point-container > div {
    flex: 4;
    min-width: 15%;
  }
  </style>
  