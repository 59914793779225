<template>
  <h1>Compensi</h1>

  <h2>Driver da liquidare</h2>
  <el-input v-model="input" placeholder="Inserisci nome del driver">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table
    :data="remTable"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
    :show-summary="true"
    :summary-method="getSummaries"
  >
    <el-table-column prop="dal" label="Dal" />
    <el-table-column prop="al" label="Al" />
    <el-table-column prop="driver_name" label="driver" />
    <el-table-column prop="minute_driver" label="minuti driver" />
    <el-table-column prop="minute_jolly" label="minuti jolly" />
    <el-table-column prop="rimborso" label="rimborso jolly" />
    <el-table-column prop="importo" label="importo">
      <template #default="scope">
        <el-input v-model="scope.row.importo"
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column label="Dettagli">
      <template #default="scope">
        <router-link
          :to="{
            path: '/contabilita/cod6/detail/' + `${scope.row.driver_id}`,
          }"
          class="routerLink"
        >
          <el-button size="small" @click="handleClick(scope.row.driver_id)">
            Visualizza
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>

  <div class="point-container">
    <div>
      <el-button @click="downloadPDF" type="primary"> Scarica PDF </el-button>
    </div>

    <div>
      <router-link class="routerLink" to="/contabilita">
        <el-button @click="update()"> Conferma </el-button>
      </router-link>
    </div>
  </div>

  <h2>Driver liquidati</h2>
  <el-input v-model="input" placeholder="Inserisci nome del driver">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table
    :data="remTableLiquidati"
    :header-cell-style="{ background: 'rgb(35,44,81)' }"
    :show-summary="true"
    :summary-method="getSummaries"
  >
    <el-table-column prop="dal" label="Dal" />
    <el-table-column prop="al" label="Al" />
    <el-table-column prop="driver_name" label="driver" />
    <el-table-column prop="minute_driver" label="minuti driver" />
    <el-table-column prop="minute_jolly" label="minuti jolly" />
    <el-table-column prop="rimborso" label="rimborso jolly" />
    <el-table-column prop="importo" label="importo">
      <template #default="scope">
        <el-input v-model="scope.row.importo" readonly
          ><template #append>€</template></el-input
        >
      </template>
    </el-table-column>
    <el-table-column label="Dettagli">
      <template #default="scope">
        <router-link
          :to="{
            path: '/contabilita/cod6/detail/' + `${scope.row.driver_id}`,
          }"
          class="routerLink"
        >
          <el-button size="small" @click="handleClick(scope.row.driver_id)">
            Visualizza
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref, computed } from 'vue';
import contabilitaAPI from '../../../services/contabilitaAPI';
import jsPDF from 'jspdf';
import { ElMessage } from 'element-plus';
import 'jspdf-autotable';

const dayjs = require('dayjs');
export default {
  setup() {
    const input = ref('');
    const tableData = ref([]);
    const tableDataLiquidati = ref([]);
    const loadRemuneration = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod6();

        response.data.forEach((remuneration) => {
          let tableRow = {
            id: remuneration.id,
            dal: dayjs(remuneration.date_start).format('YYYY-MM-DD'),
            al: dayjs(remuneration.date_end).format('YYYY-MM-DD'),
            minute_driver: remuneration.minute_driver,
            minute_jolly: remuneration.minute_jolly,
            rimborso:
              remuneration.minute_jolly > 0
                ? (remuneration.minute_jolly * 0.15, 2).toFixed(2)
                : 0,
            importo: 0,
            data:
              dayjs(remuneration.data).format('YYYY-MM-DD') != null
                ? dayjs(remuneration.data).format('YYYY-MM-DD')
                : null,
            driver_id: remuneration.driver.id,
            driver_name: (
              remuneration.driver.name +
              ' ' +
              remuneration.driver.surname
            ).trim(),
            rif_fattura: remuneration.rif_fattura,
          };

          tableData.value.push(tableRow);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const liquidato = async (id) => {
      const obj = {
        data: new Date(),
        liquidato: true,
      };
      await contabilitaAPI.patchCod6Liquidato(id, obj);
    };
    loadRemuneration();

    const loadRemunerationLiquidato = async () => {
      try {
        const response = await contabilitaAPI.getBookingCod6Liquidato();

        response.data.forEach((remuneration) => {
          let tableRow = {
            id: remuneration.id,
            dal: dayjs(remuneration.date_start).format('YYYY-MM-DD'),
            al: dayjs(remuneration.date_end).format('YYYY-MM-DD'),
            minute_driver: remuneration.minute_driver,
            minute_jolly: remuneration.minute_jolly,
            rimborso:
              remuneration.minute_jolly > 0
                ? (remuneration.minute_jolly * 0.15, 2).toFixed(2)
                : 0,
            importo: remuneration.importo,
            data:
              dayjs(remuneration.data).format('YYYY-MM-DD') != null
                ? dayjs(remuneration.data).format('YYYY-MM-DD')
                : null,
            driver_id: remuneration.driver.id,
            driver_name: (
              remuneration.driver.name +
              ' ' +
              remuneration.driver.surname
            ).trim(),
            rif_fattura: remuneration.rif_fattura,
          };
          tableDataLiquidati.value.push(tableRow);
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadRemunerationLiquidato();

    const downloadPDF = () => {
      //const selectedRows = fattureTableFalse.filter((row) => row.selected);
      if (tableData.value.length === 0) {
        ElMessage.error('Non ci sono righe da scaricare!');
        return;
      }
      // Call a function to generate a PDF with the selected rows
      generatePDF(tableData.value);
    };

    const generatePDF = (selectedRows) => {
      const pdf = new jsPDF();
      const header = [
        'Driver',
        'Minuti Driver ',
        'Minuti Jolly',
        'Rimborso Jolly',
        'Importo',
      ];
      const data = selectedRows.map((row) => [
        row.driver_name,
        row.minute_driver,
        row.minute_jolly,
        row.rimborso,
        row.importo,
      ]);
      const totalImporto = selectedRows.reduce(
        (acc, row) => acc + row.importo,
        0
      );
      const footer = [['', '', '', 'Totale:', totalImporto.toFixed(2)]];
      data.push(...footer);

      pdf.autoTable({
        head: [header],
        body: data,
      });
      const today = new Date();
      pdf.save('fatture' + dayjs(today).format('DD-MM-YY') + '.pdf');
    };

    const rif_fattura = ref(0);

    const update = () => {
      const promises = tableData.value.map((element) =>
        updRifFattura(element.id, rif_fattura.value, element.importo)
      );

      Promise.all(promises)
        .then((responses) => {
          const allSuccessful = responses.every(
            (response) => response.status === 200
          );
          if (allSuccessful) {
            ElMessage({
              message: 'Fattura aggiornato con successo!',
              type: 'success',
            });

            //route.push('/contabilità');
          } else {
            ElMessage({
              message: "Errore durante l'aggiornamento di alcune fatture!",
              type: 'error',
            });
          }
        })
        .catch((error) => {
          // Handle any error that occurred during the updates
          console.error('Error during update:', error);
          ElMessage({
            message: "Errore durante l'aggiornamento!",
            type: 'error',
          });
        });
    };
    const updRifFattura = async (id, rif, importo) => {
      let response = {};
      try {
        if (importo > 0) {
          response = await contabilitaAPI.updateFatturaRemuneration(id, {
            rif_fattura: rif,
            liquidato: true,
            importo: importo,
          });
          return response;
        }
      } catch {
        console.log('error');
      }
    };

    const remTableLiquidati = computed(function () {
      return tableDataLiquidati.value.filter((row) =>
        row.driver_name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const remTable = computed(function () {
      return tableData.value.filter((row) =>
        row.driver_name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      liquidato,
      downloadPDF,
      update,
      loadRemuneration,
      remTableLiquidati,
      remTable,
      input,
    };
  },
  methods: {
    getSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Totale';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!isNaN(values[0])) {
          const total = values.reduce((prev, curr) => prev + curr, 0);
          if (column.property === 'importo') {
            sums[index] = `${total.toFixed(2)} €`;
          } else {
            sums[index] = total;
          }
        } else {
          sums[index] = '';
        }
      });
      return sums;
    },
  },
};
</script>

<style></style>
